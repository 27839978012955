/* eslint-disable */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import moment from "moment"

import { connect } from "react-redux"

import { bindActionToPromise } from "../../utils/Common"

import { putMessage } from "../../store/actions"

const ViewInfo = props => {
  const { selectedMessage, ViewInfoBackBtn } = props

  useEffect(() => {
    if (!selectedMessage?.isRead) {
      props.readMessageAction({
        messageIds: [selectedMessage?.id],
        readAll: false,
      })
    }
  }, [selectedMessage?.isRead])

  return (
    <div>
      <span className="text-start" onClick={ViewInfoBackBtn}>
        <i className="bx bx-arrow-back me-2 txt_link"></i>
        <span className="txt_link">Back</span>
      </span>

      <div className="bg_gray_2 mb-2 mt-2">
        <p>
          From: <span>{selectedMessage?.member?.username}</span>
        </p>
        <p className="mb-0">
          Date:{" "}
          <span>
            {moment(selectedMessage?.createdAt).format("YYYY-MM-DD hh:mm:ss")}
          </span>
        </p>
      </div>
      <div>
        <p>{selectedMessage?.message}</p>
      </div>
    </div>
  )
}

ViewInfo.propTypes = {
  selectedMessage: PropTypes.obj,
  ViewInfoBackBtn: PropTypes.func,
  readMessageAction: PropTypes.func,
  setPaginationOptions: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  readMessageAction: bindActionToPromise(dispatch, putMessage),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewInfo)
