/* eslint-disable */

import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"
import CheckPopup from "./CheckPopup"

function CreateAccount() {
  const [popoverright, setpopoverright] = useState(false);


  return (
    <div>
    

          <div className="row mb-4">
            <div className="col-3">
            <label>User name <small className="text-danger">*</small></label>
            </div>
            <div className="col-6">
            GT06N-57085 
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-3">
            <label>New password <small className="text-danger">*</small></label>
            </div>
            <div className="col-6">
            <input
                        className="form-control"
                        type="password"
                        value="GT06N-57085"
                      />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-3">
            <label>Confirm password <small className="text-danger">*</small></label>
            </div>
            <div className="col-6">
            <input
                        className="form-control"
                        type="password"
                        value="GT06N-57085"
                      />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-3">
           
            </div>
            <div className="col-6 text-end">
            <button className="btn btn-primary">Submit</button>
            </div>
          </div>



          
          
         

        

    </div>
  )
}

export default CreateAccount
