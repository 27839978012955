/* eslint-disable */

import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Label,
  Button,
  Form,
  Input,
  NavLink,
  InputGroup,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import Tooltip from "@mui/material/Tooltip"
import lefticon4 from "../../assets/images/icons/measure-layer.svg"
//i18n
import { withTranslation } from "react-i18next"
import GoogleMapsApikey from "./GoogleMapsApikey"
const MonitorBottomMap = props => {
  const MapInfo = [
    {
      id: 1,
      name: "Google Streets",
    },
    {
      id: 2,
      name: "Google Satellite",
    },
    {
      id: 3,
      name: "Google Satellite",
    },
    {
      id: 4,
      name: "Google Satellite",
    },
    {
      id: 5,
      name: "Google Satellite",
    },
  ]
  const [menu, setMenu] = useState(false)

  const [GoogleMap, setGoogleMap] = useState(false)
  function tog_center() {
    setMenu(!menu)
    setGoogleMap(!GoogleMap)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="div"
      >
        <DropdownToggle className="no-button ms-4" tag="button">
          <Tooltip title="Map" placement="top" arrow>
            <img src={lefticon4} width={30} />
          </Tooltip>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu mb_map_center p-0">
          <div className="d-flex align-items-center bot_pop_head">
            <i className="bx bx-windows pe-2 flx_0 font-size-17"></i>
            <span className="flx_1"> Map Settings</span>
            <div className="flx_0 ps-2 pt-2">
              <span
                onClick={() => {
                  tog_center()
                }}
                className="bx bx-key pe-2 font-size-17 cursor-pointer"
              ></span>
              <Modal
                isOpen={GoogleMap}
                size="lg"
                toggle={() => {
                  tog_center()
                }}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">
                    Google Maps API Key [admindemo]
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setMenu(true)
                      setGoogleMap(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                </div>
                <GoogleMapsApikey />
              </Modal>

              <span
                onClick={() => setMenu(!menu)}
                className="bx bx-message-alt-minus font-size-17 cursor-pointer"
              ></span>
            </div>
          </div>
          <SimpleBar style={{ height: "360px" }}>
            <ul className="map_list_item">
              {MapInfo.map(item => {
                return <li key={item.id}>{item.name}</li>
              })}
            </ul>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
export default withTranslation()(MonitorBottomMap)
