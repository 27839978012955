/* eslint-disable */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Tooltip, InputGroup, Input } from "reactstrap"
import NotificationAlertEmail from "./NotificationAlertEmail"

import { bindActionToPromise } from "../../../utils/Common"

import { connect } from "react-redux"

import { putPreferences } from "../../../store/actions"

import { useSelector } from "react-redux"

import LoadingButton from "../../../components/Common/Button"

const NotificationService = props => {
  const {
    preferenceInput,
    notificationAlertInput,
    notificationServiceInput,
    updateNotificationServiceField,
    selectedUser,
    tog_preferences,
  } = props

  const { serviceList, emailsList } = notificationServiceInput
  const [ttop, setttop] = useState(false)
  const [ttop25, setttop25] = useState(false)

  const { isLoading } = useSelector(state => {
    return {
      isLoading: state.Preferences.isLoading,
    }
  })

  const isAllMessageSetValue =
    serviceList.findIndex(ele => !ele.isMessage) < 0 ? true : false

  const isAllEmailSetValue =
    serviceList.findIndex(ele => !ele.isEmail) < 0 ? true : false

  const isAllAppSetValue =
    serviceList.findIndex(ele => !ele.isApp) < 0 ? true : false

  const setMessageAllChange = value => {
    let newServiceList = serviceList.map(ele => {
      let newEle = { ...ele, isMessage: value }
      return newEle
    })
    updateNotificationServiceField("serviceList", [...newServiceList])
  }

  const setEmailAllChange = value => {
    let newServiceList = serviceList.map(ele => {
      let newEle = { ...ele, isEmail: value }
      return newEle
    })
    updateNotificationServiceField("serviceList", [...newServiceList])
  }

  const setAppAllChange = value => {
    let newServiceList = serviceList.map(ele => {
      let newEle = { ...ele, isApp: value }
      return newEle
    })
    updateNotificationServiceField("serviceList", [...newServiceList])
  }

  const updateServiceList = (key, val, index) => {
    let newServiceList = serviceList
    newServiceList[index][key] = val

    updateNotificationServiceField("serviceList", newServiceList)
  }

  const addNewEmail = () => {
    const newEmailList = emailsList
    newEmailList.push({
      email: "",
      isVerified: false,
    })
    updateNotificationServiceField("emailsList", newEmailList)
  }

  const removeEmail = index => {
    const newEmailList = emailsList.filter((ele, i) => i !== index)
    updateNotificationServiceField("emailsList", newEmailList)
  }

  const updateEmail = (index, value) => {
    const newEmailList = [...emailsList]
    newEmailList[index].email = value
    updateNotificationServiceField("emailsList", newEmailList)
  }

  return (
    <div>
      <div className="cus_br p-2 mb-3">
        <table className="table mb-0 table">
          <thead>
            <tr>
              <th>Type</th>
              <th width="120">
                <Input
                  type="checkbox"
                  className="form-check-Input"
                  id="customSwitchsizemd-message"
                  onClick={e => setMessageAllChange(e.target.checked)}
                  checked={isAllMessageSetValue}
                  defaultChecked={isAllMessageSetValue}
                />{" "}
                Message
              </th>
              <th width="80">
                <Input
                  type="checkbox"
                  className="form-check-Input"
                  id="customSwitchsizemd-email"
                  onClick={e => setEmailAllChange(e.target.checked)}
                  checked={isAllEmailSetValue}
                  defaultChecked={isAllEmailSetValue}
                />{" "}
                Email
              </th>
              <th width="120">
                <div className="d-flex align-items-center">
                  <Input
                    type="checkbox"
                    className="form-check-Input"
                    id="customSwitchsizemd-app"
                    onClick={e => setAppAllChange(e.target.checked)}
                    checked={isAllAppSetValue}
                    defaultChecked={isAllAppSetValue}
                  />{" "}
                  App
                  <Tooltip
                    placement="top"
                    isOpen={ttop25}
                    target="TooltipTop3"
                    toggle={() => {
                      setttop25(!ttop25)
                    }}
                    className="fs-2 text-left"
                  >
                    This is APP notification, not alert list. (if disable it,
                    you will not get APP notification, but still you can get the
                    alert list in APP.)
                  </Tooltip>
                  <span id="TooltipTop3">
                    <i className=" bx bxs-info-circle txt_colr_1 ms-1"></i>
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {serviceList.map((service, index) => (
              <tr key={index}>
                <td>
                  {" "}
                  <span className="d-inline-flex align-items-center">
                    {" "}
                    {service.serviceType}
                    {/* <Tooltip
                      placement="top"
                      isOpen={ttop}
                      target="TooltipTop1"
                      toggle={() => {
                        setttop(!ttop)
                      }}
                      className="fs-2 text-left"
                    >
                      Receive expire notification
                    </Tooltip> */}
                    <span id="TooltipTop1">
                      <i className=" bx bxs-info-circle txt_colr_1 ms-1"></i>
                    </span>
                  </span>
                </td>
                <td>
                  <Input
                    type="checkbox"
                    className="form-check-Input"
                    id="customSwitchsizemd-email"
                    onClick={e =>
                      updateServiceList("isMessage", e.target.checked, index)
                    }
                    checked={service.isMessage}
                    defaultChecked={service.isMessage}
                  />
                </td>
                <td>
                  <Input
                    type="checkbox"
                    className="form-check-Input"
                    id="customSwitchsizemd-email"
                    onClick={e =>
                      updateServiceList("isEmail", e.target.checked, index)
                    }
                    checked={service.isEmail}
                    defaultChecked={service.isEmail}
                  />
                </td>
                <td>
                  <Input
                    type="checkbox"
                    className="form-check-Input"
                    id="customSwitchsizemd-email"
                    onClick={e =>
                      updateServiceList("isApp", e.target.checked, index)
                    }
                    checked={service.isApp}
                    defaultChecked={service.isApp}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <NotificationAlertEmail
        emailsList={emailsList}
        removeEmail={removeEmail}
        addNewEmail={addNewEmail}
        updateEmail={updateEmail}
      />
      <div className="border-top pt-3 mt-2 text-end">
        <LoadingButton
          color="secondary"
          className="btn btn-outline-primary submitbtn btn-right-margin"
          loading={false}
          disabled={false}
          block={false}
          onClick={() => tog_preferences()}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          color="primary"
          className="btn btn-primary submitbtn "
          loading={isLoading}
          disabled={isLoading}
          block={false}
          onClick={() =>
            props
              .putPreferencesAction({
                data: {
                  preferences: {
                    ...preferenceInput,
                  },
                  notificationAlert: {
                    ...notificationAlertInput,
                  },
                  notificationService: {
                    ...notificationServiceInput,
                  },
                },
                memberId: selectedUser?.id,
              })
              .then(() => {
                tog_preferences()
              })
          }
        >
          Save
        </LoadingButton>
      </div>
    </div>
  )
}

NotificationService.propTypes = {
  preferenceInput: PropTypes.obj,
  notificationAlertInput: PropTypes.obj,
  notificationServiceInput: PropTypes.obj,
  updateNotificationServiceField: PropTypes.func,
  updateNotificationAlertField: PropTypes.func,
  selectedUser: PropTypes.obj,
  tog_preferences: PropTypes.func,
  putPreferencesAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  putPreferencesAction: bindActionToPromise(dispatch, putPreferences),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationService)
