import {
  GET_DEVICES_BY_MEMBERID,
  GET_DEVICES_BY_MEMBERID_FAIL,
  GET_DEVICES_BY_MEMBERID_SUCCESS,
  GET_DEVICES_INFO_BY_MEMBERID,
  GET_DEVICES_INFO_BY_MEMBERID_FAIL,
  GET_DEVICES_INFO_BY_MEMBERID_SUCCESS,
  GET_DEVICE_BY_DEVICEID,
  GET_DEVICE_BY_DEVICEID_SUCCESS,
  GET_DEVICE_BY_DEVICEID_FAIL,
  PUT_DEVICE_BY_DEVICEID,
  PUT_DEVICE_BY_DEVICEID_SUCCESS,
  PUT_DEVICE_BY_DEVICEID_FAIL,
  PUT_DEVICE_INSSTALLATION_INFO,
  PUT_DEVICE_INSSTALLATION_INFO_SUCCESS,
  PUT_DEVICE_INSSTALLATION_INFO_FAIL,
  PUT_DEVICE_FUEL,
  PUT_DEVICE_FUEL_SUCCESS,
  PUT_DEVICE_FUEL_FAIL,
  PUT_DEVICE_ALARM,
  PUT_DEVICE_ALARM_SUCCESS,
  PUT_DEVICE_ALARM_FAIL,
} from "./actionTypes"

export const getDevicesByMemberId = payload => ({
  type: GET_DEVICES_BY_MEMBERID,
  payload,
})

export const getDevicesByMemberIdSuccess = devices => ({
  type: GET_DEVICES_BY_MEMBERID_SUCCESS,
  payload: devices,
})

export const getDevicesByMemberIdFail = error => ({
  type: GET_DEVICES_BY_MEMBERID_FAIL,
  payload: error,
})

export const getDevicesInfoByMemberId = memberId => ({
  type: GET_DEVICES_INFO_BY_MEMBERID,
  memberId,
})

export const getDevicesInfoByMemberIdSuccess = devices => ({
  type: GET_DEVICES_INFO_BY_MEMBERID_SUCCESS,
  payload: devices,
})

export const getDevicesInfoByMemberIdFail = error => ({
  type: GET_DEVICES_INFO_BY_MEMBERID_FAIL,
  payload: error,
})

export const getDeviceByDeviceId = deviceId => ({
  type: GET_DEVICE_BY_DEVICEID,
  deviceId,
})

export const getDeviceByDeviceIdSuccess = payload => ({
  type: GET_DEVICE_BY_DEVICEID_SUCCESS,
  payload: payload,
})

export const getDeviceByDeviceIdFail = error => ({
  type: GET_DEVICE_BY_DEVICEID_FAIL,
  payload: error,
})

export const putDeviceByDeviceId = payload => ({
  type: PUT_DEVICE_BY_DEVICEID,
  payload,
})

export const putDeviceByDeviceIdSuccess = device => ({
  type: PUT_DEVICE_BY_DEVICEID_SUCCESS,
  payload: device,
})

export const putDeviceByDeviceIdFail = error => ({
  type: PUT_DEVICE_BY_DEVICEID_FAIL,
  payload: error,
})

export const putDeviceInstallationInfo = payload => ({
  type: PUT_DEVICE_INSSTALLATION_INFO,
  payload,
})

export const putDeviceInstallationInfoSuccess = device => ({
  type: PUT_DEVICE_INSSTALLATION_INFO_SUCCESS,
  payload: device,
})

export const putDeviceInstallationInfoFail = error => ({
  type: PUT_DEVICE_INSSTALLATION_INFO_FAIL,
  payload: error,
})

export const putDeviceFuel = payload => ({
  type: PUT_DEVICE_FUEL,
  payload,
})

export const putDeviceFuelSuccess = device => ({
  type: PUT_DEVICE_FUEL_SUCCESS,
  payload: device,
})

export const putDeviceFuelFail = error => ({
  type: PUT_DEVICE_FUEL_FAIL,
  payload: error,
})

//Device alert information 
export const putAlarmSettings = payload => ({
  type: PUT_DEVICE_ALARM,
  payload,
})

export const putAlarmSettingsSuccess = device => ({
  type: PUT_DEVICE_ALARM_SUCCESS,
  payload: device,
})

export const putAlarmSettingsFail = error => ({
  type: PUT_DEVICE_ALARM_FAIL,
  payload: error,
})