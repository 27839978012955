/* eslint-disable */

import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import SendCommandInfo from "./SendCommandInfo"
import CommandHistory from "./CommandHistory"
import lefticon15 from "../../../assets/images/icons/command.svg"
import Tooltip from "@mui/material/Tooltip"


const MonitorMapComman = () => {
  const [CommandMap, setCommandMap] = useState(false)
  function CommandMapBtn() {
    setCommandMap(!CommandMap)
    removeBodyCss()
  }

  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  return (
    <div>
      <span
        onClick={() => {
          CommandMapBtn()
        }}
        className="cursor-pointer"
      >
        
        <Tooltip title="Command" placement="bottom" arrow>
       <img alt="" src={lefticon15} width={21} />
       </Tooltip>
      </span>

      <Modal
        isOpen={CommandMap}
        size="lg"
        toggle={() => {
          CommandMapBtn()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Command</h5>
          <button
            type="button"
            onClick={() => {
              setCommandMap(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>

        <Nav tabs className="nav-tabs-custom position-relative" role="tablist">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1")
              }}
            >
              Send Command
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggleTab("2")
              }}
            >
              Command History
            </NavLink>
          </NavItem>

          <span className="command_sched txt_link">Schedule for command</span>
        </Nav>

        <TabContent
          activeTab={activeTab}
          className="crypto-buy-sell-nav-content p-0 border-0"
        >
          <TabPane tabId="1">
            <div className="">
              <SendCommandInfo />
            </div>
          </TabPane>

          <TabPane tabId="2">
            <CommandHistory />
          </TabPane>
        </TabContent>
      </Modal>
    </div>
  )
}

export default MonitorMapComman
