/* eslint-disable */

import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"
import CheckPopup from "./CheckPopup"

function MoveDevice() {
  const [popoverright, setpopoverright] = useState(false);

  return (
    <div>
      <div className="modal-body">
        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            Target user <small className="text-danger">*</small>
          </label>
          <div className="col-md-8">
            <select className="form-control">
              <option>Select</option>
              <option>Large select</option>
              <option>Small select</option>
            </select>
          </div>
        </Row>
       
        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            IMEI <small className="text-danger">*</small>
          </label>
          <div className="col-md-8">
            <div className="row gx-2">
              <div className="col">
                <textarea rows="4" className="form-control"></textarea>
                <span className="font-size-12">One batch for 200 IMEI at most.</span>
              </div>
              <div className="col-auto">
                
                <Button
                      id="Popoverright"
                      onClick={() => {
                        setpopoverright(!popoverright);
                      }}
                      className="btn btn-primary btn-sm"
                    >
                     Check
                    </Button>
               
                    <Popover
                      placement="right"
                      isOpen={popoverright}
                      target="Popoverright"
                      toggle={() => {
                        setpopoverright(!popoverright);
                      }}
                      className="width350"
                    >
                      <PopoverBody>
                      <span
                      id="Popoverright"
                      onClick={() => {
                        setpopoverright(!popoverright);
                      }}
                      className="popoverclose"
                    >
                      <i className="mdi mdi-close"></i>
                    </span>
                       <CheckPopup />
                      </PopoverBody>
                    </Popover>

              </div>
            </div>
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary ">
          Save changes
        </button>
        
      </div>
    </div>
  )
}

export default MoveDevice
