/* eslint-disable */

import React, { Component, useState } from "react"
// import React, { Component }  from 'react';
// import { Formik, Field, Form, ErrorMessage } from "formik"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Card,
  Label,
  Button,
  Col,
  CardBody,
  Alert,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"

import { useSelector, useDispatch } from "react-redux"

import LoadingButton from "../Common/Button"

import * as Yup from "yup"
import { useFormik } from "formik"

import { userChangePassword } from "../../store/actions"

import { getLoggedInUserID } from "../../helpers/fakebackend_helper"

const ChangePassword = props => {
  const dispatch = useDispatch()

  const { loading } = useSelector(state => {
    return { loading: state.ChangePassword.loading }
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required("Please Enter Your Current Password")
        .min(6, "Current Password should be at least 6 character"),
      newPassword: Yup.string()
        .required("Please Enter Your New Password")
        .min(6, "New Password should be at least 6 character")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "Current Password and New Password Can Not Be Same"
        ),
      newPasswordConfirm: Yup.string()
        .required("Please Enter Your Confirm New Password")
        .min(6, "Confirm New Password should be at least 6 character")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "Current Password and Confirm New Password Can Not Be Same"
        ),
    }),
    onSubmit: values => {
      const payload = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        memberId: getLoggedInUserID(),
      }
      dispatch(userChangePassword(payload, props.history))
    },
  })

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-5 col-form-label text-start"
              >
                Old password <small className="text-danger">*</small>
              </label>
              <div className="col-md-7">
                <Input
                  name="oldPassword"
                  className="form-control"
                  placeholder="Enter Current Password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.oldPassword || ""}
                  invalid={
                    validation.touched.oldPassword &&
                    validation.errors.oldPassword
                      ? true
                      : false
                  }
                />
                {validation.touched.oldPassword &&
                validation.errors.oldPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.oldPassword}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-5 col-form-label text-start"
              >
                New password <small className="text-danger">*</small>
              </label>
              <div className="col-md-7">
                <Input
                  name="newPassword"
                  className="form-control"
                  placeholder="Enter New Password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.newPassword || ""}
                  invalid={
                    validation.touched.newPassword &&
                    validation.errors.newPassword
                      ? true
                      : false
                  }
                />
                {validation.touched.newPassword &&
                validation.errors.newPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.newPassword}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-5 col-form-label text-start"
              >
                Confirm password <small className="text-danger">*</small>
              </label>
              <div className="col-md-7">
                <Input
                  name="newPasswordConfirm"
                  className="form-control"
                  placeholder="Enter Confirm New Password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.newPasswordConfirm || ""}
                  invalid={
                    validation.touched.newPasswordConfirm &&
                    validation.errors.newPasswordConfirm
                      ? true
                      : false
                  }
                />
                {validation.touched.newPasswordConfirm &&
                validation.errors.newPasswordConfirm ? (
                  <FormFeedback type="invalid">
                    {validation.errors.newPasswordConfirm}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>

            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-5 col-form-label text-start"
              ></label>
              <div className="col-md-7">
                <LoadingButton
                  color="primary"
                  className="btn btn-primary submitbtn "
                  loading={loading}
                  disabled={loading}
                  block={false}
                  type="submit"
                >
                  Save
                </LoadingButton>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword
