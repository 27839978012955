/* eslint-disable */
import axios from "axios"
import { showToast } from "./utils/Common"

export default {
  setupInterceptors: store => {
    axios.interceptors.response.use(
      function (response) {
        if (response?.status == 401) {
          showToast("Please Login", false)
          localStorage.clear()
          window.location.href = "/login"
        } else {
          return response
        }
      },
      function (error) {
        if (error?.response?.status == 401) {
          showToast("Please Login", false)
          localStorage.clear()
          window.location.href = "/login"
        }
        return Promise.reject(error)
      }
    )
  },
}
