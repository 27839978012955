/* eslint-disable */

import React, { useState, useEffect } from "react"

import { Formik, Field, Form, ErrorMessage } from "formik"
import * as yup from "yup"
import { Row, Tooltip, Spinner } from "reactstrap"

import moment from "moment"

import { connect } from "react-redux"

import Select from "react-select"

import classnames from "classnames"

import { useSelector, useDispatch } from "react-redux"

import { updateLoginUser, uploadImage } from "../../store/actions"

import LoadingButton from "../Common/Button"

import { loginUserDetail } from "../../store/actions"

import { fileToBase64, TimeZoneOptionList } from "../../utils/Common"

import usicons1 from "../../assets/images/alhadeed.png"

const userFormScheme = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  timezone: yup.string().required("Time zone is required"),
  // contactNumber: yup.string().matches(/^(\+91)/, "Please add +91 code"),
  // telephone: yup.string().matches(/^(\+91)/, "Please add +91 code"),
})

function Profile(props) {
  const { profileData } = props
  const dispatch = useDispatch()

  const { isLoading, loadingData } = useSelector(state => {
    return {
      isLoading: state.Login.isLoading,
      loadingData: state.Login.loadingData,
    }
  })

  const { member } = profileData

  const [ttop, setttop] = useState(false)
  const [emaitooltip, setEmaitooltip] = useState(false)
  const [userImage, setUserImage] = useState(member?.profileImage)
  const [file, setFileData] = useState()

  useEffect(() => {
    dispatch(loginUserDetail())
  }, [])

  useEffect(() => {
    setUserImage(member?.profileImage)
  }, [member])

  const optionsTimeZone = TimeZoneOptionList()

  const onImageChange = e => {
    fileToBase64(e).then(res => {
      setUserImage(res.file)
      setFileData(e.target.files[0])
    })
  }

  if (loadingData) {
    return (
      <Spinner
        className={classnames({
          "position-relative": true,
          "button-style": false,
          visible: true,
          "left-50": true,
        })}
        size="lg"
        // type="grow"
      />
    )
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: member?.name || "",
          // loginAccount: member?.username || "",
          contactNumber: member?.contactNumber || "",
          mobileNumber: member?.mobileNumber || "",
          telephone: member?.telephone || "",
          email: member?.email || "",
          address: member?.address || "",
          webpage: member?.webpage || "",
          timezone: member?.timezone || "",
          // role: profileData?.roles[0] || "",
        }}
        validationSchema={userFormScheme}
        onSubmit={values => {
          if (values) {
            const {
              name,
              contactNumber,
              mobileNumber,
              telephone,
              email,
              address,
              webpage,
              timezone,
            } = values

            dispatch(
              updateLoginUser(
                {
                  name,
                  contactNumber,
                  // mobileNumber,
                  telephone,
                  email,
                  address,
                  webpage,
                  timezone,
                },
                member?.id,
                props.history,
                userImage !== member?.profileImage,
                [
                  {
                    file,
                  },
                ]
              )
            )
          }
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
        }) => (
          <>
            <Form>
              <div className="row">
                <div className="col-lg-6">
                  <Row className="mb-3 align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Account
                    </label>
                    <div className="col-md-7">{member?.username}</div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      User name
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-username"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  {/* {profileData?.roles[0] !== "admin" && (
                    <Row className="mb-3 align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-5 col-form-label text-end"
                      >
                        Role <small className="text-danger"> *</small>
                      </label>
                      <div className="col-md-7 d-flex">
                        <div className="form-check pe-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="role"
                            id="exampleRadios2"
                            value="distributor"
                            defaultChecked
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            <i className="bx bx-user color_1"></i> Distributor
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="role"
                            id="exampleRadios2"
                            value="option2"
                            disabled={true}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            <i className="bx bx-user color_2"></i> End User
                          </label>
                        </div>
                      </div>
                    </Row>
                  )} */}
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Time zone <small className="text-danger">*</small>
                    </label>
                    <div className="col-md-7">
                      <Select
                        classNamePrefix="select2-selection"
                        placeholder="Please Select"
                        title="timezone"
                        name="timezone"
                        isClearable={false}
                        options={optionsTimeZone}
                        isSearchable={false}
                        onChange={selectedOption =>
                          setFieldValue("timezone", selectedOption.value)
                        }
                        value={optionsTimeZone.filter(option => {
                          if (option.value == values.timezone) return option
                        })}
                        onBlur={() => {
                          handleBlur({ target: { name: "timezone" } })
                        }}
                      />
                      <ErrorMessage
                        name="timezone"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Contact
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-contact-Input"
                        name="contactNumber"
                      />
                      <ErrorMessage
                        name="contactNumber"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Telephone
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-tephone-Input"
                        name="telephone"
                      />
                      <ErrorMessage
                        name="telephone"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Address
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-address-Input"
                        name="address"
                      />
                      <ErrorMessage
                        name="address"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 text-end"
                    >
                      Create time
                    </label>
                    <div className="col-md-7">
                      {moment(member?.createdAt).format("YYYY-MM-DD hh:mm:ss")}
                    </div>
                  </Row>
                </div>
                <div className="col-lg-6">
                  <Row className="mb-5 pb-5">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 text-end"
                    >
                      Profile photo:
                    </label>
                    <div className="col-md-7 d-flex">
                      <div className="profi_img_row position-relative me-1">
                        <img className="profile_img" src={userImage} alt="" />
                        <div className="upload_cus">
                          <i className="bx bxs-pencil upload_edit d-flex align-items-center justify-content-center"></i>
                          <input
                            type="file"
                            name="file"
                            onChange={onImageChange}
                          />
                        </div>
                      </div>
                      <div>
                        <i
                          className="bx bxs-info-circle info_icon"
                          id="ProfileTooltip"
                        ></i>
                      </div>
                    </div>
                  </Row>
                  <Row className="mb-3 pt-4">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Mobile
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-mobile-Input"
                        name="mobileNumber"
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Email <small className="text-danger">*</small>
                      <i
                        className="bx bxs-info-circle info_icon"
                        id="EmailTootlip"
                      ></i>
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-email-Input"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      WebPage
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-webpage-Input"
                        name="webpage"
                      />
                      <ErrorMessage
                        name="webpage"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                </div>
                <Row className="mb-3">
                  <div className="col-md-6">
                    <Row className="mb-3">
                      <div className="col-md-5"></div>
                      <div className="col-md-7">
                        {" "}
                        <LoadingButton
                          color="primary"
                          className="btn btn-primary submitbtn "
                          loading={isLoading}
                          disabled={isLoading}
                          block={false}
                          type="submit"
                        >
                          Save changes
                        </LoadingButton>
                      </div>
                    </Row>
                  </div>
                </Row>
              </div>
              <Tooltip
                placement="top"
                isOpen={ttop}
                target="ProfileTooltip"
                toggle={() => {
                  setttop(!ttop)
                }}
              >
                JPG/PNG/GIF, 1 MB
              </Tooltip>
              <Tooltip
                placement="top"
                isOpen={emaitooltip}
                target="EmailTootlip"
                toggle={() => {
                  setEmaitooltip(!emaitooltip)
                }}
              >
                You can retrieve your password through this email address,
                please be sure to fill in the valid one.
              </Tooltip>
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    ...state,
    profileData: state.Login.profileData,
    imageData: state.Login.imageData,
  }
}

export default connect(mapStateToProps, {})(Profile)
