import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Popover } from 'antd';
import PropTypes from "prop-types"
import "./UserDrop.css"
//Import Breadcrumb
import Breadcrumbs from "../Common/Breadcrumb";
import Information from "../../components/Info/Information"
import Profile from "../../components/Profile/Profile"

const TreeRightMonitor = ({ popUpHandler, userInfo }) => {

  const [popoverOpen, setPopoverOpen] = useState(false);

  const popContent = (
    <div className="popuserright">
      <ul>
        <li onClick={() => {
          popUpHandler()
        }}>Information</li>
      </ul>
    </div>
  );


  return (
    <React.Fragment>
      <Popover content={popContent} placement="right">
        <span type="primary" className="dotteduser"> <i className="bx bx-dots-vertical-rounded"></i></span>
      </Popover>
    
    </React.Fragment>
  );
};
TreeRightMonitor.propTypes = {
  popUpHandler: PropTypes.func,
  userInfo: PropTypes.object

}
export default TreeRightMonitor;
