/* eslint-disable */
import React, { useState, useEffect, useRef, memo } from "react"
import Geocode from "react-geocode"
import _ from "lodash"
//Import Breadcrumb
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import { Popup, Tooltip } from "react-leaflet"
import L from "leaflet"
import LeafMap from "../../../components/Monitor/component/LeafMap"
Geocode.setApiKey("AIzaSyCVPM_AKcsmM6GvZmx5xpizXasMuho4c24")
Geocode.enableDebug()
import { Spinner, Modal } from "reactstrap"
import classnames from "classnames"

import AirplaneMarker from "./playBackMarker"
import airplaneIcon from "./arrow_head.png"
import AddBulkMarker from "./multipleMarker"
import PolylineDecorator from "./polyLineForMap"
import StartAndEndPoint from "./startAndEndPoint"




function findIndexByKey(arr, field, value) {
  for (let i = 0; i < arr.length; i++) {
    if (parseInt(arr[i][field], 10) === parseInt(value, 10)) {
      return i
    }
  }
  return -1 // If key not found in any object, return -1
}



const PlayBackMap = ({ mapData }) => {
  const {
    currentTrack,
    locationData,
    playBackHistoryLoading,
    playBackHistory,
    filterData,
    endPopupHandler,
    endPlayBackPopUp,
  } = mapData
  const dispatch = useDispatch()
  const mapRef = useRef(null)

  
 if (endPlayBackPopUp){ document.body.classList.add("no_padding") } 

  
  //=============================================================
console.log(currentTrack)
  return (
    <>
      <Spinner
        className={classnames({
          "position-relative": true,
          "button-style": false,
          visible: true,
          "left-50": true,
        })}
        size="lg"
        type="grow"
        color="danger"
      />
      <LeafMap
        whenReady={map => {
          mapRef.current = map
        }}
        zoom={7}
        center={[13.063036666666667, 80.27216888888888]}
      >
        {/* {!isLoading && playBackHistory.length>0 && playBackHistory.map((currentMarker)=>{

          return <PointMarker data={currentMarker ?? {}} />
        })} */}

        {!playBackHistoryLoading &&
          playBackHistory &&
          playBackHistory.length > 0 && (
            <AddBulkMarker markerData={playBackHistory} />
          )}
        {/* {!isLoading && playBackHistory && playBackHistory.length > 0 && (
          <StartAndEndPoint startAndEndPoint={[playBackHistory[0],playBackHistory[playBackHistory.length]-1]} />
        )} */}

        {!playBackHistoryLoading &&
          currentTrack &&
          currentTrack.lat &&
          currentTrack.lng && (
            <>
              <AirplaneMarker
                data={currentTrack ?? {}}
                speedMarker={filterData?.speedVal || 50}
              />

              {/* <ReactLeafletDriftMarker
              position={trackData[trackData.length - 1]}
              duration={100}
              keepAtCenter={true}
            ></ReactLeafletDriftMarker> */}
              <PolylineDecorator polyline={locationData} />
            </>
          )}
      </LeafMap>

      <Modal
        size="lg"
        isOpen={endPlayBackPopUp}
        toggle={() => {
         endPopupHandler()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Playback Info</h5>
          <button
            type="button"
            onClick={() => {
             endPopupHandler()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <h1>Finished</h1>
        <p>
          From: {moment(filterData.fromDate).format("YYYY-MM-DD HH:mm")} To:{" "}
          {moment(filterData.toDate).format("YYYY-MM-DD HH:mm")}
        </p>
      </Modal>
    </>
  )
}

export default memo(PlayBackMap)
