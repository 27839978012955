/* eslint-disable */
import React from "react"
import {
  GET_TRACK_INITIAL,
  GET_TRACK_DETAILS_FAIL,
  GET_TRACK_DETAILS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  trackDetails: [],
  error: {},
  isLoading: false,
}

const TrackPageDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRACK_INITIAL:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TRACK_DETAILS_SUCCESS:
      return {
        ...state,
        trackDetails: [...action.payload],
        isLoading: false,
      }

    case GET_TRACK_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    default:
      return state
  }
}

export default TrackPageDetails
