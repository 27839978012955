/* eslint-disable */

import axios from "axios"
import {
  del,
  get,
  post,
  put,
  postLogin,
  postForgotPass,
  putChangePass,
  putById,
  getTreeMenuDetails,
  uploadIamages,
  putChangePassOfMember,
  getMonitor,
  getWithPayload,
  deleteWithPayload,
  getTrack,
  getMonitorDetailsNew,
  getPlayBackDetails,
} from "./api_helper"
import * as url from "./url_helper"
import moment from "moment"
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

const getLoggedInUserID = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user).memberId
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

export const logoutUser = history => {
  localStorage.removeItem("authUser")
  history.push({ pathname: "/login" })
}

// Login Method
const postAuthLogin = data => postLogin(url.POST_AUTH_LOGIN, data)

// postForgetPwd
const postForgetPwd = data => postForgotPass(url.POST_PASSWORD_FORGET, data)

// addUSer
const postAddUser = data => post(url.POST_ADD_USER, data)
const addPostCard = data => post(url.ADD_CARD_URL, data)
const addPostTransferCard = data => post(url.ADD_TRANSFER_CARD_URL, data)

const updateUser = data => putById(url.POST_ADD_USER, data)

const updateLoginUser = data => put(url.UPDATE_USER_PROFILE, data)

const postAddDevice = data => post(url.POST_ADD_DEVICE, data)
const putChangePwd = data => putChangePass(url.PUT_PASSWORD_CHANGE, data)

const putChangePwdOfMember = data =>
  putChangePassOfMember(url.CHANGE_PASS_OF_MEMBER, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)
const postTransferCard = data => post(url.ADD_TRANSFER_CARD_URL, data)
const getIMEDetailsData = async data =>
  await post(url.CHECK_GET_IMEI_DETAILS, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

const getDevicesByMember = payload =>
  getWithPayload(`${url.DEVICES_BY_MEMBERID}/${payload.parentId}`, {
    payload: payload.data,
  })

const getDevicesInfoByMember = id =>
  get(`${url.DEVICES_INFO_BY_MEMBERID}/?memberId=${id}`, { params: { id } })

const getSearchDeviceDataByQuery = data =>
  get(`${url.DEVICES_INFO_BY_QUERY}/?searchQuery=${data}`, { params: { data } })

const checkImei = data => post(url.CHECK_IMEI, data)
const bulksaleURL = data => post(url.BULKSALE, data)
const rechargeURL = data => post(url.RECHARGE, data)

const modifyExpiryDate = data => post(url.MODIFY_EXPIRY_DATE, data)

const plateformExpiryDate = data => post(url.PLATEFORM_MODIFY_EXPIRY_DATE, data)

const addToVirtualDevice = data => post(url.ADD_TO_VIRTUAL_DEVICE, data)

const getSearchUserDataByQuery = data =>
  get(`${url.USERS_INFO_BY_QUERY}?searchQuery=${data}`, { params: { data } })

const getUserDetail = id =>
  get(`${url.GET_USER_DETAIL}/${id}`, { params: { id } })

const getAllCardsSaga = () => get(`${url.GET_ALL_CARDS}`)

const getAllCardCount = () => get(`${url.GET_ALL_CARD_COUNT}`)

const getCardBalanceData = async id =>
  get(`${url.GET_CARD_BALANCE_BY_ID}${id}`, { params: { id } })
const getAllDistribitutorSaga = () => get(`${url.GET_ALL_DISTRIBUTOR_URL}`)

// tree menu
const getTreeMenu = async parentId =>
  await getTreeMenuDetails(
    `${url.GET_USER_BY_PARENT_ID}${parentId}`,
    parentId,
    { params: { parentId } }
  )

const getSubUsers = async payload => {
  return getWithPayload(`${url.GET_USER_BY_PARENT_ID}${payload.parentId}`, {
    payload: payload.data,
  })
}
const imageUpload = async images => {
  return await uploadIamages(url.Image_Upload, images)
}
const getMonitorDataNew = async selectedData =>
  await getMonitorDetailsNew(
    `${url.GET_MONITOR_DATA}${selectedData}`,
    selectedData
  )

const getPlayBackData = async memberId =>
  await getPlayBackDetails(`${url.GET_MONITOR_DATA}${memberId}`, memberId)

const getPlayBackHistory = async data => {
  const { fromDate, toDate, deviceId } = data

  return await getPlayBackDetails(
    `${url.DEVICE_HISTORY}${deviceId}/Location?startDate=${moment
      .utc(moment(fromDate))
      .format()}&endDate=${moment.utc(moment(toDate)).format()}`
  )
}
const getMonitorData = async selectedData =>
  getMonitor(
    [
      `${url.GET_MONITOR_DATA}${selectedData}`,
      `${url.GET_MONITOR_DATA}${selectedData}?isOnline=1`,
      `${url.GET_MONITOR_DATA}${selectedData}?isOnline=0`,
    ],
    {
      selectedData,
    }
  )

const getTrackDetailsData = async deviceId =>
  getTrack(
    [
      `${url.GET_TRACK_INITIAL_DATA}${deviceId}/CurrentLocationInitial`,
      `${url.GET_TRACK_INITIAL_DATA}${deviceId}`,
    ],
    {
      deviceId,
    }
  )

const deleteUserById = async memberId => {
  return await del(`${url.GET_USER_DETAIL}/${memberId}`)
}

// card module
const getCardsData = () => get(`${url.GET_ALL_CARDS}`, { params: { parentId } })

const getGeoFenceDataDetails = async memberId =>
  get(`${url.GET_GEO_FENCE}?memberId=${memberId}`)

const addMessage = async data => await post(`${url.MESSAGE}`, data)

const getSentMessage = async data =>
  await getWithPayload(`${url.MESSAGE}/SENT`, data)

const getReceivedMessage = async data =>
  await getWithPayload(`${url.MESSAGE}/Receive`, data)

const deleteMessage = async data =>
  await deleteWithPayload(`${url.MESSAGE}`, data)

const putMessage = async data => await put(`${url.MESSAGE}`, data)

const getPreferences = async memberId =>
  await get(`${url.PREFERENCES}/${memberId}`)

const putPreferences = async ({ memberId, data }) =>
  await put(`${url.PREFERENCES}/${memberId}`, data)
const getDeviceByDeviceId = async deviceId =>
  await get(`${url.DEVICE_DETAILED}/${deviceId}`)

const putDeviceByDeviceId = async data =>
  await put(`${url.DEVICE}/${data.id}`, data.data)

const putDeviceInstallationInfo = async data =>
  await post(`${url.DEVICE}/InstallationInfo`, data.data)

const putDeviceFuel = async data => await post(`${url.DEVICE}/Fuel`, data.data)
const putDeviceAlarmSettings = async data => {
  await post(`${url.DEVICE}/Alert`, data.data)
}
const getLoginUserDetail = async () => await get(`${url.LOGIN_USER}`)

const getDeviceGroups = async memberId =>
  await get(`${url.DEVICE_GROUP}/${memberId}`)

const addDeviceGroups = async data => await post(`${url.DEVICE_GROUP}`, data)

const addSalesDetailsRequest = async data => {
  await post(`${url.DEVICE_SALE}`, data)
}

const moveAccountToTarget = async data => {
  await post(`${url.MOVE_ACCOUNT}`, data)
}

const putDeviceGroups = async data =>
  await put(`${url.DEVICE_GROUP}/${data.groupId}`, data.data)

const getReports = async () =>
  await axios.get(`https://fakestoreapi.com/products`)

export {
  getLoggedInUser,
  getLoggedInUserID,
  isUserAuthenticated,
  postAuthLogin,
  postFakeProfile,
  postForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtProfile,
  postAddUser,
  putChangePwd,
  postAddDevice,
  getDevicesByMember,
  getDevicesInfoByMember,
  getUserDetail,
  updateUser,
  getTreeMenu,
  imageUpload,
  updateLoginUser,
  getSubUsers,
  putChangePwdOfMember,
  addMessage,
  getSentMessage,
  getReceivedMessage,
  getMonitorData,
  deleteUserById,
  getAllCardsSaga,
  addPostCard,
  addPostTransferCard,
  getAllDistribitutorSaga,
  postTransferCard,
  deleteMessage,
  putMessage,
  getPreferences,
  putPreferences,
  getDeviceByDeviceId,
  putDeviceByDeviceId,
  putDeviceInstallationInfo,
  putDeviceFuel,
  getAllCardCount,
  getLoginUserDetail,
  getCardBalanceData,
  getSearchDeviceDataByQuery,
  getSearchUserDataByQuery,
  checkImei,
  bulksaleURL,
  rechargeURL,
  getCardsData,
  modifyExpiryDate,
  plateformExpiryDate,
  addToVirtualDevice,
  getDeviceGroups,
  addDeviceGroups,
  putDeviceGroups,
  getTrackDetailsData,
  getMonitorDataNew,
  getReports,
  getPlayBackData,
  putDeviceAlarmSettings,
  getPlayBackHistory,
  getIMEDetailsData,
  addSalesDetailsRequest,
  moveAccountToTarget,
  getGeoFenceDataDetails,
}
