import { TreeSelect } from 'antd';
import React, { useState } from 'react';
import usicons1 from "../../assets/images/user.svg"
import usicons2 from "../../assets/images/user2.svg"
import usicons3 from "../../assets/images/user3.svg"
import usicons4 from "../../assets/images/user4.svg"
import PropTypes from "prop-types"
import axios from "axios"
const TreeMenuSelectPopUp = props => { 
console.log(props)
  const [value, setValue] = useState();
  const userDetails = JSON.parse(localStorage.getItem("authUser"));
  const [treeLine, setTreeLine] = useState(true);
  const [showLeafIcon, setShowLeafIcon] = useState(false)
  const [treeData, setTreeData] = useState([
    {
      id: userDetails.memberId,
      pId: 0,
      value: userDetails.memberId,
      title: userDetails.member.name,
      icon: <img src={usicons1} />,
    }
  ]);
  const onChange = value => {
    props.parentCallback(value)
  }

  const onSelect = (value, node, extra) => {
    handleCallBack(value, node)
  }


  const genTreeNode = async (parentId) => {
    const random = Math.random().toString(36).substring(2, 6);
    const axconfig = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/Member/MenuTree/SubUser/${parentId}`,
      headers: {
        accept: "/",
        Authorization: `Bearer ${userDetails.accessToken}`,
      },
    }

      const response = await axios(axconfig)


    response.data.rows.forEach(userDetails => {
        
        let leafstatus
        delete userDetails.key
        if (userDetails.childMemberCount === "0") {
          leafstatus = true
        } else {
          leafstatus = false
        }
  
        if (userDetails.role === "end_user") {
            userDetails.icon = <img src={usicons1} />
          } else if (userDetails.role === "distributor") {
            userDetails.icon = <img src={usicons1} />
          } else if (userDetails.role === "virtual") {
            userDetails.icon = <img src={usicons2} />
          }
        userDetails.id=userDetails.id;
        userDetails.pId = parentId;
        userDetails.value = `${userDetails.id}`;
        userDetails.title = `${userDetails.name}`;
        userDetails.isLeaf = leafstatus;
      })
 
      return response.data.rows
  };

  const onLoadData = ({ id }) =>
    new Promise( (resolve) => {
      setTimeout(async() => {
        setTreeData(
          treeData.concat(await genTreeNode(id)),
        );
        resolve(undefined);
      }, 300);
    });


    return (
      <TreeSelect
        treeDataSimpleMode
        style={{ width: "100%" }}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        // placeholder="Please select"
        onChange={onChange}
        loadData={onLoadData}
        treeData={treeData}
        status="warning"
        allowClear
        defaultValue={props?.selectedValue?.name || "Select"}
        treeDefaultExpandAll={true}
      />
    )
};

TreeMenuSelectPopUp.propTypes = {
  parentCallback: PropTypes.func,
    selectedValue: PropTypes.string
  
  }

export default TreeMenuSelectPopUp;