import React from "react"
import { Link } from "react-router-dom"

const GeocodingAPI = () => {
  return (
    <div>
      <div className="bg_cus_2 p-3 d-flex align-items-center">
        <span className="pe-2 flx_0">
          <i className="helpIcon  bx bxs-info-circle font-size-16"></i>
        </span>
        <p className="flx_1">
          Geocoding API is used to access Google Map Address Services. If you
          want the address information to be more accurate, you should register
          on the Google API Console and get your own key. Having an API key, you
          are given your individual free limit of user queries according to your
          API quota.
          <Link className="link_txt ps-2">Google Maps API pricing</Link>
        </p>
      </div>
      <div className="pb-4 pt-4">
        <button className="btn btn-primary btn-sm" type="button">
          Add Key
        </button>
        <Link className="link_txt ps-2">How to get?</Link>
      </div>

      <div>
        <table className="table mb-0 table">
          <thead>
            <tr>
              <th>Key</th>
              <th width="120">Type</th>
              <th width="130">API key status</th>
              <th width="100">Status</th>
              <th width="120">Operation</th>
            </tr>
          </thead>
          <tbody>
           
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default GeocodingAPI
