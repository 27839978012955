import React from "react"

function AlertReciveVoice() {
  const ReciveVoiceData = [
    {
      id: 1,
      name: "SOS",
    },
    {
      id: 2,
      name: "External power disconnect",
    },
    {
      id: 3,
      name: "Geo-fence in",
    },
  ]
  return (
    <div>
      <div className="p-3">
        <div className="row">
          {ReciveVoiceData.map(item => {
            return (
              <div className="col-lg-6 pb-2" key={item.id}>
                <input type="checkbox" className="me-2" id={item.id} />
                <span>{item.name}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AlertReciveVoice
