/* eslint-disable */

import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Modal,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import Tooltip from "@mui/material/Tooltip"
import lefticon7 from "../../assets/images/icons/poi.svg"
import AddGroupGeoFence from "./AddGroupGeoFence"

//i18n
import { withTranslation } from "react-i18next"
import ImportExcelPopsApikey from "./ImportFromExcelFile"
const MonitorBottomPoi = props => {

  const [Addgroup, setAddgroup] = useState(false)
  function AddGroup() {
    setMenu(!menu)
    setAddgroup(!Addgroup)
    removeBodyCss()
  }


  const [Defaultgrp, setDefaultgrp] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)
  const [menu, setMenu] = useState(false)
  const [col6, setcol6] = useState(true)
  const t_col6 = () => {
    setcol6(!col6)
  }
  const [ImportExcelPop, setImportExcelPop] = useState(false)
  function ImportExcel() {
    setMenu(!menu)
    setImportExcelPop(!ImportExcelPop)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="div"
      >
        <DropdownToggle className="no-button ms-4" tag="button">
          <Tooltip title="POI" placement="top" arrow>
            <img src={lefticon7} width={30} />
          </Tooltip>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu mb_route_center p-0">
          <div className="d-flex align-items-center bot_pop_head">
            <i className="bx bxs-map pe-2 flx_0 font-size-17"></i>
            <span className="flx_1"> POI</span>
            <div className="flx_0 ps-2 pt-2">
              <span
                onClick={() => setMenu(!menu)}
                className="bx bx-message-alt-minus font-size-17 cursor-pointer"
              ></span>
            </div>
          </div>
          <SimpleBar style={{ height: "360px" }}>
            <div className="poi_header bg_cus_2 p-2 d-flex align-items-center flex-row">
              <div className="flx_0 pe-2">
                <input type="checkbox" className="form-check-input" />
              </div>
              <div className="flx_1 pe-2">
                <div className="clos_input position-relative">
                  <input className="form-control poi_head_input" type="text" />
                  <span className="txt_clr d-flex align-items-center justify-content-center">
                    X
                  </span>
                </div>
              </div>
              <div className="flx_0 pe-2">
                <button className="btn btn-primary btn-sm cus_icon_btn">
                  <i className="bx bx-trash-alt"></i>
                </button>
              </div>
              <div className="flx_0 br_1">
                <button className="btn btn-primary btn-sm cus_icon_btn">
                  <i className="bx bx-plus"></i>
                </button>
              </div>
              <div className="flx_0">
                <Dropdown
                  isOpen={singlebtn}
                  toggle={() => setSinglebtn(!singlebtn)}
                >
                  <DropdownToggle className="btn btn-primary btn-sm cus_icon_btn ps-0 pe-0">
                    <i className="bx bx-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <span
                        onClick={() => {
                          ImportExcel()
                        }}
                      >
                        Import from Excel file
                      </span>
                      <Modal
                        isOpen={ImportExcelPop}
                        size="lg"
                        toggle={() => {
                         
                          ImportExcel()
                          
                        }}
                        centered
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">
                            Import from Excel file
                          </h5>
                          <button
                            type="button"
                            onClick={() => {
                              setMenu(true)
                              setImportExcelPop(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="mdi mdi-close"></i>
                          </button>
                        </div>
                        <ImportExcelPopsApikey />
                      </Modal>
                    </DropdownItem>
                    <DropdownItem>Clone</DropdownItem>
                    <DropdownItem>
                      <span
                      className="d-block"
                        onClick={() => {
                          AddGroup()
                        }}
                      >
                        Group
                      </span>

                      <Modal
                        isOpen={Addgroup}
                        toggle={() => {
                          AddGroup()
                        }}
                        centered
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">Group</h5>
                          <button
                            type="button"
                            onClick={() => {
                              setMenu(true)
                              setAddgroup(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="mdi mdi-close"></i>
                          </button>
                        </div>
                        <AddGroupGeoFence />
                      </Modal>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>

            <div className="route_info">
              <div className="d-flex flex-row align-items-center p-2 bg-greyl">
                <div className="flx_0">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    id="customCheckcolor1"
                  />
                </div>
                <div className="flx_1">
                  <header className="Accord_head d-flex flex-row">
                    <div className="flx_1" onClick={t_col6}>
                      <i className="bx bxs-down-arrow font-size-12"></i>{" "}
                      Defaultgroup (4)
                    </div>
                  </header>
                </div>
              </div>
              <Collapse isOpen={col6} className="over_auto ps-2 pe-2">
                <ul className="deviceshown poi_list_item">
                  <li>
                    <div className="d-flex align-items-start w-100">
                      <div className="flx_1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
                          <i className="bx bxs-map font-size-20 me-1"></i>
                          <span className="">Pandian 2040</span>
                        </div>
                      </div>
                      <div className="flx_0 d-flex flex-row hover_poi">
                        <i className="bx bxs-pencil font-size-20 me-1 cursor-pointer"></i>
                        <i className="bx bxs-trash font-size-20 me-1 cursor-pointer"></i>
                        <div>
                          <Dropdown
                            isOpen={Defaultgrp}
                            toggle={() => setDefaultgrp(!Defaultgrp)}
                          >
                            <DropdownToggle className="no-button cus_btn_drop_1">
                              <i className=" bx bx-grid-horizontal font-size-20 cursor-pointer"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem>Default Group</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </Collapse>
            </div>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
export default withTranslation()(MonitorBottomPoi)
