/* eslint-disable */

import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
  Table,
} from "reactstrap"
import CheckPopup from "./CheckPopup"
import LoadingButton from "../../components/Common/Button"
import { useSelector } from "react-redux"
import { putAlarmSettings } from "../../store/actions"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionToPromise } from "../../utils/Common"
function DeviceAlertSettings(props) {
  const {
    deviceAlertSettingsInputs,
    updateDeviceAlertSettingsInputs,
    tog_model,
    selectedDevice,
  } = props
  const [popoverright, setpopoverright] = useState(false)
  const { isUpdateDeviceLoading } = useSelector(state => {
    return {
      isUpdateDeviceLoading: state.Device.isUpdateDeviceLoading,
    }
  })

function generatePayloadForUpdate() {
  return {
    data: {
      deviceId: selectedDevice?.id,
      lowTemperature: deviceAlertSettingsInputs?.lowTemperature || "3",
      engineIdle: deviceAlertSettingsInputs?.engineIdle || "0",
      idleAlertMin: deviceAlertSettingsInputs?.idleAlertMin || "0",
      speedingAlert: deviceAlertSettingsInputs?.speedingAlert || "0",
      speedingKM: deviceAlertSettingsInputs?.speedingKM || "0",
      restrictedDriving: [
        {
          from: "08:00",
          to: "10:00",
        },
      ],
      engineOff: deviceAlertSettingsInputs?.engineOff || false,
      engineOn: deviceAlertSettingsInputs?.engineOn || false,
      doorClose: deviceAlertSettingsInputs?.doorClose || false,
      doorOpen: deviceAlertSettingsInputs?.doorOpen || false,
      oilLeakAlert: deviceAlertSettingsInputs?.oilLeakAlert || false,
      offline: deviceAlertSettingsInputs?.offline || false,
      parking: deviceAlertSettingsInputs?.parking || "0",
      parkingAlertMin: deviceAlertSettingsInputs?.parkingAlertMin || false,
      refueling: false,
      geoFence: [
        {
          name: "string",
          in: true,
          out: true,
          speeding: true,
        },
      ],
    },
  }
}
  return (
    <div>
      <div className="modal-body">
        <Row className="mb-0">
          {/* //Engine OFF */}
          <div className="col-md-4">
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="horizontal-customCheck-auto2"
                onClick={e =>
                  updateDeviceAlertSettingsInputs("engineOff", e.target.checked)
                }
                checked={deviceAlertSettingsInputs?.engineOff}
              />
              <label className="form-check-label" htmlFor="engineOff">
                Engine OFF
              </label>
            </div>
          </div>
          {/* //Engine On */}
          <div className="col-md-4">
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="horizontal-customCheck-auto3"
                onClick={e =>
                  updateDeviceAlertSettingsInputs("engineOn", e.target.checked)
                }
                checked={deviceAlertSettingsInputs?.engineOn}
              />
              <label className="form-check-label" htmlFor="engineOn">
                Engine On
              </label>
            </div>
          </div>
        </Row>
        <Row className="mb-0">
          {/* //Door Open */}
          <div className="col-md-4">
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="horizontal-customCheck-auto3"
                onClick={e =>
                  updateDeviceAlertSettingsInputs("doorOpen", e.target.checked)
                }
                checked={deviceAlertSettingsInputs?.doorOpen}
              />
              <label className="form-check-label" htmlFor="doorOpen">
                Door Open
              </label>
            </div>
          </div>
          {/* //Door Close */}
          <div className="col-md-4">
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="horizontal-customCheck-auto4"
                onClick={e =>
                  updateDeviceAlertSettingsInputs("doorClose", e.target.checked)
                }
                checked={deviceAlertSettingsInputs?.doorClose}
              />
              <label className="form-check-label" htmlFor="doorClose">
                Door Close
              </label>
            </div>
          </div>
        </Row>
        <Row className="mb-0">
          {/* //Oil leak alert*/}
          <div className="col-md-4">
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="horizontal-customCheck-auto5"
                onClick={e =>
                  updateDeviceAlertSettingsInputs(
                    "oilLeakAlert",
                    e.target.checked
                  )
                }
                checked={deviceAlertSettingsInputs?.oilLeakAlert}
              />
              <label className="form-check-label" htmlFor="oilLeakAlert">
                Oil leak alert
              </label>
            </div>
          </div>
          {/* //Oil leak alert*/}
          <div className="col-md-4">
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="horizontal-customCheck-auto12"
                onClick={e =>
                  updateDeviceAlertSettingsInputs(
                    "refuelingAlert",
                    e.target.checked
                  )
                }
                checked={deviceAlertSettingsInputs?.refuelingAlert}
              />
              <label className="form-check-label" htmlFor="refuelingAlert">
                Refueling alert
              </label>
            </div>
          </div>
        </Row>
        <Row className="mb-0">
          {/* //Offline alert*/}
          <div className="col-md-4">
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="horizontal-customCheck-auto7"
                onClick={e =>
                  updateDeviceAlertSettingsInputs("offline", e.target.checked)
                }
                checked={deviceAlertSettingsInputs?.offline}
              />
              <label className="form-check-label" htmlFor="offline">
                Offline alert
              </label>
            </div>
          </div>
        </Row>
        {/* //Speeding alert*/}
        <Row className="mb-0">
          <Row className="mb-3">
            <div className="col-md-12 d-flex align-items-center">
              <div className="col-auto form-check mb-0">
                <Input
                  type="checkbox"
                  className="form-check-Input"
                  id="horizontal-customCheck-auto9"
                  onClick={e =>
                    updateDeviceAlertSettingsInputs(
                      "speedingKM",
                      e.target.checked
                    )
                  }
                  checked={
                    parseInt(deviceAlertSettingsInputs?.speedingAlert) > 0
                  }
                />
                <label className="form-check-label" htmlFor="speedingAlert">
                  Speeding Alert
                </label>
              </div>
              <div className="col-auto input-group w-150x ms-3">
                <input
                  className="form-control height32"
                  type="number"
                  defaultValue="0"
                  value={deviceAlertSettingsInputs?.speedingAlert}
                  onChange={e =>
                    updateDeviceAlertSettingsInputs(
                      "speedingAlert",
                      e.target.value
                    )
                  }
                  min="0"
                />
                <div className="input-group-prepend">
                  <span
                    className="input-group-text height32"
                    id="validationTooltipUsernamePrepend"
                  >
                    kph
                  </span>
                </div>
              </div>
            </div>
          </Row>
        </Row>
        {/* //IDLE alert*/}
        <Row className="mb-0">
          <Row className="mb-3">
            <div className="col-md-12 d-flex align-items-center">
              <div className="col-auto form-check mb-0">
                <Input
                  type="checkbox"
                  className="form-check-Input"
                  id="horizontal-customCheck-auto10"
                  onClick={e =>
                    updateDeviceAlertSettingsInputs(
                      "idleAlertMin",
                      e.target.checked
                    )
                  }
                  checked={parseInt(deviceAlertSettingsInputs?.engineIdle) > 0}
                />
                <label className="form-check-label" htmlFor="htempl">
                  Idle Alert
                </label>
              </div>
              <div className="col-auto input-group w-150x ms-3">
                <input
                  className="form-control height32"
                  type="number"
                  defaultValue="0"
                  min="0"
                  value={deviceAlertSettingsInputs?.engineIdle}
                  onChange={e =>
                    updateDeviceAlertSettingsInputs(
                      "engineIdle",
                      e.target.value
                    )
                  }
                />
                <div className="input-group-prepend">
                  <span
                    className="input-group-text height32"
                    id="validationTooltipUsernamePrepend"
                  >
                    Min
                  </span>
                </div>
              </div>
            </div>
          </Row>
        </Row>
        {/* //Parking alert*/}
        <Row className="mb-0">
          <Row className="mb-3">
            <div className="col-md-12 d-flex align-items-center">
              <div className="col-auto form-check mb-0">
                <Input
                  type="checkbox"
                  className="form-check-Input"
                  id="horizontal-customCheck-auto11"
                  value={deviceAlertSettingsInputs?.parkingAlertMin}
                  onClick={e =>
                    updateDeviceAlertSettingsInputs(
                      "parkingAlertMin",
                      e.target.checked
                    )
                  }
                  checked={parseInt(deviceAlertSettingsInputs?.parking) > 0}
                />
                <label className="form-check-label" htmlFor="parkingAlertMin">
                  Parking Alert
                </label>
              </div>
              <div className="col-auto input-group w-150x ms-3">
                <input
                  className="form-control height32"
                  type="number"
                  defaultValue="0"
                  min="0"
                  value={deviceAlertSettingsInputs?.parking}
                  onChange={e =>
                    updateDeviceAlertSettingsInputs("parking", e.target.value)
                  }
                />
                <div className="input-group-prepend">
                  <span
                    className="input-group-text height32"
                    id="validationTooltipUsernamePrepend"
                  >
                    Min
                  </span>
                </div>
              </div>
            </div>
          </Row>
        </Row>
        * Geo-fence Not completed
        {/* //Geo-fence*/}
        <Row className="mb-2 mt-3">
          <div className="col-md-12 d-flex align-items-center">
            <h4> Geo-fence alert: </h4>
          </div>
        </Row>
        <Table className="table mb-0">
          <thead>
            <tr>
              <th> Geo-fence </th>
              <th>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  value=""
                  id="in"
                />
                <label className="form-check-label" htmlFor="in">
                  In
                </label>
              </th>
              <th>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  value=""
                  id="out"
                />
                <label className="form-check-label" htmlFor="out">
                  Out
                </label>
              </th>
              <th>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  value=""
                  id="speedalert"
                />
                <label className="form-check-label" htmlFor="speedalert">
                  Speeding alert
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Test1</td>
              <td>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  value=""
                  id="in1"
                />
              </td>
              <td>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  value=""
                  id="in2"
                />
              </td>
              <td>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  value=""
                  id="in3"
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Row className="mb-1 mt-3">
          <div className="col-md-12 d-flex justify-content-end">
        
            <LoadingButton
              color="primary"
              className="btn btn-primary btn-right"
              loading={isUpdateDeviceLoading}
              disabled={isUpdateDeviceLoading}
              block={false}
              onClick={() => {
                props
                  .putDeviceByDeviceIdAction(generatePayloadForUpdate())
                  .then(() => {
                    tog_model()
                  })
              }}
            >
              Submit
            </LoadingButton>
          </div>
        </Row>
      </div>
    </div>
  )
}


DeviceAlertSettings.propTypes = {
  deviceAlertSettingsInputs: PropTypes.obj,
  selectedDevice: PropTypes.obj,
  updateDeviceAlertSettingsInputs: PropTypes.func,
  putDeviceByDeviceIdAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}


const mapDispatchToProps = dispatch => ({
  putDeviceByDeviceIdAction: bindActionToPromise(dispatch, putAlarmSettings),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceAlertSettings)

