import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  Tooltip,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Spinner,
  Badge,
  UncontrolledPopover,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const TreeRight = () => {

  const [popoverright, setpopoverright] = useState(false);

  return (
    <>
     <span
                      id="Popoverright"
                      onClick={() => {
                        setpopoverright(!popoverright);
                      }}
                      color="secondary"
                    >
                      <i className="bx bx-dots-vertical-rounded"></i>
                    </span>
                    <Popover
                      placement="right"
                      isOpen={popoverright}
                      target="Popoverright"
                     trigger="hover"
                      toggle={() => {
                        setpopoverright(!popoverright);
                      }}
                    >
                      
                      <PopoverBody>
                      

                <DropdownItem
                  href="#"
                  
                >
                  Sell
                </DropdownItem>
                <DropdownItem href="#">Add Device</DropdownItem>
                <hr className="my-1" />
                <DropdownItem href="#">Add User</DropdownItem>
                <DropdownItem href="#">Information</DropdownItem>
                <DropdownItem href="#">Move Account</DropdownItem>
                <DropdownItem href="#">Send Message</DropdownItem>
                <hr className="my-1" />
                <DropdownItem href="#">Modify password</DropdownItem>
                <hr className="my-1" />
                <DropdownItem href="#">Refresh</DropdownItem>
            
                      </PopoverBody>
                    </Popover>
    </>
  );
};

export default TreeRight;
