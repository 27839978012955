import {
  GET_PREFERENCES,
  GET_PREFERENCES_SUCCESS,
  GET_PREFERENCES_ERROR,
  PUT_PREFERENCES,
  PUT_PREFERENCES_SUCCESS,
  PUT_PREFERENCES_ERROR,
} from "./actionTypes"

export const getPreferences = payload => ({
  type: GET_PREFERENCES,
  payload,
})

export const getPreferencesSuccess = messages => ({
  type: GET_PREFERENCES_SUCCESS,
  payload: messages,
})

export const getPreferencesFail = error => ({
  type: GET_PREFERENCES_ERROR,
  payload: error,
})

export const putPreferences = payload => ({
  type: PUT_PREFERENCES,
  payload,
})

export const putPreferencesSuccess = preferences => ({
  type: PUT_PREFERENCES_SUCCESS,
  payload: preferences,
})

export const putPreferencesFail = error => ({
  type: PUT_PREFERENCES_ERROR,
  payload: error,
})
