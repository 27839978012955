/* eslint-disable */

import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
// Login Redux States
import {
  GET_GEO_FENCE_DETAILS
} from "./actionTypes"
import {
  getFenceDetailsSuccess,
  getFenceDetailsFail
} from "./actions"

import {
  getGeoFenceDataDetails,
 
} from "../../helpers/fakebackend_helper"


function* fetchGeoFenceDetails({ memberId }) {
  try {
    const response = yield call(getGeoFenceDataDetails, memberId)
    yield put(getFenceDetailsSuccess({response,memberId}))
  } catch (error) {
    yield put(getFenceDetailsFail(error))
  }
}

function* geoFenceSaga() {
  yield takeEvery(GET_GEO_FENCE_DETAILS, fetchGeoFenceDetails)
}

export default geoFenceSaga
