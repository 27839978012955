/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

import { authProtectedRoutes, publicRoutes } from "./index"

export const isUnAuthorized = route => {
  return !authProtectedRoutes.includes(route)
}

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  route,
  ...rest
}) => (
  <>
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
        if (isAuthProtected && isUnAuthorized(route)) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  </>
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  route: PropTypes.object,
}

export default Authmiddleware
