import {
  GET_MOVINGOVERVIEW_DETAILS,
  GET_MOVINGOVERVIEW_DETAILS_SUCCESS,
  GET_MOVINGOVERVIEW_DETAILS_ERROR,
  GET_MILEAGEREPORT,
  GET_MILEAGEREPORT_SUCCESS,
  GET_MILEAGEREPORT_ERROR,
  GET_SPEEDING_DETAILS,
  GET_SPEEDING_DETAILS_SUCCESS,
  GET_SPEEDING_DETAILS_ERROR,
  GET_PARKING_DETAILS,
  GET_PARKING_DETAILS_SUCCESS,
  GET_PARKING_DETAILS_ERROR,
  GET_TRIP_REPORT,
  GET_TRIP_REPORT_SUCCESS,
  GET_TRIP_REPORT_ERROR,
  GET_SPEEDING_TRIP_REPORT,
  GET_SPEEDING_TRIP_REPORT_SUCCESS,
  GET_SPEEDING_TRIP_REPORT_ERROR,
  GET_ENGINE_OVERVIEW,
  GET_ENGINE_OVERVIEW_SUCCESS,
  GET_ENGINE_OVERVIEW_ERROR,
  GET_ENGINE_REPORT,
  GET_ENGINE_REPORT_SUCCESS,
  GET_ENGINE_REPORT_ERROR,
  GET_ENGINE_IDLE_REPORT,
  GET_ENGINE_IDLE_REPORT_SUCCESS,
  GET_ENGINE_IDLE_REPORT_ERROR,
  GET_ALERT_OVERVIEW,
  GET_ALERT_OVERVIEW_SUCCESS,
  GET_ALERT_OVERVIEW_ERROR,
  GET_ALERT_REPORT,
  GET_ALERT_REPORT_SUCCESS,
  GET_ALERT_REPORT_ERROR,
  GET_ALERT_DETAILS,
  GET_ALERT_DETAILS_SUCCESS,
  GET_ALERT_DETAILS_ERROR,
  GET_GEO_FENCE_ALERT_REPORT,
  GET_GEO_FENCE_ALERT_REPORT_SUCCESS,
  GET_GEO_FENCE_ALERT_REPORT_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  movingOverviewDetails: {},
  mileageReport: {},
  speedingDetails: {},
  parkingDetails: {},
  tripReport: {},
  speedingTripReport: {},
  engineOverview: {},
  engineReport: {},
  engineIdleReport: {},
  alertOverview: {},
  alertReport: {},
  alertDetails: {},
  alertGeoFenceReport: {},
}

const reports = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOVINGOVERVIEW_DETAILS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_MOVINGOVERVIEW_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        movingOverviewDetails: action.payload,
      }
      break
    case GET_MOVINGOVERVIEW_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_MILEAGEREPORT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_MILEAGEREPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        mileageReport: action.payload,
      }
      break
    case GET_MILEAGEREPORT_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_SPEEDING_DETAILS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SPEEDING_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        speedingDetails: action.payload,
      }
      break
    case GET_SPEEDING_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_PARKING_DETAILS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PARKING_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        parkingDetails: action.payload,
      }
      break
    case GET_PARKING_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_TRIP_REPORT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_TRIP_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        tripReport: action.payload,
      }
      break
    case GET_TRIP_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_SPEEDING_TRIP_REPORT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SPEEDING_TRIP_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        speedingTripReport: action.payload,
      }
      break
    case GET_SPEEDING_TRIP_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_ENGINE_OVERVIEW:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ENGINE_OVERVIEW_SUCCESS:
      state = {
        ...state,
        loading: false,
        engineOverview: action.payload,
      }
      break
    case GET_ENGINE_OVERVIEW_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_ENGINE_REPORT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ENGINE_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        engineReport: action.payload,
      }
      break
    case GET_ENGINE_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_ENGINE_IDLE_REPORT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ENGINE_IDLE_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        engineIdleReport: action.payload,
      }
      break
    case GET_ENGINE_IDLE_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_ALERT_OVERVIEW:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ALERT_OVERVIEW_SUCCESS:
      state = {
        ...state,
        loading: false,
        alertOverview: action.payload,
      }
      break
    case GET_ALERT_OVERVIEW_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_ALERT_REPORT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ALERT_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        alertReport: action.payload,
      }
      break
    case GET_ALERT_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_ALERT_DETAILS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ALERT_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        alertDetails: action.payload,
      }
      break
    case GET_ALERT_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_GEO_FENCE_ALERT_REPORT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_GEO_FENCE_ALERT_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        alertGeoFenceReport: action.payload,
      }
      break
    case GET_GEO_FENCE_ALERT_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default reports
