//LOGIN
export const LOGIN_USER = "/Member"
export const POST_AUTH_LOGIN = "/Member/Login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_PASSWORD_FORGET = "/Member/ForgotPassword"
export const PUT_PASSWORD_CHANGE = "/Member/ChangePassword"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

// USER
export const POST_ADD_USER = "/Member/Admin"
export const GET_USER_DETAIL = "/Member/Admin"
export const Image_Upload = "/Member/Common/UploadFile"

// DEVICE
export const POST_ADD_DEVICE = "/Device"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"
export const DEVICES_BY_MEMBERID = "/Device/User"

export const DEVICES_INFO_BY_MEMBERID = "/Device/Dashboard/Info"
export const DEVICES_INFO_BY_QUERY = "/Device/search/searchValue"
export const USERS_INFO_BY_QUERY = "/Member/Member/search"

export const CHECK_IMEI = "/Device/checkImei"
export const BULKSALE = "/Device/sellDevice"
export const RECHARGE = "/Device/recharge"

export const MODIFY_EXPIRY_DATE = "/Device/Modify/ExpiryDate"

export const PLATEFORM_MODIFY_EXPIRY_DATE = "/Device/Modify/PlatformExpiryDate"

export const ADD_TO_VIRTUAL_DEVICE = "/Device/addToVirtual"

//Tree Menu
export const GET_USER_BY_PARENT_ID = "/Member/MenuTree/SubUser/"

export const UPDATE_USER_PROFILE = "/Member"

export const CHANGE_PASS_OF_MEMBER = "/Member/Admin/ChangePassword"

// Add Card
export const GET_ALL_CARDS = "/Card"
export const GET_ALL_DISTRIBUTOR_URL = "/Member/getDistributors"

export const ADD_CARD_URL = "/Card"
export const GET_ALL_CARD_COUNT = "/Card/Own/Count"
export const ADD_TRANSFER_CARD_URL = "/Card/Purchase"

//Message

export const MESSAGE = "/Message"
export const GET_MONITOR_DATA = "/Device/User/"
export const GET_CARD_BALANCE_BY_ID = "/Card/Own/CardBalance?memberId="
export const GET_TRACK_INITIAL_DATA = "/Device/"
export const PREFERENCES = "/Member/Settings"
export const DEVICE_DETAILED = "/Device/Detailed"
export const DEVICE_HISTORY = "/Device/History/"
export const DEVICE = "/Device"

//Group

export const DEVICE_GROUP = "/Group"

export const Reports = "/Reports"


//Popup Operations in business page 
export const CHECK_GET_IMEI_DETAILS = "/Device/getImeDetails"
export const DEVICE_SALE = "/Device/sellDevice"
export const MOVE_ACCOUNT = "/Member/moveAccount"

//geoFence
export const GET_GEO_FENCE = "/GeoFence" 