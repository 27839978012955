/* eslint-disable */
import React, { useState } from "react"
import { Row, Col, Button, Modal } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

const CustomerService = () => {
  const [ViewInfo, setViewInfo] = useState(false)
  const [GridWrapper, setGridWrapper] = useState(true)
  const ViewInfoBackBtn = () => {
    setGridWrapper(true)
    setViewInfo(false)
  }
  const columns = [
    {
      dataField: "name",
      text: "",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "50px" }
      },
      formatter: () => (
        <>
          <div className="color_cir"></div>
        </>
      ),
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      classes: "txt_link",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setGridWrapper(false)
          setViewInfo(!ViewInfo)
        },
      },
    },
    {
      dataField: "startdate",
      text: "Date",
      headerStyle: (colum, colIndex) => {
        return { width: "80px" }
      },
      sort: true,
    },
    {
      dataField: "delete",
      text: "Delete",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "80px" }
      },

      formatter: () => (
        <>
          <div className="text-center">
            {" "}
            <i className="bx bx-trash-alt fs-5"></i>{" "}
          </div>
        </>
      ),
    },
  ]

  // Table Data
  const productData = [
    {
      description: "description",
      startdate: "2008/11/28",
    },

    {
      description: "description",
      startdate: "2008/11/28",
    },
    {
      description: "description",
      startdate: "2008/11/28",
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="ps-3 pe-3 pb-3">
        <div className="grid_wrapper">
          {GridWrapper ? (
            <div>
              <Row className="mb-3 justify-content-between">
                <Col
                  md="12"
                  className="d-inline-flex align-items-center btnsmall"
                >
                  <Button color="secondary" className="me-3" outline>
                    Delete
                  </Button>

                  <Button color="secondary" className="me-3" outline>
                    Read All
                  </Button>
                  <Button color="secondary" className="me-3" outline>
                    Delete All
                  </Button>
                </Col>
              </Row>

              <Row>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={productData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={productData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Row>
            </div>
          ) : null}
        </div>

        {ViewInfo ? (
          <div>
            <span className="text-start" onClick={ViewInfoBackBtn}>
              <i className="bx bx-arrow-back me-2 txt_link"></i>
              <span className="txt_link">Back</span>
            </span>

            <div className="bg_gray_2 mb-2 mt-2">
              <p>
                From: <span>ACCOUNT</span>
              </p>
              <p className="mb-0">
                Date: <span>2022-05-09 07:41:06</span>
              </p>
            </div>
            <div>
              <p>
                On the other hand, we denounce with righteous indignation and
                dislike men who are so beguiled and demoralized by the charms of
                pleasure of the moment, so blinded by desire, that they cannot
                foresee the pain and trouble that are bound to ensue; and equal
                blame belongs to those who fail in their duty
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default CustomerService
