export const GET_MOVINGOVERVIEW_DETAILS = "GET_MOVINGOVERVIEW_DETAILS"
export const GET_MOVINGOVERVIEW_DETAILS_SUCCESS =
  "GET_MOVINGOVERVIEW_DETAILS_SUCCESS"
export const GET_MOVINGOVERVIEW_DETAILS_ERROR =
  "GET_MOVINGOVERVIEW_DETAILS_ERROR"

export const GET_MILEAGEREPORT = "GET_MILEAGEREPORT"
export const GET_MILEAGEREPORT_SUCCESS = "GET_MILEAGEREPORT_SUCCESS"
export const GET_MILEAGEREPORT_ERROR = "GET_MILEAGEREPORT_ERROR"

export const GET_SPEEDING_DETAILS = "GET_SPEEDING_DETAILS"
export const GET_SPEEDING_DETAILS_SUCCESS = "GET_SPEEDING_DETAILS_SUCCESS"
export const GET_SPEEDING_DETAILS_ERROR = "GET_SPEEDING_DETAILS_ERROR"

export const GET_PARKING_DETAILS = "GET_PARKING_DETAILS"
export const GET_PARKING_DETAILS_SUCCESS = "GET_PARKING_DETAILS_SUCCESS"
export const GET_PARKING_DETAILS_ERROR = "GET_PARKING_DETAILS_ERROR"

export const GET_TRIP_REPORT = "GET_TRIP_REPORT"
export const GET_TRIP_REPORT_SUCCESS = "GET_TRIP_REPORT_SUCCESS"
export const GET_TRIP_REPORT_ERROR = "GET_TRIP_REPORT_ERROR"

export const GET_SPEEDING_TRIP_REPORT = "GET_SPEEDING_TRIP_REPORT"
export const GET_SPEEDING_TRIP_REPORT_SUCCESS =
  "GET_SPEEDING_TRIP_REPORT_SUCCESS"
export const GET_SPEEDING_TRIP_REPORT_ERROR = "GET_SPEEDING_TRIP_REPORT_ERROR"

export const GET_ENGINE_OVERVIEW = "GET_ENGINE_OVERVIEW"
export const GET_ENGINE_OVERVIEW_SUCCESS = "GET_ENGINE_OVERVIEW_SUCCESS"
export const GET_ENGINE_OVERVIEW_ERROR = "GET_ENGINE_OVERVIEW_ERROR"

export const GET_ENGINE_REPORT = "GET_ENGINE_REPORT"
export const GET_ENGINE_REPORT_SUCCESS = "GET_ENGINE_REPORT_SUCCESS"
export const GET_ENGINE_REPORT_ERROR = "GET_ENGINE_REPORT_ERROR"

export const GET_ENGINE_IDLE_REPORT = "GET_ENGINE_IDLE_REPORT"
export const GET_ENGINE_IDLE_REPORT_SUCCESS = "GET_ENGINE_IDLE_REPORT_SUCCESS"
export const GET_ENGINE_IDLE_REPORT_ERROR = "GET_ENGINE_IDLE_REPORT_ERROR"

export const GET_ALERT_OVERVIEW = "GET_ALERT_OVERVIEW"
export const GET_ALERT_OVERVIEW_SUCCESS = "GET_ALERT_OVERVIEW_SUCCESS"
export const GET_ALERT_OVERVIEW_ERROR = "GET_ALERT_OVERVIEW_ERROR"

export const GET_ALERT_REPORT = "GET_ALERT_REPORT"
export const GET_ALERT_REPORT_SUCCESS = "GET_ALERT_REPORT_SUCCESS"
export const GET_ALERT_REPORT_ERROR = "GET_ALERT_REPORT_ERROR"

export const GET_ALERT_DETAILS = "GET_ALERT_DETAILS"
export const GET_ALERT_DETAILS_SUCCESS = "GET_ALERT_DETAILS_SUCCESS"
export const GET_ALERT_DETAILS_ERROR = "GET_ALERT_DETAILS_ERROR"

export const GET_GEO_FENCE_ALERT_REPORT = "GET_GEO_FENCE_ALERT_REPORT"
export const GET_GEO_FENCE_ALERT_REPORT_SUCCESS =
  "GET_GEO_FENCE_ALERT_REPORT_SUCCESS"
export const GET_GEO_FENCE_ALERT_REPORT_ERROR =
  "GET_GEO_FENCE_ALERT_REPORT_ERROR"
