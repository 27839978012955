import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MapContainer, TileLayer } from "react-leaflet"
import ReactLeafletGoogleLayer from "react-leaflet-google-layer"
const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`

const LeafMap = ({ children, ...props }) => (
  <Wrapper>
    <MapContainer {...props}>
      {/* <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      /> */}
      <ReactLeafletGoogleLayer apiKey="AIzaSyCVPM_AKcsmM6GvZmx5xpizXasMuho4c24" />
      {children}
    </MapContainer>
  </Wrapper>
)

LeafMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

LeafMap.defaultProps = {
  children: null,
}

export default LeafMap
