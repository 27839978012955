/* eslint-disable */
import usicons1 from "../../../src/assets/images/user.svg"

import TreeRight from "../../pages/Business/TreeRight"

import React from "react"
import {
  GET_TREE_MENU_DATAILS,
  GET_TREE_MENU_DATAILS_FAIL,
  GET_TREE_MENU_DATAILS_SUCCESS,
  GET_TREE_MENU_INITIAL,
  GET_TREE_MENU_INITIAL_FAIL,
  GET_TREE_MENU_INITIAL_SUCCESS,
  GET_SUB_USERS,
  GET_SUB_USERS_FAIL,
  GET_SUB_USERS_SUCCESS,
  DELETE_USERS,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_ERROR,
} from "./actionTypes"
import { CarryOutOutlined, FormOutlined } from "@ant-design/icons"
let dataInit = []
if (localStorage.getItem("authUser")) {
  const userDetails = JSON.parse(localStorage.getItem("authUser"))
  console.log(userDetails)
  dataInit = [
    {
      id: userDetails.memberId,
      pId: 0,
      value: userDetails.memberId,
      title: (
        <>
          <div>{userDetails.member.name}</div>
          <div className="ripos">
            <TreeRight />
          </div>
        </>
      ),
      isLeaf: false,
      icon: <img src={usicons1} />,
      telephone: userDetails.member.telephone,
      role: userDetails?.roles[0],
      contactNumber: userDetails.member.contactNumber,
      email: userDetails.member.email,
      name: userDetails.member.name,
      profileImage: userDetails.member.profileImage,
    },
  ]
} else {
  dataInit = []
}

const INIT_STATE = {
  treeData: dataInit,
  error: {},
  selectedValue: undefined,
  isLoading: false,
  subUser: {},
  isLoadingDelete: false,
}

const getTreeMenuDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TREE_MENU_DATAILS:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TREE_MENU_DATAILS_SUCCESS:
      return {
        ...state,
        treeData: state.treeData.concat(action.payload),
        isLoading: false,
      }

    case GET_TREE_MENU_DATAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case GET_TREE_MENU_INITIAL:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TREE_MENU_INITIAL_SUCCESS:
      return {
        ...state,
        treeData: action.payload,
        isLoading: false,
      }
    case GET_TREE_MENU_INITIAL_FAIL:
      return {
        ...state,
        treeData: action.payload,
        isLoading: false,
      }

    case GET_SUB_USERS:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SUB_USERS_FAIL:
      return {
        ...state,
        isLoading: false,
      }

    case GET_SUB_USERS_SUCCESS:
      return {
        ...state,
        subUser: action.payload,
        isLoading: false,
      }

    case DELETE_USERS:
      return {
        ...state,
        isLoadingDelete: true,
      }

    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
      }

    case DELETE_USERS_ERROR:
      return {
        ...state,
        isLoadingDelete: false,
      }

    default:
      return state
  }
}

export default getTreeMenuDetails
