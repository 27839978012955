/* eslint-disable */
import React, { useState } from "react";
import {
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Modal,
  } from "reactstrap";
  import { Link } from "react-router-dom";
  import classnames from "classnames";

  import Membership from "./Membership";
  import MoveDevice from "./MoveDevice"
  import Sale from "./Sale"
  import RechargePop from "./RechargePop"
  import SendCommandInfo from "../Monitor/MapPopupInfo/SendCommandInfo"
  import IoSetting from "./IoSettings"
  import CreateAccount from "./CreateAccount"
  import ModifyPassword from "./ModifyPassword"
  import PopupDeviceList from "./PopupDeviceList"
  import DeviceDetails from "./DeviceDetails"
  

const SearchDeviceInfo = () => {
    const [verticalActiveTab, setverticalActiveTab] = useState("12");
    const toggleVertical = tab => {
      if (verticalActiveTab !== tab) {
        setverticalActiveTab(tab);
      }
    };


    const [modal_center2, setmodal_center2] = useState(false)
    function tog_center2() {
      setmodal_center2(!modal_center2)
      removeBodyCss()
    }


  return (
    <div className="px-0">
      <Row className="g-0">
        <Col md="3">
          <div className="bgleft">
            <Nav pills className="flex-column">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "11",
                  })}
                  onClick={() => {
                    toggleVertical("11")
                  }}
                >
                  Membership
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "12",
                  })}
                  onClick={() => {
                    toggleVertical("12")
                  }}
                >
                  Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "13",
                  })}
                  onClick={() => {
                    toggleVertical("13")
                  }}
                >
                  Sell
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "14",
                  })}
                  onClick={() => {
                    toggleVertical("14")
                  }}
                >
                  Move
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "15",
                  })}
                  onClick={() => {
                    toggleVertical("15")
                  }}
                >
                  Recharge
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "16",
                  })}
                  onClick={() => {
                    toggleVertical("16")
                  }}
                >
                  Command
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "17",
                  })}
                  onClick={() => {
                    toggleVertical("17")
                  }}
                >
                  I/O Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "18",
                  })}
                  onClick={() => {
                    toggleVertical("18")
                  }}
                >
                  Create account
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "19",
                  })}
                  onClick={() => {
                    toggleVertical("19")
                  }}
                >
                  Modify password
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Col>
        <Col md="9">
          <TabContent
            activeTab={verticalActiveTab}
            className="text-muted mt-4 mt-md-0"
          >
            <TabPane tabId="11">
              <div className="ps-3">
                <Membership />
              </div>
            </TabPane>
            <TabPane tabId="12">
              <div className="ps-3 deviceres">
                <DeviceDetails />
              </div>
            </TabPane>
            <TabPane tabId="13">
              <div className="ps-3">
                <Sale userInfo={{}} />
              </div>
            </TabPane>
            <TabPane tabId="14">
              <div className="ps-3">
                <MoveDevice />
              </div>
            </TabPane>
            <TabPane tabId="15">
              <div className="ps-3">
                <RechargePop />
              </div>
            </TabPane>
            <TabPane tabId="16">
              <div className="ps-3">
                <SendCommandInfo />
              </div>
            </TabPane>
            <TabPane tabId="17">
              <div className="ps-3">
                <IoSetting />
              </div>
            </TabPane>
            <TabPane tabId="18">
              <div className="ps-3">
                <CreateAccount />
              </div>
            </TabPane>
            <TabPane tabId="19">
              <div className="ps-3">
                <ModifyPassword />
              </div>
            </TabPane>
          </TabContent>
        </Col>
      </Row>

      <Modal
        size="lg"
        isOpen={modal_center2}
        toggle={() => {
          tog_center2()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Details</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <PopupDeviceList />
        </div>
      </Modal>
    </div>
  )
}

export default SearchDeviceInfo