import {
  ADD_DEVICE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_ERROR,
  SEARCH_DEVICE_DEFAULT,
  SEARCH_DEVICE_SUCCESS,
  SEARCH_DEVICE_ERROR,
  CHECK_IMEI_DEFAULT,
  CHECK_IMEI_SUCCESS,
  CHECK_IMEI_ERROR,
  BULKSALE_DEFAULT,
  RECHARGE_DEFAULT,
  RECHARGE_SUCCESS,
  RECHARGE_ERROR,
  BULKSALE_SUCCESS,
  BULKSALE_ERROR,
  MODIFY_EXPIRY_DATE,
  MODIFY_EXPIRY_DATE_SUCCESS,
  MODIFY_EXPIRY_DATE_ERROR,
  PLATEFORM_MODIFY_EXPIRY_DATE,
  PLATEFORM_MODIFY_EXPIRY_DATE_SUCCESS,
  PLATEFORM_MODIFY_EXPIRY_DATE_ERROR,
  ADD_TO_VIRTUAL_DEVICE,
  ADD_TO_VIRTUAL_DEVICE_SUCCESS,
  ADD_TO_VIRTUAL_DEVICE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  deviceData: [],
  searchDeviceData: [],
  imeiResData: [],
  data: {},
  isLoadingCheckImei: false,
  isLoadingModifyExpDate: false,
  isLoadingBulkSale: false,
  isLoadingRecharge: false,
  isLoadingPlateformModifyExpDate: false,
  isLoadingAddToVirtualDevice: false,
}

const addDeviceReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case ADD_DEVICE:
      return {
        ...state,
        loading: true,
      }
    case ADD_DEVICE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        deviceData: [...state.deviceData, action.payload],
      }

    case ADD_DEVICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case SEARCH_DEVICE_DEFAULT:
      return {
        ...state,
        isLoading: true,
      }
    case SEARCH_DEVICE_SUCCESS:
      return {
        ...state,
        searchDeviceData: action.payload,
        isLoading: false,
      }

    case SEARCH_DEVICE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case CHECK_IMEI_DEFAULT:
      return {
        ...state,
        isLoadingCheckImei: true,
      }
    case CHECK_IMEI_SUCCESS:
      return {
        ...state,
        imeiResData: action.payload,
        isLoadingCheckImei: false,
      }
    case CHECK_IMEI_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoadingCheckImei: false,
      }
    case BULKSALE_DEFAULT:
      return {
        ...state,
        isLoadingBulkSale: true,
      }
    case BULKSALE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoadingBulkSale: false,
      }
    case BULKSALE_ERROR:
      return {
        ...state,
        isLoadingBulkSale: false,
      }
    case RECHARGE_DEFAULT:
      return {
        ...state,
        isLoadingRecharge: true,
      }
    case RECHARGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoadingRecharge: false,
      }
    case RECHARGE_ERROR:
      return {
        ...state,
        isLoadingRecharge: false,
      }

    case MODIFY_EXPIRY_DATE:
      return {
        ...state,
        isLoadingModifyExpDate: true,
      }
    case MODIFY_EXPIRY_DATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoadingModifyExpDate: false,
      }
    case MODIFY_EXPIRY_DATE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoadingModifyExpDate: false,
      }

    case PLATEFORM_MODIFY_EXPIRY_DATE:
      return {
        ...state,
        isLoadingPlateformModifyExpDate: true,
      }
    case PLATEFORM_MODIFY_EXPIRY_DATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoadingPlateformModifyExpDate: false,
      }
    case PLATEFORM_MODIFY_EXPIRY_DATE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoadingPlateformModifyExpDate: false,
      }

    case ADD_TO_VIRTUAL_DEVICE:
      return {
        ...state,
        isLoadingAddToVirtualDevice: true,
      }
    case ADD_TO_VIRTUAL_DEVICE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoadingAddToVirtualDevice: false,
      }
    case ADD_TO_VIRTUAL_DEVICE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoadingAddToVirtualDevice: false,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default addDeviceReducer
