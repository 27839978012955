import {
  GET_PLAYBACK_INITIAL,
  GET_PLAYBACK_DETAILS_FAIL,
  GET_PLAYBACK_DETAILS_SUCCESS,
} from "./actionTypes"

export const getPlayBackDetails = memberId => ({
  type: GET_PLAYBACK_INITIAL,
  memberId,
})

export const getPlayBackDetailsSuccess = memberDetails => ({
  type: GET_PLAYBACK_DETAILS_SUCCESS,
  payload: memberDetails,
})
export const getPlayBackDetailsFail = error => ({
  type: GET_PLAYBACK_DETAILS_FAIL,
  payload: error,
})
