/* eslint-disable */
import { call, put, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import { GET_PLAYBACK_INITIAL } from "./actionTypes"

import { getPlayBackDetailsSuccess, getPlayBackDetailsFail } from "./actions"

//Include Both Helper File with needed methods
import { getPlayBackData } from "../../../helpers/fakebackend_helper"

function* fetchPlayBackDetails({ memberId }) {
  try {
    const response = yield call(getPlayBackData, memberId)
    yield put(getPlayBackDetailsSuccess(response))
  } catch (error) {
    yield put(getPlayBackDetailsFail(error))
  }
}


function* playBackSaga() {
  yield takeLatest(GET_PLAYBACK_INITIAL, fetchPlayBackDetails)
}

export default playBackSaga
