/* eslint-disable */
import { call, put, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import { GET_PLAYBACK_HISTORY_INITIAL } from "./actionTypes"

import {
  getPlayBackHistoryDetailsSuccess,
  getPlayBackHistoryDetailsFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getPlayBackHistory } from "../../../helpers/fakebackend_helper"

function* fetchPlayBackHistoryDetails({data}) {
  let response ;
  try {
    console.log(data,"====================saga=========================")
    if(data.playBackHistory){
     response = data.playBackHistory
    }else{
     response = yield call(getPlayBackHistory, data)
    }
    yield put(getPlayBackHistoryDetailsSuccess(response))
  } catch (error) {
    yield put(getPlayBackHistoryDetailsFail(error))
  }
}


function* playBackHistorySaga() {
  yield takeLatest(GET_PLAYBACK_HISTORY_INITIAL, fetchPlayBackHistoryDetails)
}

export default playBackHistorySaga
