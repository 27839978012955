/* eslint-disable */

import React, { Component, useState, useEffect, memo } from "react"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap"
import classnames from "classnames"
import PlayBackSpeedChart from "./PlayBackSpeedChart"
import PlayBackDetailsTab from "./PlayBackDetailsTab"
import PlayBackTripTab from "./PlayBackTripTab"
import { Slider, Box, Tooltip, Typography } from "@mui/material"
import { styled } from '@mui/material/styles';
import moment from "moment"




const PlayBackTabs = ({
  playBarData
 
}) => {
  const {
    playBackHistory,
    cursor,
    playBackHistoryLoading,
    filterData,
    stopAndPlay,
    playPauseStop,
    stopAndPlayHandler,
    resetCurser,
  } = playBarData

  const [PlayBackTabsShow, setPlayBackTabsShow] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [updatedValue, setUpdatedValue] = React.useState(cursor)
 
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    setUpdatedValue(cursor)
  }, [cursor])


  //Managing Bar logic here 
  function valueLabelFormat(value) {
    return `${playBackHistory[cursor]?.createdAt ? moment(playBackHistory[cursor].createdAt).format("YYYY-MM-DD HH:mm:ss"):moment().format("YYYY-MM-DD HH:mm:ss") }`
    console.log(playBackHistory[cursor])
  }

  function calculateValue(value) {
    return 2 ** value
  }

  const PlayBackTabsOpenBtn = id => {
    setPlayBackTabsShow(true)
    toggleTab(id)
  }

  const handleChange = (event, newValue) => {
    console.log(newValue, "=========newValue=============")
    resetCurser(newValue)
    setUpdatedValue(newValue)
  }

  const PrettoSlider = styled(Slider)({
    color: "#ff9934",
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      height: 32,
      backgroundColor: "#666",
      //   transformOrigin: 'bottom left'
    },
  })

  // width: "5px",
  //   height: "15px",
  //     background: "#ffffff",
  //       display: "block",
  //         top: "-4px",
  //           position: "absolute",
  //             right: 0,
  //               borderRadius: "5px",
console.log(updatedValue, "===================updatedValue")
  return (
    <div>
      <div className="play_back_tab_nav">
        <div className="d-flex flex-column">
          <span className="tabs_pb" onClick={() => PlayBackTabsOpenBtn("1")}>
            <Tooltip title="Speed chart" placement="right" arrow>
              <i className="bx bxs-tachometer"></i>
            </Tooltip>
          </span>
          <span className="tabs_pb" onClick={() => PlayBackTabsOpenBtn("2")}>
            <Tooltip title="Details" placement="right" arrow>
              <i className="bx bx-detail"></i>
            </Tooltip>
          </span>
          <span className="tabs_pb" onClick={() => PlayBackTabsOpenBtn("3")}>
            <Tooltip title="Trip" placement="right" arrow>
              <i className=" bx bx-map"></i>
            </Tooltip>
          </span>
        </div>
      </div>
      <div className="play_back_tab_info">
        <div className="pb_drag_player text-center position-relative">
          <div className="pb_player d-flex align-items-center mb-3">
            {!stopAndPlay && playPauseStop && (
              <div className="flx_0 play_icon">
                <i className="bx bx-play fs-3 text-white"></i>
              </div>
            )}
            {!stopAndPlay && !playPauseStop && (
              <div className="flx_0 play_icon">
                <i className="bx bx-play fs-3 text-white"></i>
              </div>
            )}
            {stopAndPlay && !playPauseStop && (
              <div
                className="flx_0 play_icon"
                onClick={() => {
                  stopAndPlayHandler(true)
                  
                }}
              >
                <i className="bx bx-play fs-3 text-white"></i>
              </div>
            )}

            {stopAndPlay && playPauseStop && (
              <div
                className="flx_0 pause_icon"
                onClick={() => {
                  stopAndPlayHandler(false)
                }}
              >
                <i className="bx bx-pause fs-3 text-white"></i>
              </div>
            )}

            <div className="flx_1 pe-3 ps-3">
              <div className="progressSlider">
                <Box sx={{ width: "100%" }}>
                  <PrettoSlider
                    value={updatedValue != 0 ? updatedValue : cursor}
                    getAriaLabel={valueLabelFormat}
                    min={0}
                    max={
                      playBackHistory?.length != 0 ? playBackHistory?.length : 0
                    }
                    scale={calculateValue}
                    getAriaValueText={valueLabelFormat}
                    valueLabelFormat={valueLabelFormat}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                    //defaultValue={updatedValue != 0 ? updatedValue : cursor}
                  />
                </Box>
                {/* <div className="slider-son" style={{ width: "100px" }}>


                    <span className="play_drag"></span>
                  </div> */}
              </div>
            </div>
            <div className="flx_0">
              <span className="text-white">
                {" "}
                <Typography id="non-linear-slider" gutterBottom>
                  {playBackHistory[cursor]?.createdAt
                    ? moment(playBackHistory[cursor]?.createdAt).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : moment().format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
              </span>
            </div>
          </div>
        </div>

        {PlayBackTabsShow ? (
          <div className="mt-3">
            <div className="crypto-buy-sell-nav bg-white w-100">
              <Nav
                tabs
                className="nav-tabs-custom mb-3 position-relative"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Speed chart
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggleTab("3")
                    }}
                  >
                    Trip
                  </NavLink>
                </NavItem>

                <span
                  onClick={PlayBackTabsClose}
                  className="play_back_tabs_close"
                >
                  X
                </span>
              </Nav>

              <TabContent
                activeTab={activeTab}
                className="crypto-buy-sell-nav-content border-0"
              >
                <TabPane tabId="1">
                  <div className="ps-3 pb-3 pe-3">
                    <PlayBackSpeedChart />
                  </div>
                </TabPane>

                <TabPane tabId="2">
                  <div className="ps-3 pb-3 pe-3">
                    <PlayBackDetailsTab />
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="ps-3 pb-3 pe-3">
                    <PlayBackTripTab />
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default memo(PlayBackTabs)
