/* eslint-disable */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Modal, Label, Spinner, Input } from "reactstrap"
import MetaTags from "react-meta-tags"

import { Link } from "react-router-dom"

import LoadingButton from "../../components/Common/Button"

import moment from "moment"

import classnames from "classnames"

import { connect } from "react-redux"

import { useSelector, useDispatch } from "react-redux"

import { bindActionToPromise } from "../../utils/Common"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import { getSentMessages, deleteMessage } from "../../store/actions"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

const SentMessageTab = props => {
  const [ViewInfo, setViewInfo] = useState(false)
  const [viewReceivedList, setViewReceivedList] = useState(false)
  const [GridWrapper, setGridWrapper] = useState(true)
  const ViewInfoBackBtn = () => {
    setGridWrapper(true)
    setViewInfo(false)
  }
  const ViewReceivedListBackBtn = () => {
    setGridWrapper(true)
    setViewReceivedList(false)
  }

  const [selectedRows, setSelectedRows] = useState([])

  const [paginationOptions, setPaginationOptions] = useState({
    sizePerPage: 5,
    totalSize: 5,
    custom: true,
    page: 1,
  })

  const [deleteIDs, setDeleteIDs] = useState([])

  const [modal_delete, setmodal_delete] = useState(false)
  function tog_delete(ids = []) {
    setDeleteIDs(ids)
    setmodal_delete(!modal_delete)
  }

  const [modal_delete_selected, setmodal_delete_selected] = useState(false)
  function tog_delete_selected() {
    setmodal_delete_selected(!modal_delete_selected)
  }

  const [modal_delete_all, setmodal_delete_all] = useState(false)
  function tog_delete_all() {
    setmodal_delete_all(!modal_delete_all)
  }

  const [dataList, setDataList] = useState([])
  const [count, setCount] = useState(0)

  const [selectedMessage, setSelectedMessage] = useState({})

  const { sentMessages, listLoading, loading } = useSelector(state => {
    return {
      sentMessages: state.Message.sentMessages,
      listLoading: state.Message.listLoading,
      loading: state.Message.loading,
    }
  })

  useEffect(() => {
    if (sentMessages) {
      setDataList(sentMessages.rows)
      setCount(sentMessages.count)
    }
  }, [sentMessages])

  const getData = () => {
    props.getSentMessageAction({
      offset: paginationOptions?.sizePerPage * (paginationOptions?.page - 1),
      limit: paginationOptions?.sizePerPage,
    })
  }

  useEffect(() => {
    getData()
  }, [paginationOptions])

  const columnsMember = [
    {
      dataField: "username",
      text: "Account",
      style: { width: "20%" },
      formatter: (cellContent, user) => (
        <>
          <div className="d-flex linkbtn">{cellContent}</div>
        </>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      style: { width: "20%" },
      formatter: (cellContent, user) => (
        <>
          <div className="d-flex linkbtn">{cellContent}</div>
        </>
      ),
    },
  ]
  const columns = [
    {
      dataField: "message",
      text: "Description",
      classes: "txt_link",
      style: { width: "60%" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setGridWrapper(false)
          setSelectedMessage(row)
          setViewInfo(!ViewInfo)
        },
      },
      formatter: (cellContent, message) => (
        <>
          <div>
            <Label
              className="me-2 text-secondary cursor-pointer"
              style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
            >
              {cellContent}
            </Label>
          </div>
        </>
      ),
    },
    {
      dataField: "receiverIds",
      text: "",
      classes: "txt_link",
      style: { width: "5%" },
      formatter: (cellContent, message) => (
        <>
          <div className="d-flex linkbtn">
            <Label className="me-2 text-secondary cursor-pointer">
              {message.receiverIds.length}
            </Label>
          </div>
        </>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setGridWrapper(false)
          setSelectedMessage(row)
          setViewReceivedList(!viewReceivedList)
        },
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      style: { width: "20%" },
      formatter: (cellContent, message) => (
        <>
          <div className="d-flex linkbtn">
            {moment(message.createdAt).format("YYYY-MM-DD hh:mm:ss")}
          </div>
        </>
      ),
    },

    {
      dataField: "delete",
      text: "Delete",
      classes: "txt_link",
      style: { width: "10%" },
      formatter: (cellContent, message) => (
        <>
          <div className="text-center" onClick={() => {}}>
            {" "}
            <i className="bx bx-trash-alt fs-5"></i>{" "}
          </div>
        </>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          tog_delete([row.id])
        },
      },
    },
  ]
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: paginationOptions?.sizePerPage || 5,
    totalSize: count, // replace later with size(customers),
    custom: true,
    page: paginationOptions?.page || 1,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    // clickToSelect: true,
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        const idsArray = rows.map(ele => ele.id)
        setSelectedRows(idsArray)
      } else {
        setSelectedRows([])
      }
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      const oldSelectedRows = [...selectedRows]
      if (!isSelect) {
        setSelectedRows(oldSelectedRows.filter(ele => ele !== row.id))
      } else {
        setSelectedRows([...oldSelectedRows, row.id])
      }
    },
  }

  const onSizePerPageChange = sizePerPage => {
    setPaginationOptions({
      ...paginationOptions,
      sizePerPage: sizePerPage,
      page: 1,
    })
  }

  const onPageChange = (page, sizePerPage) => {
    setPaginationOptions({
      ...paginationOptions,
      page: page,
    })
  }

  if (listLoading) {
    return (
      <Spinner
        className={classnames({
          "position-relative": true,
          "button-style": false,
          visible: true,
          "left-50": true,
        })}
        size="lg"
        // type="grow"
      />
    )
  }

  return (
    <React.Fragment>
      <div className="ps-3 pe-3 pb-3">
        <div className="grid_wrapper">
          {GridWrapper ? (
            <div>
              <Row className="mb-3 justify-content-between">
                <Col
                  md="12"
                  className="d-inline-flex align-items-center btnsmall"
                >
                  <Button
                    color="secondary"
                    className="me-3"
                    outline
                    disabled={selectedRows.length <= 0}
                    onClick={() => tog_delete_selected([])}
                  >
                    Delete
                  </Button>

                  <Button
                    color="secondary"
                    className="me-3"
                    outline
                    onClick={() => tog_delete_all()}
                  >
                    Delete All
                  </Button>
                </Col>
              </Row>

              <Row>
                {dataList && (
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={dataList}
                    remote
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={dataList}
                        remote
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    remote
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                    onSizePerPageChange={onSizePerPageChange}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                    onPageChange={onPageChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                )}
              </Row>
            </div>
          ) : null}
        </div>

        {ViewInfo && selectedMessage ? (
          <div>
            <span className="text-start" onClick={ViewInfoBackBtn}>
              <i className="bx bx-arrow-back me-2 txt_link"></i>
              <span className="txt_link">Back</span>
            </span>

            <div className="bg_gray_2 mb-2 mt-2">
              <p>
                To: <span>{selectedMessage?.receiverIds?.length}</span>
              </p>
              <p className="mb-0">
                Date:{" "}
                <span>
                  {moment(selectedMessage?.createdAt).format(
                    "YYYY-MM-DD hh:mm:ss"
                  )}
                </span>
              </p>
            </div>
            <div>
              <p>{selectedMessage?.message}</p>
            </div>
          </div>
        ) : null}

        {viewReceivedList && selectedMessage ? (
          <>
            <span className="text-start" onClick={ViewReceivedListBackBtn}>
              <i className="bx bx-arrow-back me-2 txt_link"></i>
              <span className="txt_link">Back</span>
            </span>
            <PaginationProvider
              pagination={paginationFactory({
                sizePerPage: 5,
                totalSize: selectedMessage?.members?.length, // replace later with size(customers),
                custom: true,
              })}
              keyField="id"
              columns={columnsMember}
              data={selectedMessage?.members}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columnsMember}
                  data={selectedMessage?.members}
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              // defaultSorted={defaultSorted}
                              // selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              hiddenHeader={true}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </>
        ) : null}

        <Modal
          size="md"
          isOpen={modal_delete}
          toggle={() => {
            tog_delete([])
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Delete</h5>
            <button
              type="button"
              onClick={() => {
                tog_delete([])
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
          <div className="modal-body">
            <Row className="mb-1 pl-4">Delete this item?</Row>
            <Row className="">
              <div className="d-flex justify-content-end">
                <LoadingButton
                  color="secondary"
                  className="btn btn-primary submitbtn btn-left"
                  loading={false}
                  disabled={false}
                  block={false}
                  onClick={() => tog_delete([])}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  className="btn btn-primary submitbtn "
                  loading={loading}
                  disabled={loading}
                  block={false}
                  onClick={async () => {
                 
                    props
                      .deleteMessageAction({
                        messageIds: deleteIDs,
                        deleteAll: false,
                      })
                      .then(() => {
                        tog_delete([])
                        setPaginationOptions({
                          sizePerPage: 5,
                          totalSize: 5,
                          custom: true,
                          page: 1,
                        })
                      })
                      .catch(error => {
                        tog_delete([])
                        setPaginationOptions({
                          sizePerPage: 5,
                          totalSize: 5,
                          custom: true,
                          page: 1,
                        })
                      })
                  }}
                >
                  Ok
                </LoadingButton>
              </div>
            </Row>
          </div>
        </Modal>
        <Modal
          size="md"
          isOpen={modal_delete_selected}
          toggle={() => {
            tog_delete_selected()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Delete</h5>
            <button
              type="button"
              onClick={() => {
                tog_delete_selected()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
          <div className="modal-body">
            <Row className="mb-1 pl-4">Delete this item?</Row>
            <Row className="">
              <div className="d-flex justify-content-end">
                <LoadingButton
                  color="secondary"
                  className="btn btn-primary submitbtn btn-left"
                  loading={false}
                  disabled={false}
                  block={false}
                  onClick={() => tog_delete_selected()}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  className="btn btn-primary submitbtn "
                  loading={loading}
                  disabled={loading}
                  block={false}
                  onClick={() => {
                    props
                      .deleteMessageAction({
                        messageIds: selectedRows,
                        deleteAll: false,
                      })
                      .then(() => {
                        tog_delete_selected()
                        setPaginationOptions({
                          sizePerPage: 5,
                          totalSize: 5,
                          custom: true,
                          page: 1,
                        })
                      })
                      .catch(error => {
                        tog_delete_selected()
                        setPaginationOptions({
                          sizePerPage: 5,
                          totalSize: 5,
                          custom: true,
                          page: 1,
                        })
                      })
                  }}
                >
                  Ok
                </LoadingButton>
              </div>
            </Row>
          </div>
        </Modal>
        <Modal
          size="md"
          isOpen={modal_delete_all}
          toggle={() => {
            tog_delete_all()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Delete</h5>
            <button
              type="button"
              onClick={() => {
                tog_delete_all()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
          <div className="modal-body">
            <Row className="mb-1 pl-4">Delete this item?</Row>
            <Row className="">
              <div className="d-flex justify-content-end">
                <LoadingButton
                  color="secondary"
                  className="btn btn-primary submitbtn btn-left"
                  loading={false}
                  disabled={false}
                  block={false}
                  onClick={() => tog_delete_all()}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  className="btn btn-primary submitbtn "
                  loading={loading}
                  disabled={loading}
                  block={false}
                  onClick={() => {
                    props
                      .deleteMessageAction({
                        deleteAll: true,
                      })
                      .then(() => {
                        tog_delete_all()
                        setPaginationOptions({
                          sizePerPage: 5,
                          totalSize: 5,
                          custom: true,
                          page: 1,
                        })
                      })
                      .catch(error => {
                        tog_delete_all()
                        setPaginationOptions({
                          sizePerPage: 5,
                          totalSize: 5,
                          custom: true,
                          page: 1,
                        })
                      })
                  }}
                >
                  Ok
                </LoadingButton>
              </div>
            </Row>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

SentMessageTab.propTypes = {
  modal_center: PropTypes.bool,
  tog_center: PropTypes.func,
  getSentMessageAction: PropTypes.func,
  deleteMessageAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  getSentMessageAction: bindActionToPromise(dispatch, getSentMessages),
  deleteMessageAction: bindActionToPromise(dispatch, deleteMessage),
})
export default connect(mapStateToProps, mapDispatchToProps)(SentMessageTab)
