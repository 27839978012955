import {
  ADD_USERS,
  ADD_USERS_SUCCESS,
  ADD_USERS_ERROR,
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_ERROR,
  UPDATE_USERS,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_ERROR,
  CHANGE_PASSWORD_MEMBER,
  CHANGE_PASSWORD_MEMBER_SUCCESS,
  CHANGE_PASSWORD_MEMBER_ERROR,
  SEARCH_USER_DEFAULT,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_ERROR,
} from "./actionTypes"

const initialState = {
  userData: [],
  userDetail: {},
  isLoading: false,
  lodaingForDetail: false,
}

const addUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DETAIL:
      return {
        ...state,
        isLoading: true,
        lodaingForDetail: true,
      }
    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
        isLoading: false,
        lodaingForDetail: false,
      }

    case GET_USER_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        lodaingForDetail: false,
      }

    case ADD_USERS:
      return {
        ...state,
        isLoading: true,
      }

    case ADD_USERS_SUCCESS:
      return {
        ...state,
        userData: [...state.userData, action.payload],
        isLoading: false,
      }

    case ADD_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_USERS:
      return {
        ...state,
        isLoading: true,
      }

    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userData: [...state.userData, action.payload],
      }

    case UPDATE_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
      }

    case CHANGE_PASSWORD_MEMBER:
      state = {
        ...state,
        loading: true,
      }
      break
    case CHANGE_PASSWORD_MEMBER_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case CHANGE_PASSWORD_MEMBER_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case SEARCH_USER_DEFAULT:
      return {
        ...state,
        isLoading: true,
      }
    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        userSearchData: action.payload,
        isLoading: false,
      }

    case SEARCH_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default addUserReducer
