/* eslint-disable */

import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Modal,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import Tooltip from "@mui/material/Tooltip"
import lefticon10 from "../../assets/images/icons/alert.svg"

//i18n
import { withTranslation } from "react-i18next"
import ImportExcelPopsApikey from "./ImportFromExcelFile"
import MonitorBottomNotification from "./MonitorBottomNotification"
import AlertReciveVoice from "./AlertReciveVoice"
const MonitorBottomAlert = props => {
  const [singlebtn, setSinglebtn] = useState(false)
  const [menu, setMenu] = useState(false)
  const [col6, setcol6] = useState(true)
  const t_col6 = () => {
    setcol6(!col6)
  }
  const [ImportExcelPop, setImportExcelPop] = useState(false)
  function ImportExcel() {
    setMenu(!menu)
    setImportExcelPop(!ImportExcelPop)
    removeBodyCss()
  }

  const [AlertVolume, setAlertVolumeBtn] = useState(false)
  const AlertVolumeBtn = () => {
    setAlertVolumeBtn(!AlertVolume)
  }

  const [NotificationPop, setNotificationPop] = useState(false)
  function NotificationPopBtn() {
    setMenu(!menu)
    setNotificationPop(!NotificationPop)
    removeBodyCss()
  }

  const [ReciveVoicePop, setReciveVoicePop] = useState(false)
  function ReciveVoicePopBtn() {
    setMenu(!menu)
    setReciveVoicePop(!ReciveVoicePop)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="div"
      >
        <DropdownToggle className="no-button ms-4" tag="button">
          <Tooltip title="Alert" placement="top" arrow>
            <img src={lefticon10} width={30} />
          </Tooltip>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu mb_alert_toggle p-0">
          <div className="d-flex align-items-center bot_pop_head">
            <i className="bx bx-bell pe-2 flx_0 font-size-17"></i>
            <span className="flx_1"> Alert</span>
            <div className="flx_0 ps-2 pt-2">
              <span
                onClick={() => setMenu(!menu)}
                className="bx bx-message-alt-minus font-size-17 cursor-pointer"
              ></span>
            </div>
          </div>

          <SimpleBar style={{ height: "360px" }}>
            <div className="poi_header bg_cus_2 p-2 d-flex align-items-center flex-row">
              <div className="flx_0 pe-2">
                <input type="checkbox" className="form-check-input" />
              </div>
              <div className="flx_1 pe-2">
                <div className="clos_input position-relative">
                  <input className="form-control poi_head_input" type="text" />
                  <span className="txt_clr d-flex align-items-center justify-content-center">
                    X
                  </span>
                </div>
              </div>
              <div className="flx_0 pe-1">
                <i
                  onClick={AlertVolumeBtn}
                  className={
                    "bx cursor-pointer fs-5 " +
                    (AlertVolume ? "bx-volume-mute" : "bx-volume")
                  }
                ></i>
              </div>
              <div className="flx_0 pe-1">
                <i className="bx bx-trash-alt fs-5"></i>
              </div>

              <div className="flx_0">
                <Dropdown
                  isOpen={singlebtn}
                  toggle={() => setSinglebtn(!singlebtn)}
                >
                  <DropdownToggle className="no-button cus_nobtn p-0 bg-transparent">
                    <i className="bx bx-dots-vertical fs-5"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className="d-flex align-items-center">
                      <span
                        onClick={() => {
                          NotificationPopBtn()
                        }}
                        className="cursor-pointer  d-flex align-items-center"
                      >
                        <i className="bx bx-bell fs-5 me-1"></i> Notification
                      </span>
                      <Modal
                        isOpen={NotificationPop}
                        size="lg"
                        toggle={() => {
                          NotificationPopBtn()
                        }}
                        centered
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">Notification</h5>
                          <button
                            type="button"
                            onClick={() => {
                              setMenu(true)
                              setNotificationPop(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="mdi mdi-close"></i>
                          </button>
                        </div>
                        <MonitorBottomNotification />
                      </Modal>
                    </DropdownItem>
                    <DropdownItem className="d-flex align-items-center">
                      <span
                        onClick={() => {
                          ReciveVoicePopBtn()
                        }}
                        className="cursor-pointer d-flex align-items-center"
                      >
                        <i className="bx bx-cog fs-5 me-1"></i> Receive voice
                      </span>
                      <Modal
                        isOpen={ReciveVoicePop}
                        size="md"
                        toggle={() => {
                          ReciveVoicePopBtn()
                        }}
                        centered
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">Notification</h5>
                          <button
                            type="button"
                            onClick={() => {
                              setMenu(true)
                              setReciveVoicePop(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="mdi mdi-close"></i>
                          </button>
                        </div>
                        <AlertReciveVoice />
                      </Modal>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <ul className="deviceshown route_list_item ul_h1 p-2">
              <li>
                <div className="d-flex align-items-start w-100">
                  <div className="form-check flx_0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                  </div>
                  <div className="flx_1 pe-2">
                    <span className="">04-20 16:15:30</span>
                  </div>
                  <div className="flx_0 pe-2">
                    <span className="link_txt"> ALFA</span>
                  </div>
                  <div className="flx_1">
                    <span className="txt_over_1">Parking alert</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-start w-100">
                  <div className="form-check flx_0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                  </div>
                  <div className="flx_1 pe-2">
                    <span className="">04-20 16:15:30</span>
                  </div>
                  <div className="flx_0 pe-2">
                    <span className="link_txt"> ALFA</span>
                  </div>
                  <div className="flx_1">
                    <span className="txt_over_1">Parking alert</span>
                  </div>
                </div>
              </li>
            </ul>
            <div className="bg_cus_1 p-1 text-center">
              <i className="bx bx-bar-chart"></i>
              View all alerts
            </div>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
export default withTranslation()(MonitorBottomAlert)
