/* eslint-disable */

import React from 'react'
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

const PlayBackTripTab = () => {
  return (
    <div>
        <div className="table-responsive" style={{"height":"135px", "overflow":"auto"}}>
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>#</th>
                          <th>Trip State</th>
                          <th>Start time</th>
                          <th>End time</th>
                          <th>Mileage(KM)</th>
                          <th>Duration</th>
                          <th>Start Location</th>
                          <th>End Location</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                      </tbody>
                    </Table>
                  </div>
    </div>
  )
}

export default PlayBackTripTab