import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_DEVICES_BY_MEMBERID,
  GET_DEVICES_INFO_BY_MEMBERID,
  GET_DEVICE_BY_DEVICEID,
  PUT_DEVICE_BY_DEVICEID,
  PUT_DEVICE_INSSTALLATION_INFO,
  PUT_DEVICE_FUEL,
  PUT_DEVICE_ALARM
} from "./actionTypes"

import {
  getDevicesByMemberIdSuccess,
  getDevicesByMemberIdFail,
  getDevicesInfoByMemberIdSuccess,
  getDevicesInfoByMemberIdFail,
  getDeviceByDeviceIdSuccess,
  getDeviceByDeviceIdFail,
  putDeviceByDeviceIdSuccess,
  putDeviceByDeviceIdFail,
  putDeviceInstallationInfoSuccess,
  putDeviceInstallationInfoFail,
  putDeviceFuelSuccess,
  putDeviceFuelFail,
  putAlarmSettingsSuccess,
  putAlarmSettingsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDevicesByMember,
  getDevicesInfoByMember,
  getDeviceByDeviceId,
  putDeviceByDeviceId,
  putDeviceInstallationInfo,
  putDeviceFuel,
  putDeviceAlarmSettings,
  imageUpload,
} from "../../helpers/fakebackend_helper"

import { showToast } from "../../utils/Common"

function* fetchDevicesByMemeber({ payload }) {
  try {
    const response = yield call(getDevicesByMember, payload)
    yield put(getDevicesByMemberIdSuccess(response))
  } catch (error) {
    yield put(getDevicesByMemberIdFail(error))
  }
}

function* fetchDevicesInfoByMemeber({ memberId }) {
  try {
    const response = yield call(getDevicesInfoByMember, memberId)
    yield put(getDevicesInfoByMemberIdSuccess(response))
  } catch (error) {
    yield put(getDevicesInfoByMemberIdFail(error))
  }
}

function* fetchDeviceByDeviceId({ deviceId }) {
  try {
    const response = yield call(getDeviceByDeviceId, deviceId)
    yield put(getDeviceByDeviceIdSuccess(response))
  } catch (error) {
    yield put(getDeviceByDeviceIdFail(error))
  }
}

function* putDeviceByDeviceIdSaga({ payload }) {
  const {
    payload: payloadData,
    meta: { resolve, reject },
  } = payload
  const { id, data, isImageUpload, files } = payloadData
  try {
    if (isImageUpload) {
      const responseFiles = yield call(imageUpload, { images: files })

      const response = yield call(putDeviceByDeviceId, {
        id,
        data: { ...data, vehiclePicture: responseFiles[0].key },
      })
      yield put(putDeviceByDeviceIdSuccess(response))
      showToast("Updated Successfully!!", true)
      yield call(resolve, response)
    } else {
      const response = yield call(putDeviceByDeviceId, {
        id,
        data: { ...data },
      })
      yield put(putDeviceByDeviceIdSuccess(response))
      showToast("Updated Successfully!!", true)
      yield call(resolve, response)
    }
  } catch (error) {
    yield put(putDeviceByDeviceIdFail())
    yield call(reject, error)
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}

function* putDeviceInstallationInfoSaga({ payload }) {
  const {
    payload: payloadData,
    meta: { resolve, reject },
  } = payload
  const { data, isImageUpload, files } = payloadData
  try {
    if (isImageUpload) {
      const responseFiles = yield call(imageUpload, { images: files })

      const response = yield call(putDeviceInstallationInfo, {
        data: { ...data, image: responseFiles[0].key },
      })
      yield put(putDeviceInstallationInfoSuccess(response))
      showToast("Updated Successfully!!", true)
      yield call(resolve, response)
    } else {
      const response = yield call(putDeviceInstallationInfo, {
        data: { ...data },
      })
      yield put(putDeviceInstallationInfoSuccess(response))
      showToast("Updated Successfully!!", true)
      yield call(resolve, response)
    }
  } catch (error) {
    yield put(putDeviceInstallationInfoFail())
    yield call(reject, error)
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}

function* putDeviceFuelSaga({ payload }) {
  const {
    payload: payloadData,
    meta: { resolve, reject },
  } = payload
  const { data } = payloadData
  try {
    const response = yield call(putDeviceFuel, {
      data: { ...data },
    })
    yield put(putDeviceFuelSuccess(response))
    showToast("Updated Successfully!!", true)
    yield call(resolve, response)
  } catch (error) {
    yield put(putDeviceFuelFail())
    yield call(reject, error)
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}


function* pulDeviceAlarmSettingsSaga({ payload }) {
  const {
    payload: payloadData,
    meta: { resolve, reject },
  } = payload
  const { data } = payloadData

  try {
    const response = yield call(putDeviceAlarmSettings, {
      data: { ...data },
    })
    yield put(putAlarmSettingsSuccess(response))
    showToast("Updated Successfully!!", true)
    yield call(resolve, response)
  } catch (error) {
    yield put(putAlarmSettingsFail())
    yield call(reject, error)
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}


function* contactsSaga() {
  yield takeEvery(GET_DEVICES_BY_MEMBERID, fetchDevicesByMemeber)
  yield takeEvery(GET_DEVICES_INFO_BY_MEMBERID, fetchDevicesInfoByMemeber)
  yield takeEvery(GET_DEVICE_BY_DEVICEID, fetchDeviceByDeviceId)
  yield takeEvery(PUT_DEVICE_BY_DEVICEID, putDeviceByDeviceIdSaga)
  yield takeEvery(PUT_DEVICE_INSSTALLATION_INFO, putDeviceInstallationInfoSaga)
  yield takeEvery(PUT_DEVICE_FUEL, putDeviceFuelSaga),
  yield takeEvery(PUT_DEVICE_ALARM, pulDeviceAlarmSettingsSaga)
}

export default contactsSaga
