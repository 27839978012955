import React, { useState } from "react"
import PropTypes from "prop-types"
import { Tooltip, Input } from "reactstrap"

const NotificationAlertEmail = props => {
  const { emailsList, addNewEmail, removeEmail, updateEmail } = props
  const [ttop, setttop] = useState(false)

  return (
    <div>
      <div className="cus_br p-2 mb-3">
        Email:
        <div className="pb-2">
          {emailsList.map((emailObj, index) => (
            <div className="pt-2 d-flex align-items-center" key={index}>
              <div className="flx_0 pe-2">
                <Input
                  name="email"
                  type="text"
                  className="form-control"
                  onChange={e => updateEmail(index, e.target.value)}
                  value={emailObj.email}
                />
              </div>
              {index === 0 ? (
                <div
                  className="flx_0 pe-1 cursor-poiter"
                  onClick={() => addNewEmail()}
                >
                  <i className="bx bxs-plus-square fs-5"></i>
                </div>
              ) : (
                <div
                  className="flx_0 pe-1 cursor-poiter"
                  onClick={() => removeEmail(index)}
                >
                  <i className="bx bxs-minus-square fs-5"></i>
                </div>
              )}
              <div className="flx_0 pe-1 cursor-poiter">
                <span className="link_txt">Verify </span>
              </div>
              <div className="flx_0">
                <Tooltip
                  placement="top"
                  isOpen={ttop}
                  target="TooltipTop1"
                  toggle={() => {
                    setttop(!ttop)
                  }}
                  className="fs-2 text-left"
                >
                  A mail will be sent to verify the validity of email address.
                </Tooltip>
                <span id="TooltipTop1">
                  <i className=" bx bxs-info-circle txt_colr_1"></i>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

NotificationAlertEmail.propTypes = {
  emailsList: PropTypes.array,
  addNewEmail: PropTypes.func,
  removeEmail: PropTypes.func,
  updateEmail: PropTypes.func,
}

export default NotificationAlertEmail
