/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Tooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Label,
  Button,
  Form,
  Input,
  NavLink,
  Container,
  InputGroup,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Link } from "react-router-dom"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import SelectSearch from "react-select-search"

import "./Card.css"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

const ExchangeLifeCardHistory = () => {
  // data
  const columns = [
    {
      dataField: "sno",
      text: "#",
      sort: false,
    },
    {
      dataField: "datetime",
      text: "Datetime",
      sort: false,
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
    },
    {
      dataField: "operadetails",
      text: "Operation detail",
      sort: false,
    },
    {
      dataField: "income",
      text: "Income",
      sort: false,
    },
    {
      dataField: "expenditure",
      text: "Expenditure",
      sort: false,
    },
    {
      dataField: "cardbalance",
      text: "Card balance",
      sort: false,
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: false,
    },
    {
      dataField: "operation",
      text: "Operation",
      sort: false,
    },
  ]

  // Table Data
  const productData = [
    {
      sno: "1",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "2",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "3",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "4",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "5",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "6",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "7",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "8",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "9",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "10",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "11",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
    {
      sno: "12",
      datetime: "23-12-2022, 11.58 pm",
      type: "asdf",
      operadetails: "1CA7895",
      income: "",
      expenditure: "Pickuptruck",
      cardbalance: "sales",
      remarks: "25",
      operation: "2",
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  // Select All Button operation
  //const selectRow = {
  // mode: 'checkbox'
  //}

  const { SearchBar } = Search

  const handleChange = (...args) => {
    searchInput.current.querySelector("input").value = args
  }

  const handleFilter = items => {
    return searchValue => {
      if (searchValue.length === 0) {
        return options
      }
      const updatedItems = items.map(list => {
        const newItems = list.items.filter(item => {
          return item.name.toLowerCase().includes(searchValue.toLowerCase())
        })
        return { ...list, items: newItems }
      })
      return updatedItems
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Business</title>
        </MetaTags>

        <div>
          <Container fluid>
            <Row className="mt-3">
              <Col md={12}>
                <Card className="mb-3">
                  <CardBody className="d-flex align-items-center justify-content-between p-2">
                    <div className="page-title-box">
                      <h3 className="mb-0 font-size-16 d-flex align-items-center">
                        Transfer card
                      </h3>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody className="px-3 py-4">
                    <Row>
                      <Col className="col-12">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="id"
                          columns={columns}
                          data={productData}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              columns={columns}
                              data={productData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-4 d-flex mt-2">
                                    <div className="col-auto pt-2 mb-2">
                                      Type
                                    </div>

                                    <div className="col-auto mb-2">
                                      <select
                                        defaultValue="1"
                                        className="form-select min-width200"
                                        name="users"
                                      >
                                        <option value="0">All</option>
                                        <option value="New card">
                                          New card
                                        </option>
                                        <option value="New card to lifelong">
                                          New card to lifelong
                                        </option>
                                        <option value="Annual card">
                                          Annual card
                                        </option>
                                        <option value="Lifelong card">
                                          Lifelong card
                                        </option>
                                      </select>
                                    </div>

                                    <div className="col-auto pt-2 mb-2">
                                      From
                                    </div>
                                    <div className="col-auto mb-2">
                                      <input
                                        className="form-control"
                                        type="date"
                                        defaultValue=""
                                        id="example-date-input"
                                      />
                                      <span className="form-text">
                                        Maximum time range of one month.
                                      </span>
                                    </div>
                                    <div className="col-auto pt-2 mb-2">To</div>
                                    <div className="col-auto mb-2">
                                      <input
                                        className="form-control"
                                        type="date"
                                        defaultValue=""
                                        id="example-date-input"
                                      />
                                      <span className="form-text invisible">
                                        Maximum time range of one month.
                                      </span>
                                    </div>

                                    <div className="col-auto pt-2 mb-2">
                                      Target user
                                    </div>

                                    <div className="col-auto mb-2">
                                      <select
                                        defaultValue="1"
                                        className="form-select min-width200"
                                        name="users"
                                      >
                                        <option value="0">Select</option>
                                        <option value="User1">User1</option>
                                      </select>
                                    </div>

                                    <div className="col-auto mb-2">
                                      <Button
                                        type="submit"
                                        color="primary"
                                        className="me-3 btnwid"
                                      >
                                        Check
                                      </Button>
                                      <Button
                                        type="reset"
                                        color="secondary"
                                        className="btnwid"
                                        outline
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          //selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ExchangeLifeCardHistory
