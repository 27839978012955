/* eslint-disable */

import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"
import CheckPopup from "./CheckPopup"

function SettingsPop() {
  const [popoverright, setpopoverright] = useState(false);


  return (
    <div>
      <div className="modal-body">
      

        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            Fuel Tank Shape <small className="text-danger">*</small>
          </label>
          <div className="col-md-8">
            <div className="row gx-2">
              <div className="col d-flex">
              <div className="mt-2 d-flex">
                        <div className="form-checK me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios1"
                          >
                           Cube
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios2"
                            value="option2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            Horizontal
                          </label>
                        </div>
                      </div>
              </div>
            </div>
          </div>
        </Row>
        <Row className="mb-3">
        <div className="col-md-12 d-flex align-items-center">
        <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
          </label>
          <div className="col-md-8">

                        <div className="col-auto input-group w-150x ms-3 mb-2">
                        <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                Length
                              </span>
                            </div>
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="0"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                CM
                              </span>
                            </div>
                            </div>

                            <div className="col-auto input-group w-150x ms-3 mb-2">
                        <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                Width
                              </span>
                            </div>
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="0"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                CM
                              </span>
                            </div>
                            </div>


                            <div className="col-auto input-group w-150x ms-3">
                        <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                Height
                              </span>
                            </div>
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="0"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                CM
                              </span>
                            </div>
                            </div>


                            </div>
          </div>
        </Row>
        <Row className="mb-3">
        <div className="col-md-12 d-flex align-items-center">
        <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
           Fuel consumption
          </label>
                        <div className="col-auto input-group w-150x ms-3">
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="0"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                L/100KM
                              </span>
                            </div>
                            </div>
          </div>
        </Row>

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary ">
          Save changes
        </button>
        
      </div>
    </div>
  )
}

export default SettingsPop
