/* eslint-disable */
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Row, Tooltip, Input } from "reactstrap"

import { bindActionToPromise } from "../../utils/Common"

import { putPreferences } from "../../store/actions"

import LoadingButton from "../../components/Common/Button"

import { TimeZoneOptionList } from "../../utils/Common"

import { useSelector } from "react-redux"

import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"

import Select from "react-select"

function Preference(props) {
  const {
    preferenceInput,
    updatePreferenceField,
    notificationAlertInput,
    notificationServiceInput,
    selectedUser,
    tog_preferences,
    isTemplate,
  } = props

  const [ttop, setttop] = useState(false)
  const [ttop2, setttop2] = useState(false)
  const [ttop3, setttop3] = useState(false)

  const { isLoading } = useSelector(state => {
    return {
      isLoading: state.Preferences.isLoading,
    }
  })

  const distanceSpeedOptions = [
    { value: "Kilometer", label: "Kilometer" },
    { value: "Mile", label: "Mile" },
  ]

  const pressureUnitOptions = [
    {
      value: "Pounds Per Square Inch(PSI)",
      label: "Pounds Per Square Inch(PSI)",
    },
    { value: "Bar(Bar)", label: "Bar(Bar)" },
    { value: "KiloPascals(KPa)", label: "KiloPascals(KPa)" },
  ]

  const temperatureUnitOptions = [
    { value: "Celsius(C)", label: "Celsius(℃)" },
    { value: "Fahrenheit(F)", label: "Fahrenheit(℉)" },
  ]

  const volumeUnitOptions = [
    { value: "Liters(L)", label: "Liters(L)" },
    { value: "Gallons(US)", label: "Gallons(US)" },
    { value: "Gallons(UK)", label: "Gallons(UK)" },
  ]

  const fuelMetricsOptions = [
    { value: "L/100KM", label: "L/100KM" },
    { value: "Miles/gallon (US)", label: "Miles/gallon (US)" },
    { value: "Miles/gallon (UK)", label: "Miles/gallon (UK)" },
  ]

  const firstDayOfWeekOptions = [
    { value: "Monday", label: "Monday" },
    { value: "Sunday", label: "Sunday" },
  ]

  const optionsTimeZone = TimeZoneOptionList()

  return (
    <React.Fragment>
      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Distance and speed unit
          </label>
          <Select
            classNamePrefix="select2-selection"
            placeholder="Please Select"
            title="Distance and speed unit"
            name="distanceSpeedUnit"
            isClearable={false}
            options={distanceSpeedOptions}
            isSearchable={false}
            onChange={selectedOption =>
              updatePreferenceField(
                "distanceSpeedUnit",
                selectedOption?.value || "Kilometer"
              )
            }
            value={distanceSpeedOptions.filter(option => {
              if (option.value == preferenceInput.distanceSpeedUnit)
                return option
            })}
          />
        </div>

        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Pressure unit
          </label>
          <Select
            classNamePrefix="select2-selection"
            placeholder="Please Select"
            title="Pressure unit"
            name="pressureUnit"
            isClearable={false}
            options={pressureUnitOptions}
            isSearchable={false}
            onChange={selectedOption =>
              updatePreferenceField(
                "pressureUnit",
                selectedOption?.value || "Pounds Per Square Inch(PSI)"
              )
            }
            value={pressureUnitOptions.filter(option => {
              if (option.value == preferenceInput.pressureUnit) return option
            })}
          />
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Temperature unit
          </label>
          <Select
            classNamePrefix="select2-selection"
            placeholder="Please Select"
            title="Temperature unit"
            name="temperatureUnit"
            isClearable={false}
            options={temperatureUnitOptions}
            isSearchable={false}
            onChange={selectedOption =>
              updatePreferenceField(
                "temperatureUnit",
                selectedOption?.value || "Celsius(℃)"
              )
            }
            value={temperatureUnitOptions.filter(option => {
              if (option.value == preferenceInput.temperatureUnit) return option
            })}
          />
        </div>

        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Volume unit
          </label>

          <Select
            classNamePrefix="select2-selection"
            placeholder="Please Select"
            title="Volume unit"
            name="volumeUnit"
            isClearable={false}
            options={volumeUnitOptions}
            isSearchable={false}
            onChange={selectedOption =>
              updatePreferenceField(
                "volumeUnit",
                selectedOption?.value || "Litres(L)"
              )
            }
            value={volumeUnitOptions.filter(option => {
              if (option.value == preferenceInput.volumeUnit) return option
            })}
          />
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Fuel Efficiency Metrics
          </label>
          <Select
            classNamePrefix="select2-selection"
            placeholder="Please Select"
            title="Fuel Efficiency Metrics"
            name="fuelMetrics"
            isClearable={false}
            options={fuelMetricsOptions}
            isSearchable={false}
            onChange={selectedOption =>
              updatePreferenceField(
                "fuelMetrics",
                selectedOption?.value || "L/100KM"
              )
            }
            value={fuelMetricsOptions.filter(option => {
              if (option.value == preferenceInput.fuelMetrics) return option
            })}
          />
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Time zone
          </label>
          <Select
            classNamePrefix="select2-selection"
            placeholder="Please Select"
            title="Time zone"
            name="timezone"
            isClearable={false}
            options={optionsTimeZone}
            isSearchable={false}
            onChange={selectedOption =>
              updatePreferenceField("timeZone", selectedOption.value)
            }
            value={optionsTimeZone.filter(option => {
              if (option.value === preferenceInput.timeZone) return option
            })}
          />
        </div>

        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            First day of week
          </label>
          <Select
            classNamePrefix="select2-selection"
            placeholder="Please Select"
            title="First day of week"
            name="firstDayOfWeek"
            isClearable={false}
            options={firstDayOfWeekOptions}
            isSearchable={false}
            onChange={selectedOption =>
              updatePreferenceField(
                "firstDayOfWeek",
                selectedOption?.value || "Monday"
              )
            }
            value={firstDayOfWeekOptions.filter(option => {
              if (option.value == preferenceInput.firstDayOfWeek) return option
            })}
          />
        </div>
      </Row>
      <hr className="mt-3 mb-1" />
      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Engine IDLE{" "}
            <Tooltip
              placement="top"
              isOpen={ttop}
              target="TooltipTop"
              toggle={() => {
                setttop(!ttop)
              }}
            >
              After enable the engine idle status, <br />
              <br />
              when ACC is ON and device is static for more than 35 seconds,{" "}
              <br />
              <br />
              platform will show Engine IDLE status (orange color icon) for the
              device.
            </Tooltip>
            <span id="TooltipTop">
              <i className=" bx bxs-info-circle txt_colr_1"></i>
            </span>
          </label>
          <div className="col-md-12 d-flex align-items-center px-2">
            <div className="form-check form-switch form-switch-md mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="horizontal-customCheck-auto1"
                onClick={e =>
                  updatePreferenceField("engineIdle", e.target.checked)
                }
                checked={preferenceInput.engineIdle}
                defaultChecked={preferenceInput.engineIdle}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Command Password{" "}
            <Tooltip
              placement="top"
              isOpen={ttop2}
              target="TooltipTop2"
              toggle={() => {
                setttop2(!ttop2)
              }}
            >
              Whether need to verify login password when sending GPRS command.
              (remark: All gprs commands needs to verify login password except
              the customize GPRS commands)
            </Tooltip>
            <span id="TooltipTop2">
              <i className=" bx bxs-info-circle txt_colr_1"></i>
            </span>
          </label>
          <div className="col-md-12 d-flex align-items-center px-2">
            <div className="form-check form-switch form-switch-md mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="customSwitchsizemd"
                onClick={e =>
                  updatePreferenceField("commandPassword", e.target.checked)
                }
                checked={preferenceInput.commandPassword}
                defaultChecked={preferenceInput.commandPassword}
              />
            </div>
          </div>
        </div>
      </Row>
      <hr className="mt-1 mb-1" />
      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Enable IMEI login{" "}
            <Tooltip
              placement="top"
              isOpen={ttop3}
              target="TooltipTop3"
              toggle={() => {
                setttop3(!ttop3)
              }}
            >
              Whether enable IMEI number login or not. <br />
              （For security reason, we suggest using account login）
            </Tooltip>
            <span id="TooltipTop3">
              <i className=" bx bxs-info-circle txt_colr_1"></i>
            </span>
          </label>
          <div className="col-md-12 d-flex align-items-center px-2">
            <div className="form-check form-switch form-switch-md mb-3">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="customSwitchsizemd2"
                onClick={e =>
                  updatePreferenceField("enableIMEI", e.target.checked)
                }
                checked={preferenceInput.enableIMEI}
                defaultChecked={preferenceInput.enableIMEI}
              />
            </div>
          </div>
        </div>
      </Row>
      <hr className="mt-1 mb-1" />
      {isTemplate && (
        <Row className="mb-1">
          <div className="col-md-6">
            <label className="col-md-12 col-form-label text-start">
              Preferences: Set automatically for new sub-account
            </label>
            <div className="col-md-12 d-flex align-items-center px-2">
              <div className="form-check form-switch form-switch-md mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                />
              </div>
            </div>
          </div>
        </Row>
      )}
      <Row className="mb-1 mt-3">
        <div className="col-md-12 d-flex justify-content-end">
          <LoadingButton
            color="primary"
            className="btn btn-primary submitbtn "
            loading={isLoading}
            disabled={isLoading}
            block={false}
            onClick={() =>
              props
                .putPreferencesAction({
                  data: {
                    preferences: {
                      ...preferenceInput,
                    },
                    notificationAlert: {
                      ...notificationAlertInput,
                    },
                    notificationService: {
                      ...notificationServiceInput,
                    },
                  },
                  memberId: selectedUser?.id,
                })
                .then(() => {
                  tog_preferences()
                })
            }
          >
            Save
          </LoadingButton>
        </div>
        {!isTemplate && (
          <span className="d-block color_1 font-size-13">
            More: <br />
            <b
              onClick={() => {
                props.tog_center4 && props.tog_center4()
              }}
              className="cursor-pointer"
            >
              <i className=" bx bx-cog"></i> Template settings
            </b>
            (set default settings for new sub-account)
          </span>
        )}
      </Row>
    </React.Fragment>
  )
}

Preference.propTypes = {
  preferenceInput: PropTypes.obj,
  notificationAlertInput: PropTypes.obj,
  notificationServiceInput: PropTypes.obj,
  updatePreferenceField: PropTypes.func,
  putPreferencesAction: PropTypes.func,
  selectedUser: PropTypes.obj,
  tog_preferences: PropTypes.func,
  tog_center4: PropTypes.func,
  isTemplate: PropTypes.bool,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  putPreferencesAction: bindActionToPromise(dispatch, putPreferences),
})
export default connect(mapStateToProps, mapDispatchToProps)(Preference)
