import React,{ useEffect, useState } from "react"
import { LeafletTrackingMarker } from "react-leaflet-tracking-marker"
import L from "leaflet"
import PropTypes from "prop-types"
import airplaneIcon from "./arrow_head.png"
import { Popup } from "react-leaflet"

const icon = L.icon({
  iconSize: [50, 50],
  popupAnchor: [2, -20],
  iconUrl: airplaneIcon,
})

function AirplaneMarker({ data, speedMarker }) {
  const { lat, lng, model, memberId, speed, createdAt } = data
  const [prevPos, setPrevPos] = useState([lat, lng])

  useEffect(() => {
    if (prevPos[1] !== lng && prevPos[0] !== lat) setPrevPos([lat, lng])
  }, [lat, lng, prevPos])
  console.log(speedMarker,"===========speed===========");
  return (
    <>
      <LeafletTrackingMarker
        icon={icon}
        position={[lat, lng]}
        previousPosition={prevPos}
        duration={speedMarker/2}
        keepAtCenter={true}
      >
        <Popup>{"Hello, there! 🐱‍🏍 "}</Popup>
      </LeafletTrackingMarker>
    </>
  )
}

AirplaneMarker.propTypes = {
  data: PropTypes.any,
  onClickOut: PropTypes.func,
  speedMarker:PropTypes.string
}

export default AirplaneMarker
