import React, { useState } from "react"
import { Modal } from "reactstrap"
import lefticon20 from "../../../assets/images/icons/io.svg"
import Tooltip from "@mui/material/Tooltip"

const Iosetting = () => {
  const [IoSetting, setIoSetting] = useState(false)
  function IoSettingBtn() {
    setIoSetting(!IoSetting)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  return (
    <div>
      <span
        onClick={() => {
          IoSettingBtn()
        }}
        className="cursor-pointer"
      >
        
        <Tooltip title="Share Location" placement="bottom" arrow>
          <img alt="" src={lefticon20} width={30} />
        </Tooltip>
      </span>

      <Modal
        isOpen={IoSetting}
        size="md"
        toggle={() => {
          IoSettingBtn()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">I/O Settings</h5>
          <button
            type="button"
            onClick={() => {
              setIoSetting(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>

        <div className="form_share p-3">
          <div className="bg_gray p-2 mb-3 d-flex align-items-center flex-row">
            <i className="bx bxs-info-circle txt_link flx_0 fs-5 me-2"></i>
            <p className="mb-0 mt-0 flx_1">
              Please confirm whether your device support these functions.
            </p>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Input 1 :</label>
                <div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Input 2 :</label>
                <div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Input 3 :</label>
                <div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Input 4 :</label>
                <div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Input 5 :</label>
                <div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Input 6 :</label>
                <div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Input 7 :</label>
                <div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Input 8 :</label>
                <div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
          </div>

          <div className="text-end">
            <button 
             onClick={() => {
              setIoSetting(false)
            }}
            className="btn btn-sm btn-outline-primary me-2">
              Cancel
            </button>
            <button className="btn btn-sm btn-primary">Submit</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Iosetting
