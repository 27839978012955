/* eslint-disable */

import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"
import CheckPopup from "./CheckPopup"

function Recharge() {
  const [popoverright, setpopoverright] = useState(false)

  return (
    <div>
      <div className="modal-body">
        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            IMEI <small className="text-danger">*</small>
          </label>
          <div className="col-md-8">
            <div className="row gx-2">
              <div className="col">
                <input
                  className="form-control"
                  type="text"
                  value={892951027457085}
                />
              </div>
            </div>
          </div>
        </Row>

        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            Device name
          </label>
          <div className="col-md-8 pt-2">
            <span> GT06N-57085 </span>
          </div>
        </Row>

        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            Platform expiration date
          </label>
          <div className="col-md-8 pt-2">
            <span> 2017-08-02</span>
          </div>
        </Row>

        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            Type
          </label>
          <div className="col-md-8">
            <div className="row gx-2">
              <div className="col d-flex">
                <div className="mt-2 d-flex">
                  <div className="form-check mb-3 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      value="option1"
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Annual Card
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      value="option2"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Lifelong Card
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            Platform expiration date(New)
          </label>
          <div className="col-md-8 pt-2">
            <span> 2023-06-01 </span>
          </div>
        </Row>

        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            User due <small className="text-danger">*</small>
          </label>
          <div className="col-md-8">
            <input
              className="form-control"
              type="date"
              defaultValue="2019-08-19"
              id="example-date-input"
            />
          </div>
        </Row>

        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            Remark
          </label>
          <div className="col-md-8">
            <div className="row gx-2">
              <div className="col">
                <textarea rows="4" className="form-control"></textarea>
              </div>
            </div>
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary ">
          Save changes
        </button>
      </div>
    </div>
  )
}

export default Recharge
