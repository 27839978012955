import React from "react"
import { useState } from "react"
import logo from "../../assets/images/varjo-color.svg"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

function openApi() {
  const [activeTab, setActiveTab] = useState("tab1")
  const jsonData = { code: 0, message: "" }

  const jso1 = {
    record: {
      access_token: "ACCESS_TOKEN",
      expires_in: 7200,
    },
    code: 0,
  }

  const jso2 = {
    record: [
      {
        imei: "358899051025339",
        course: 195,
        systemtime: 1419905754,
        gpstime: 1419906052,
        speed: 0,
        hearttime: 1419906952,
        servertime: 1420964313,
        longitude: 113.909813,
        latitude: 22.583197,
        acctime: 1400,
        accstatus: 1,
        doorstatus: 0,
        chargestatus: 0,
        oilpowerstatus: 1,
        defencestatus: 0,
        datastatus: 2,
        battery: 100,
        mileage: 51654,
        todaymileage: 15654,
      },
    ],
    code: 0,
  }

  const jso3 = {
    record:
      "113.97196,22.568616,1406858664,0,228;113.97196,22.56861,1406858684,0,228;113.97196,22.56861,1406858704,0,228;113.97196,22.56861,1406858724,0,228;113.97196,22.56861,1406858724,0,228;113.97196,22.56861,1406858724,0,228;113.97196,22.56861,1406858724,0,228;113.971934,22.568583,1406858780,0,61;113.971925,22.568585,1406858800,0,61",
    code: 0,
  }

  const jso4 = { code: 0 }

  const jso5 = {
    record: {
      commandid: "1B454",
    },
    code: 0,
  }

  const jso6 = {
    record: {
      response: "Cut off the fuel supply: Success! Speed: 2km/h.",
      commandstatus: 1,
    },
    code: 0,
  }

  const jso7 = {
    code: 0,
    record: [
      {
        imei: "512345678901234",
        devicename: "VT05S-01234",
        devicetype: "VT05S",
        platenumber: "TheOne",
        onlinetime: 0,
        platformduetime: 0,
        simcard: "7557862586",
        activatedtime: 0,
        userduetime: 0,
      },
      {
        imei: "123456789123456",
        devicename: "VT05S-23456",
        devicetype: "VT05S",
        platenumber: "",
        onlinetime: 1419905754,
        platformduetime: 1588603848,
        simcard: "",
        activatedtime: 1419805754,
        userduetime: 1589603848,
      },
    ],
  }

  const jso8 = {
    code: 0,
    record: "27,113.885888,22.580592,1541575497,1541575507,40,40,0",
  }

  const jso9 = {
    code: 0
  };

  const jso10 = {
    code: 0,
    record: {
      createtime: 1562570435,
      mobile: "",
      telephone: "",
      roletype: 1,
      account: "demo",
      email: "openapidemo@gmail.com",
      blockstatus: 1,
      username: "openapidemo"
    }
  };

  const jso11 = {
    code: 0,
    record: [
        {
            imei: "512345678901234",
            devicename: "VT05S-01234",
            devicetype: "VT05S",
            platenumber: "TheOne",
            onlinetime: 0,
            platformduetime: 0,
            simcard: "7557862586",
            activatedtime: 0,
            userduetime: 0
        },
        {
            imei: "123456789123456",
            devicename: "VT05S-23456",
            devicetype: "VT05S",
            platenumber: "",
            onlinetime: 1419905754,
            platformduetime: 1588603848,
            simcard: "",
            activatedtime: 1419805754,
            userduetime: 1589603848
        }
    ]
};

const jso12 = {
    "record": [
        { "imei": "351000091413001", "mileage": 338.21 },
        { "imei": "351000091413002", "mileage": 500.56 },
        { "imei": "351000091413003", "mileage": 280.87 },
        { "imei": "351000091413004", "mileage": 621.21 }
    ],
    "code": 0
};

const jso13 = {
    code: 0,
    record: [
        {
            createtime: 1562570435,
            mobile: "",
            telephone: "",
            roletype: 1,
            account: "demo",
            email: "openapidemo@gmail.com",
            blockstatus: 1,
            username: "openapidemo"
        },
        {
            createtime: 1562670132,
            mobile: "",
            telephone: "",
            roletype: 1,
            account: "demotest",
            email: "demotest@gmail.com",
            blockstatus: 1,
            username: "demotest name"
        }
    ]
};


  const handleTabClick = (tabId: string) => setActiveTab(tabId)

  return (
    <div>
      <div className="api-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12 py-2">

                <Link to="/login">  <img src={logo} alt="" height="65" /></Link>
            
            </div>
          </div>
        </div>
      </div>
      <div className="api-breadcrump py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 py-2 text-center text-white">
              <h2 className="fs-2 text-white">OPEN API</h2>
              <p>You can get Open API service from your service provider.</p>
            </div>
          </div>
        </div>
      </div>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <ul className="info-left-api">
                <li className="">
                  <a
                    className={`${activeTab === "tab1" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab1")}
                  >
                    <h3> 1. Instruction</h3>
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab2" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab2")}
                  >
                    1.1 Parameter encoding
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab3" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab3")}
                  >
                    1.2 Time format
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab4" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab4")}
                  >
                    1.3 HTTP MIME
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab5" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab5")}
                  >
                    1.4 The response JSON format
                  </a>
                </li>

                <li className=""></li>
                <li className=""></li>

                <li className="">
                  <a
                    className={`${activeTab === "tab6" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab6")}
                  >
                    <h3> 2. API List</h3>
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab7" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab7")}
                  >
                    2.1 Get access token
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab8" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab8")}
                  >
                    2.2 Track
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab9" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab9")}
                  >
                    2.3 Playback
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab10" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab10")}
                  >
                    2.4 Create Geofence
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab11" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab11")}
                  >
                    2.5 Send command
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab12" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab12")}
                  >
                    2.6 Query command response
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab13" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab13")}
                  >
                    2.7 Get device list
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab14" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab14")}
                  >
                    2.8 Get device alarm information
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab15" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab15")}
                  >
                    2.9 Block account
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab16" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab16")}
                  >
                    2.10 Unblock account
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab17" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab17")}
                  >
                  2.11 Get account information
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab18" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab18")}
                  >
                  2.12 Get IMEIs information
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab19" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab19")}
                  >
                  2.13 Send message
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab20" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab20")}
                  >
                  2.14 Mileage report
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab21" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab21")}
                  >
                  2.15 Get sub-customer list
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab22" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab22")}
                  >
                  2.16 Modify sub-customer password
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab23" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab23")}
                  >
                  <h3> 3. Error Code</h3>
                  </a>
                </li>

              


              </ul>
            </div>
            <div className="col-md-8">
              <div className="tab-content">
                {activeTab === "tab1" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>1.1 Instruction</h3>
                        </div>


                        <ul className="info-right-api">

                <li className="">
                  <a
                    className={`${activeTab === "tab2" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab2")}
                  >
                    1.1 Parameter encoding
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab3" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab3")}
                  >
                    1.2 Time format
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab4" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab4")}
                  >
                    1.3 HTTP MIME
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab5" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab5")}
                  >
                    1.4 The response JSON format
                  </a>
                </li>
              </ul>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab2" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>1.1 Parameter encoding</h3>
                        </div>
                        <p>Parameter encoding is UTF-8 as standard.</p>
                        <p>
                          Please refer to:{" "}
                          <a href="http://en.wikipedia.org/wiki/UTF-8">
                            http://en.wikipedia.org/wiki/UTF-8
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab3" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>1.2 Time format</h3>
                        </div>
                        <p>Time format is UNIX timestamp.</p>
                        <p>
                          Please refer to:{" "}
                          <a href="https://en.wikipedia.org/wiki/Unix_time">
                            https://en.wikipedia.org/wiki/Unix_time
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab4" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>1.3 HTTP MIME</h3>
                        </div>
                        <p>application/json; charset=UTF-8</p>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab5" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>1.4 The response JSON format</h3>
                        </div>

                        <pre>
                          <code>{JSON.stringify(jsonData, null, 2)}</code>
                        </pre>
                        <p>
                          If code != 0, it means request is error ,please refer
                          to <a href="#Error">Chapter 3</a>.
                        </p>
                        <p>Message is the code description, or it’s empty.</p>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab6" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2. API List</h3>
                        </div>

                        <ul className="info-right-api">
               

                <li className="">
                  <a
                    className={`${activeTab === "tab7" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab7")}
                  >
                    2.1 Get access token
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab8" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab8")}
                  >
                    2.2 Track
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab9" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab9")}
                  >
                    2.3 Playback
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab10" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab10")}
                  >
                    2.4 Create Geofence
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab11" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab11")}
                  >
                    2.5 Send command
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab12" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab12")}
                  >
                    2.6 Query command response
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab13" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab13")}
                  >
                    2.7 Get device list
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab14" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab14")}
                  >
                    2.8 Get device alarm information
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab15" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab15")}
                  >
                    2.9 Block account
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab16" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab16")}
                  >
                    2.10 Unblock account
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab17" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab17")}
                  >
                  2.11 Get account information
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab18" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab18")}
                  >
                  2.12 Get IMEIs information
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab19" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab19")}
                  >
                  2.13 Send message
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab20" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab20")}
                  >
                  2.14 Mileage report
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab21" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab21")}
                  >
                  2.15 Get sub-customer list
                  </a>
                </li>

                <li className="">
                  <a
                    className={`${activeTab === "tab22" ? "activetab" : ""}`}
                    onClick={() => handleTabClick("tab22")}
                  >
                  2.16 Modify sub-customer password
                  </a>
                </li>

              

              


              </ul>


                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab7" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.1 Get access token</h3>
                        </div>

                        <div id="API1" className="api-item api-article">
                          <h4>Purpose</h4>
                          <p>
                            Get access token for the others requests, each api
                            must contain the access token. The access token is
                            valid for 2 hours, so we dont need refresh it
                            frequently. We suggest refreshing the access token
                            in every 90 minutes. When you request a new access
                            token, the old one will be invalid immediately.
                          </p>
                          <h4>Request type</h4>
                          <p>GET</p>
                          <h4>Parameters</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Parameter</th>
                                <th>Type</th>
                                <th>Required</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>time</td>
                                <td>long</td>
                                <td>true</td>
                                <td>The time when the client sends request</td>
                              </tr>
                              <tr>
                                <td>account</td>
                                <td>string</td>
                                <td>true</td>
                                <td>Account</td>
                              </tr>
                              <tr>
                                <td>signature</td>
                                <td>string</td>
                                <td>true</td>
                                <td>
                                  Encrypted signature,the algorithm
                                  is:md5(md5(password) + time),md5 use 32
                                  lower-case characters.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>URI</h4>
                          <p>/api/authorization</p>
                          <h4>Example</h4>
                          <p>
                            The password=Abc@34590,md5(md5(Abc@34590) +
                            1556243443) will calculate out the result
                            signature=3844afd2c3bf68f0f3f4aa0a9ee8ee6c
                          </p>
                          <p>
                            So the request is:
                            https://varjogps.com/api/authorization?time=1556243443&account=test&;signature=3844afd2c3bf68f0f3f4aa0a9ee8ee6c
                          </p>
                          <h4>Response Description</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th colSpan="2">JSON KEY</th>
                                <th>Value type</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan="2">record</td>
                                <td>Object</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>access_token</td>
                                <td>string</td>
                                <td>The access token</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>expires in</td>
                                <td>long</td>
                                <td>access_token expire time in seconds</td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>Response JSON demo</h4>
                          <pre>
                            <code>{JSON.stringify(jso1, null, 2)}</code>
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab8" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.2 Track</h3>
                        </div>

                        <div id="API2" className="api-item api-article">
                          <h4>Purpose</h4>
                          <p>
                            Get the latest coordinate data for tracking device.
                          </p>
                          <h4>Request type</h4>
                          <p>GET</p>
                          <h4>Parameters</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Parameter</th>
                                <th>Type</th>
                                <th>Required</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>access_token</td>
                                <td>string</td>
                                <td>true</td>
                                <td>The accesstoken</td>
                              </tr>
                              <tr>
                                <td>imeis</td>
                                <td>string</td>
                                <td>true</td>
                                <td>
                                  Target IMEI list, separated by comma, max 100
                                  IMEIs in one request.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>URI</h4>
                          <p>/api/track</p>
                          <h4>Example</h4>
                          <p>
                            https://varjogps.com/api/track?access_token=
                            {`accesstoken`}
                            &imeis=358899051025339,355139000000234
                          </p>
                          <h4>Response description</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th colSpan="2">JSON KEY</th>
                                <th>Value type</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td>imei</td>
                                <td>string</td>
                                <td>The imei of the target</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>servertime</td>
                                <td>long</td>
                                <td>Current server time</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>gpstime</td>
                                <td>long</td>
                                <td>Gps time</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>hearttime</td>
                                <td>long</td>
                                <td>
                                  Heart time, the last time when the target sent
                                  data.
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>systemtime</td>
                                <td>long</td>
                                <td>
                                  The system time when the target sent gps data.
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>longitude</td>
                                <td>double</td>
                                <td>Longitude</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>latitude</td>
                                <td>double</td>
                                <td>latitude</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>course</td>
                                <td>int</td>
                                <td>
                                  course(Noth is 0, clockwise increase ,max
                                  360.)
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>speed</td>
                                <td>int</td>
                                <td>speed(KM/H)</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>acctime</td>
                                <td>long</td>
                                <td>
                                  ACC status kept time interval in seconds
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>accstatus</td>
                                <td>int</td>
                                <td>
                                  ACC status <br />
                                  1:ACC ON，
                                  <br />
                                  0:ACC OFF,
                                  <br />
                                  -1:No acc status
                                  <br />
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>doorstatus</td>
                                <td>int</td>
                                <td>
                                  Door status
                                  <br />
                                  1:Door open,
                                  <br />
                                  0:Door closed,
                                  <br />
                                  -1:No door status
                                  <br />
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>chargestatus</td>
                                <td>int</td>
                                <td>
                                  Charging status
                                  <br />
                                  1:Charging,
                                  <br />
                                  0:Not in charging,
                                  <br />
                                  -1:No such status <br />
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>oilpowerstatus</td>
                                <td>int</td>
                                <td>
                                  Fuel status，
                                  <br />
                                  1:Fuel supply ok，
                                  <br />
                                  0:Fuel supply off，
                                  <br />
                                  -1:No such status
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>defencestatus</td>
                                <td>int</td>
                                <td>
                                  Defence status
                                  <br />
                                  1:Defence on，
                                  <br />
                                  0:Defence off，
                                  <br />
                                  -1:No such status
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>datastatus</td>
                                <td>int</td>
                                <td>
                                  1:Never online,
                                  <br />
                                  2:OK,
                                  <br />
                                  3:Expired,
                                  <br />
                                  4:Offline, <br />
                                  5:Block
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>battery</td>
                                <td>int</td>
                                <td>
                                  The battery
                                  <br />
                                  -1:No battery status
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>mileage</td>
                                <td>int</td>
                                <td>
                                  mileage(unit:m)
                                  <br />
                                  -1:No mileage.
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>todaymileage</td>
                                <td>long</td>
                                <td>
                                  todaymileage(unit:m)
                                  <br />
                                  -1:No todaymileage.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>Response JSON demo</h4>
                          <pre>
                            <code>{JSON.stringify(jso2, null, 2)}</code>
                          </pre>
                          <h4>More info</h4>
                          <p>
                            To get devices status, you can follow the flow chart
                            below:
                          </p>
                          <p>
                            <img
                              width="500"
                              src="https://www.protrack365.com/images/openapi/track-flow.png"
                              align="middle"
                              alt="track flow"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab9" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.3 Playback</h3>
                        </div>

                        <div id="API3" className="api-item api-article">
                          <h4>Purpose</h4>
                          <p>
                            Get the GPS data of the target in the time period.
                          </p>
                          <h4>Request type</h4>
                          <p>GET</p>
                          <h4>Parameters</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Parameter</th>
                                <th>Type</th>
                                <th>Required</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>access_token</td>
                                <td>string</td>
                                <td>true</td>
                                <td>The access token</td>
                              </tr>
                              <tr>
                                <td>imei</td>
                                <td>string</td>
                                <td>true</td>
                                <td>Target&apos;s IMEI</td>
                              </tr>
                              <tr>
                                <td>begintime</td>
                                <td>long</td>
                                <td>true</td>
                                <td>Begin time</td>
                              </tr>
                              <tr>
                                <td>endtime</td>
                                <td>long</td>
                                <td>true</td>
                                <td>End time</td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>URI</h4>
                          <p>/api/playback</p>
                          <h4>Example</h4>
                          <p>
                            https://varjogps.com/api/playback?access_token=
                            {`ACCESS_TOKEN`}
                            &imei=358899051025339&begintime=1406746394&endtime=1407746394
                          </p>
                          <h4>Response description</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>JSON KEY</th>
                                <th>Value type</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>record</td>
                                <td>String</td>
                                <td>
                                  Data is separated by semicolon，the data in
                                  each group is:
                                  <br />
                                  longitude,latitude,gpstime,speed,course
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>Response JSON demo</h4>
                          <pre>
                            <code>{JSON.stringify(jso3, null, 2)}</code>
                          </pre>
                          <h4>Remark</h4>
                          <p>
                            This api max return 1000 GPS records at one time,
                            when the client get 1000 records, the client has to
                            request next 1000 records, in this request ,the
                            begin time is the last 1000th record’s gpstime,
                            until client get the records less than 1000. When
                            you get the records less than 1000, it means you’ve
                            got all the GPS records.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab10" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.4 Create Geofence</h3>
                        </div>

                        <div id="API4" className="api-item api-article">
                          <h4>Purpose</h4>
                          <p>Create a circle geofence.</p>
                          <h4>Request type</h4>
                          <p>POST</p>
                          <h4>Parameters</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Parameter</th>
                                <th>Type</th>
                                <th>Required</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>access_token</td>
                                <td>string</td>
                                <td>true</td>
                                <td>The access token</td>
                              </tr>
                              <tr>
                                <td>imei</td>
                                <td>string</td>
                                <td>true</td>
                                <td>Target&apos;s IMEI</td>
                              </tr>
                              <tr>
                                <td>efencename</td>
                                <td>string</td>
                                <td>true</td>
                                <td>The name of Geofence</td>
                              </tr>
                              <tr>
                                <td>alarmtype</td>
                                <td>int</td>
                                <td>true</td>
                                <td>Alarm type(0:out,1:in,2:in/out)</td>
                              </tr>
                              <tr>
                                <td>longitude</td>
                                <td>double</td>
                                <td>true</td>
                                <td>The longitude of the geofence center</td>
                              </tr>
                              <tr>
                                <td>latitude</td>
                                <td>double</td>
                                <td>true</td>
                                <td>The latitude of the geofence center</td>
                              </tr>
                              <tr>
                                <td>radius</td>
                                <td>int</td>
                                <td>true</td>
                                <td>Geofence radius(100~5000,unit:meter)</td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>URI</h4>
                          <p>/api/geofence/create</p>
                          <h4>Example</h4>
                          <p>
                            https://varjogps.com/api/geofence/create?access_token=
                            {`ACCESS_TOKEN`}
                            &amp;imei=358899051025339&amp;efencename=home&amp;alarmtype=0&amp;longitude=113.97196&amp;latitude=22.56861&amp;radius=300
                          </p>
                          <h4>Response JSON demo</h4>
                          <pre>
                            <code>{JSON.stringify(jso4)}</code>{" "}
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab11" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.5 Send command</h3>
                        </div>
                        <div id="API5" className="api-item api-article">
                          <h4>How to send a command?</h4>
                          <p>
                            First call the send command api(2.5) to get command
                            id. then call the query command status api(2.6) in
                            loop until the command status is 1(Respond), max try
                            30 times, and then we consider the command is
                            timeout.
                          </p>

                          <h4>Purpose</h4>
                          <p>send a command to device from server.</p>
                          <h4>Request type</h4>
                          <p>POST</p>
                          <h4>Parameters</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Parameter</th>
                                <th>Type</th>
                                <th>Required</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>access_token</td>
                                <td>string</td>
                                <td>true</td>
                                <td>The access token</td>
                              </tr>
                              <tr>
                                <td>imei</td>
                                <td>string</td>
                                <td>true</td>
                                <td>Target&apos;s IMEI</td>
                              </tr>
                              <tr>
                                <td>command</td>
                                <td>string</td>
                                <td>true</td>
                                <td>
                                  <p>The command</p>
                                  <p>RELAY,1: Stop Engine</p>
                                  <p>RELAY,0: Restore Engine</p>
                                  <p>RESET: Reset device</p>
                                  <p>LOCKDOOR : Lock Door</p>
                                  <p>UNLOCKDOOR : Unlock Door</p>
                                  <p>SET_MILEAGE : Set Mileage</p>
                                </td>
                              </tr>
                              <tr>
                                <td>paramData</td>
                                <td>string</td>
                                <td>false</td>
                                <td>
                                  <p>
                                    When you choose to set mileage, you must to
                                    fill in paramData
                                  </p>
                                  <p>example: {`"mileage":"30"`}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>URI</h4>
                          <p>/api/command/send</p>
                          <h4>Example</h4>
                          <p>
                            https://varjogps.com/api/command/send?access_token=
                            {`ACCESS_TOKEN`}
                            &amp;imei=358899051025339&amp;command=RELAY,1
                          </p>
                          <h4>Response description</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th colSpan="2">JSON KEY</th>
                                <th>Value type</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan="2">record</td>
                                <td>Object</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>commandid</td>
                                <td>string</td>
                                <td>The id to query command response.</td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>Response JSON demo</h4>

                          <pre>
                            <code>{JSON.stringify(jso5, null, 2)}</code>
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab12" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.6 Query command response</h3>
                        </div>

                        <div id="API6" className="api-item api-article">
                          <h4>Purpose</h4>
                          <p>query the command response.</p>
                          <h4>Request type</h4>
                          <p>POST</p>
                          <h4>Parameters</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Parameter</th>
                                <th>Type</th>
                                <th>Required</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>access_token</td>
                                <td>string</td>
                                <td>true</td>
                                <td>The access token</td>
                              </tr>
                              <tr>
                                <td>commandid</td>
                                <td>string</td>
                                <td>true</td>
                                <td>The id returned from send command</td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>URI</h4>
                          <p>/api/command/query</p>
                          <h4>Example</h4>
                          <p>
                            https://varjogps.com/api/command/query?access_token=
                            {`ACCESS_TOKEN`}&amp;commandid=1B454
                          </p>
                          <h4>Response description</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th colSpan="2">JSON KEY</th>
                                <th>Value type</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan="2">record</td>
                                <td>Object</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>response</td>
                                <td>string</td>
                                <td>The command response</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>commandstatus</td>
                                <td>int</td>
                                <td>
                                  <p>Command status</p>
                                  <p>1: has received response from device</p>
                                  <p>0: not receive response from device</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>Response JSON demo</h4>
                          <pre>
                            <code>{JSON.stringify(jso6, null, 2)}</code>
                          </pre>
                          <h4>Remark</h4>
                          <p>
                            This api query once every 1 second, up to 30
                            seconds.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab13" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.7 Get device list</h3>
                        </div>

                        <div id="API7" className="api-item api-article">
                          <h4>Purpose</h4>
                          <p>
                            Get device list. (Return maximum 500pcs devices for
                            each account.)
                          </p>
                          <h4>Request type</h4>
                          <p>GET</p>
                          <h4>Parameters</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Parameter</th>
                                <th>Type</th>
                                <th>Required</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>access_token</td>
                                <td>string</td>
                                <td>true</td>
                                <td>The access token</td>
                              </tr>
                              <tr>
                                <td>account</td>
                                <td>string</td>
                                <td>false</td>
                                <td>Account</td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>URI</h4>
                          <p>/api/device/list</p>
                          <h4>Example</h4>
                          <p>
                            https://varjogps.com/api/device/list?access_token=
                            {`ACCESS_TOKEN`}&amp;account=demo
                          </p>
                          <h4>Response description</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th colSpan="2">JSON KEY</th>
                                <th>Value type</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan="2">record</td>
                                <td>Object</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>imei</td>
                                <td>string</td>
                                <td>Target&apos;s IMEI</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>devicename</td>
                                <td>String</td>
                                <td>Device name</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>devicetype</td>
                                <td>String</td>
                                <td>Device model</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>platenumber</td>
                                <td>String</td>
                                <td>Licence plate number</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>onlinetime</td>
                                <td>long</td>
                                <td>Device first online time</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>platformduetime</td>
                                <td>long</td>
                                <td>Device platform expire time</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>simcard</td>
                                <td>String</td>
                                <td>SIM card</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>activatedtime</td>
                                <td>long</td>
                                <td>Device activated time</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>userduetime</td>
                                <td>long</td>
                                <td>Device user expire time</td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>Response JSON demo</h4>
                          <pre>
                            <code>{JSON.stringify(jso7, null, 2)}</code>
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab14" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.8 Get device alarm information</h3>
                        </div>

                        <div id="API8" className="api-item api-article">
                          <h4>Purpose</h4>
                          <p>
                            Get the device(s) alarm information in the time
                            period.
                          </p>
                          <h4>Request type</h4>
                          <p>GET</p>
                          <h4>Parameters</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Parameter</th>
                                <th>Type</th>
                                <th>Required</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>access_token</td>
                                <td>string</td>
                                <td>true</td>
                                <td>The access token</td>
                              </tr>
                              <tr>
                                <td>imei</td>
                                <td>string</td>
                                <td>true</td>
                                <td>Target IMEI</td>
                              </tr>
                              <tr>
                                <td>begintime</td>
                                <td>long</td>
                                <td>true</td>
                                <td>Begin time</td>
                              </tr>
                              <tr>
                                <td>endtime</td>
                                <td>long</td>
                                <td>true</td>
                                <td>End time</td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>URI</h4>
                          <p>/api/alarm/list</p>
                          <h4>Example</h4>
                          <p>
                            https://varjogps.com/api/alarm/list?access_token=
                            {`ACCESS_TOKEN`}
                            &amp;imei=358899051025339&amp;begintime=1406746394&amp;endtime=1407746394
                          </p>
                          <h4>Response description</h4>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th colSpan="2">
                                  JSON KEY
                                </th>
                                <th >Value type</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan="2">record</td>
                                <td>String</td>
                                <td>
                                  Data is separated by semicolon, the data in
                                  each group is: alarm type, longitude,
                                  latitude, gpstime, system time,speed,course,
                                  Geo-fence ID
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <h4>Response JSON demo</h4>
                          <pre>
                            <code>{JSON.stringify(jso8, null, 2)}</code>
                          </pre>
                          <div className="bs-callout bs-callout-warning">
                            <h4>Note:</h4>
                            <p>
                              This API max return{" "}
                              <pre><code className="inline">100</code></pre> alarm records
                              at one time, when the client get{" "}
                              <pre><code className="inline">100</code></pre> records, the
                              client has to request next{" "}
                              <pre><code className="inline">100</code></pre> records, in
                              this request ,the begin time is the last{" "}
                              <pre><code className="inline">100th</code></pre>{" "}
                              record&apos;s system time, until client get the
                              records less than{" "}
                              <pre><code className="inline">100</code></pre>. When you get
                              the records less than{" "}
                              <pre><code className="inline">100</code></pre>, it means
                              you&apos;ve got all the alarm records.
                            </p>
                            <table className="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th>Alarm type</th>
                                  <th>Description</th>
                                  <th>Remark</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>sosAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>lowBatteryAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>powerDisconnectAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>vibrationAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>5</td>
                                  <td>geoFenceInAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>geoFenceOutAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>7</td>
                                  <td>speedingAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>8</td>
                                  <td>carMoveAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>9</td>
                                  <td>engineOn</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>10</td>
                                  <td>engineOff</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>11</td>
                                  <td>tireTemperatureAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>12</td>
                                  <td>tireLeakPressureAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>13</td>
                                  <td>tireLowPressureAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>14</td>
                                  <td>tireHighPressureAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>15</td>
                                  <td>gpsDeadZoneInAlarm</td>
                                  <td></td>
                                </tr>

                                <tr>
                                  <td>16</td>
                                  <td>gpsDeadZoneOutAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>17</td>
                                  <td>obdDtcAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>18</td>
                                  <td>disassembleAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>19</td>
                                  <td>powerOffAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>20</td>
                                  <td>collisionAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>21</td>
                                  <td>dropAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>22</td>
                                  <td>routeAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>23</td>
                                  <td>rapidAccelerationAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>24</td>
                                  <td>rapidDecelerationAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>25</td>
                                  <td>sharpTurnAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>26</td>
                                  <td>dooropenAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>27</td>
                                  <td>doorcloseAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>28</td>
                                  <td>offlineAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>29</td>
                                  <td>engineIdleAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>30</td>
                                  <td>parkingAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>31</td>
                                  <td>oilLeakAlertAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>32</td>
                                  <td>oilTheftAlertAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>33</td>
                                  <td>jammingAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>34</td>
                                  <td>restrictedDrivingAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>35</td>
                                  <td>lowTemperatureAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>36</td>
                                  <td>highTemperatureAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>37</td>
                                  <td>dumpSwitchOnAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>38</td>
                                  <td>dumpSwitchOffAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>39</td>
                                  <td>armAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>40</td>
                                  <td>rolloverAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>41</td>
                                  <td>fatigueDrivingAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>42</td>
                                  <td>lackBreakFatigueDrivingAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>43</td>
                                  <td>lowOilAlarm</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>44</td>
                                  <td>lowExternalBatteryAlarm</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}


{activeTab === "tab15" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.9 Block an account</h3>
                        </div>

                        <div id="API9" className="api-item api-article">
                  
                    <h4>Purpose</h4>
                    <p>Block an account.</p>
                    <h4>Request type</h4>
                    <p>POST</p>
                    <h4>Parameters</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>access_token</td>
                            <td>string</td>
                            <td>true</td>
                            <td>The access token</td>
                        </tr>
                        <tr>
                            <td>account</td>
                            <td>string</td>
                            <td>true</td>
                            <td>Account</td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>URI</h4>
                    <p>/api/user/block</p>
                    <h4>Example</h4>
                    <p>https://varjogps.com/api/user/block?access_token={`ACCESS_TOKEN`}&amp;account=demo</p>
                    <h4>Response JSON demo</h4>
                    <pre>
      <code>{JSON.stringify(jso9, null, 2)}</code>
    </pre>
                </div>
                       
                      </div>
                    </div>
                  </div>
                )}


{activeTab === "tab16" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.10 Unblock an account</h3>
                        </div>

                        <div id="API10" className="api-item api-article">
                   
                    <h4>Purpose</h4>
                    <p>Unblock an account.</p>
                    <h4>Request type</h4>
                    <p>POST</p>
                    <h4>Parameters</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>access_token</td>
                            <td>string</td>
                            <td>true</td>
                            <td>The access token</td>
                        </tr>
                        <tr>
                            <td>account</td>
                            <td>string</td>
                            <td>true</td>
                            <td>Account</td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>URI</h4>
                    <p>/api/user/unblock</p>
                    <h4>Example</h4>
                    <p>https://varjogps.com/api/user/unblock?access_token={`ACCESS_TOKEN`}&amp;account=demo</p>
                    <h4>Response JSON demo</h4>
                    <pre>
      <code>{JSON.stringify(jso9, null, 2)}</code>
    </pre>
                </div>
                      </div>
                    </div>
                  </div>
                )}



{activeTab === "tab17" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.11 Get account information</h3>
                        </div>

                        <div className="api-item api-article">
                    
                    <h4>Purpose</h4>
                    <p>Get the account&apos;s brief information.</p>
                    <h4>Request type</h4>
                    <p>GET</p>
                    <h4>Parameters</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>access_token</td>
                            <td>string</td>
                            <td>true</td>
                            <td>The access token</td>
                        </tr>
                        <tr>
                            <td>account</td>
                            <td>string</td>
                            <td>true</td>
                            <td>Account</td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>URI</h4>
                    <p>/api/user/info</p>
                    <h4>Example</h4>
                    <p>https://varjogps.com/api/user/info?access_token={`ACCESS_TOKEN`}&amp;account=demo</p>
                    <h4>Response description</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th colSpan="2" >JSON KEY</th>
                            <th >Value type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="2">record</td>
                            <td>Object</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td rowSpan="8"></td>
                            <td>account</td>
                            <td>String</td>
                            <td>Account</td>
                        </tr>
                        <tr>
                            <td>username</td>
                            <td>String</td>
                            <td>User name</td>
                        </tr>
                        <tr>
                            <td>email</td>
                            <td>String</td>
                            <td>Email address</td>
                        </tr>
                        <tr>
                            <td>mobile</td>
                            <td>String</td>
                            <td>Mobile number</td>
                        </tr>
                        <tr>
                            <td>telephone</td>
                            <td>String</td>
                            <td>Telephone</td>
                        </tr>
                        <tr>
                            <td>createtime</td>
                            <td>long</td>
                            <td>Create time</td>
                        </tr>
                        <tr>
                            <td>roletype</td>
                            <td>int</td>
                            <td>1 Distributor, 2 End user, 3 Virtual account</td>
                        </tr>
                        <tr>
                            <td>blockstatus</td>
                            <td>int</td>
                            <td>
                                0:block<br/>
                                1:unblock
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>Response JSON demo</h4>
                    <pre>
      <code>{JSON.stringify(jso10, null, 2)}</code>
    </pre>
                </div>



                      </div>
                    </div>
                  </div>
                )}



{activeTab === "tab18" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.12 Get device basic information by IMEIs</h3>
                        </div>

                        <div>
                    
                    <h4>Purpose</h4>
                    <p>Get the device(s) basic information according to IMEIs. (Maximum 100pcs in one request.)</p>
                    <h4>Request type</h4>
                    <p>GET</p>
                    <h4>Parameters</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>access_token</td>
                            <td>string</td>
                            <td>true</td>
                            <td>The access token</td>
                        </tr>
                        <tr>
                            <td>imeis</td>
                            <td>string</td>
                            <td>true</td>
                            <td>Target IMEI list, separated by comma, max 100 IMEIs in one request.</td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>URI</h4>
                    <p>/api/device/detail</p>
                    <h4>Example</h4>
                    <p>https://varjogps.com/api/device/detail?access_token={`ACCESS_TOKEN`}&amp;imeis=358899051025339,355139000000234</p>
                    <h4>Response description</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th colSpan="2">JSON KEY</th>
                            <th>Value type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="2">record</td>
                            <td>Object</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>imei</td>
                            <td>string</td>
                            <td>Target&apos;s IMEI</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>devicename</td>
                            <td>String</td>
                            <td>Device name</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>devicetype</td>
                            <td>String</td>
                            <td>Device model</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>platenumber</td>
                            <td>String</td>
                            <td>Licence plate number</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>onlinetime</td>
                            <td>long</td>
                            <td>Device first online time</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>platformduetime</td>
                            <td>long</td>
                            <td>Device platform expire time</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>simcard</td>
                            <td>String</td>
                            <td>SIM card</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>activatedtime</td>
                            <td>long</td>
                            <td>Device activated time</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>userduetime</td>
                            <td>long</td>
                            <td>Device user expire time</td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>Response JSON demo</h4>
                    <pre>
                    <pre>
      <code>{JSON.stringify(jso11, null, 2)}</code>
    </pre>
    </pre>
                </div>


                      </div>
                    </div>
                  </div>
                )}


{activeTab === "tab19" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.13 Send message</h3>
                        </div>

                        <div>
                   
                    <h4>Purpose</h4>
                    <p>Send message to account.</p>
                    <h4>Request type</h4>
                    <p>POST</p>
                    <h4>Parameters</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>access_token</td>
                            <td>string</td>
                            <td>true</td>
                            <td>The access token</td>
                        </tr>
                        <tr>
                            <td>accounts</td>
                            <td>string</td>
                            <td>true</td>
                            <td>Account list, separated by comma, max 200 accounts in one request.</td>
                        </tr>
                        <tr>
                            <td>content</td>
                            <td>string</td>
                            <td>true</td>
                            <td>Max. of 300 characters.</td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>URI</h4>
                    <p>/api/message/send</p>
                    <h4>Example</h4>
                    <p>https://varjogps.com/api/message/send?access_token={`ACCESS_TOKEN`}&amp;accounts=test,name&amp;content=Hello</p>
                    <h4>Response JSON demo</h4>
                    <pre>
      <code>{JSON.stringify(jso9, null, 2)}</code>
    </pre>
                </div>


                      </div>
                    </div>
                  </div>
                )}



{activeTab === "tab20" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.14 Mileage report</h3>
                        </div>

                        <div>
                   
                    <h4>Purpose</h4>
                    <p>Get mileage report of devices in the time period.</p>
                    <h4>Request type</h4>
                    <p>GET</p>
                    <h4>Parameters</h4>

                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>access_token</td>
                            <td>string</td>
                            <td>true</td>
                            <td>The access token</td>
                        </tr>
                        <tr>
                            <td>imeis</td>
                            <td>string</td>
                            <td>true</td>
                            <td>Target IMEI list, separated by comma, max 100 IMEIs in one request.
</td>
                        </tr>
                        <tr>
                            <td>begintime</td>
                            <td>long</td>
                            <td>true</td>
                            <td>Begin time. The minute of begintime can only be 0 or 30, for example  01:00, 02:30.</td>
                        </tr>
                        <tr>
                            <td>endtime</td>
                            <td>long</td>
                            <td>true</td>
                            <td>End time. The minute of endtime can only be 0 or 30, for example  01:00, 02:30.</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>
                      * The begintime is included ,endtime is not included.<br/>
                      * The maximum interval between begintime and endtime is 7 days.
                    </p>
                    <h4>URI</h4>
                    <p>/api/device/mileage</p>
                    <h4>Example</h4>
                    <p>https://varjogps.com/api/device/mileage?access_token={`ACCESS_TOKEN`}&amp;imeis=351000091413001,351000091413002,351000091413003,351000091413004&amp;begintime=1631721600&amp;endtime=1631808000</p>

                    <h4>Response Description</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th colSpan="2">JSON KEY</th>
                            <th>Value type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="2">record</td>
                            <td>Array</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>imei</td>
                            <td>string</td>
                            <td>Device imei</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>mileage</td>
                            <td>double</td>
                            <td>mileage of device, in km</td>
                        </tr>
                        </tbody>
                    </table>

                    <h4>Response JSON demo</h4>
                    <pre>
      <code>{JSON.stringify(jso12, null, 2)}</code>
    </pre>
                </div>

                      </div>
                    </div>
                  </div>
                )}



{activeTab === "tab21" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.15 Get sub-customer list</h3>
                        </div>

                        <div id="API15" className="api-item api-article">
                   
                    <h4>Purpose</h4>
                    <p>Get sub-customer list. (Return maximum 500pcs users.)</p>
                    <h4>Request type</h4>
                    <p>GET</p>
                    <h4>Parameters</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>access_token</td>
                            <td>string</td>
                            <td>true</td>
                            <td>The access token</td>
                        </tr>
                        <tr>
                            <td>account</td>
                            <td>string</td>
                            <td>false</td>
                            <td>Account</td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>URI</h4>
                    <p>/api/user/list</p>
                    <h4>Example</h4>
                    <p>https://varjogps.com/api/user/list?access_token={`ACCESS_TOKEN`}&amp;account=demo</p>
                    <h4>Response description</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th colSpan="2" >JSON KEY</th>
                            <th >Value type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="2">record</td>
                            <td>Object</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td rowSpan="8"></td>
                            <td>account</td>
                            <td>String</td>
                            <td>Account</td>
                        </tr>
                        <tr>
                            <td>username</td>
                            <td>String</td>
                            <td>User name</td>
                        </tr>
                        <tr>
                            <td>email</td>
                            <td>String</td>
                            <td>Email address</td>
                        </tr>
                        <tr>
                            <td>mobile</td>
                            <td>String</td>
                            <td>Mobile number</td>
                        </tr>
                        <tr>
                            <td>telephone</td>
                            <td>String</td>
                            <td>Telephone</td>
                        </tr>
                        <tr>
                            <td>createtime</td>
                            <td>long</td>
                            <td>Create time</td>
                        </tr>
                        <tr>
                            <td>roletype</td>
                            <td>int</td>
                            <td>1 Distributor, 2 End user, 3 Virtual account</td>
                        </tr>
                        <tr>
                            <td>blockstatus</td>
                            <td>int</td>
                            <td>
                                0:block<br/>
                                1:unblock
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>Response JSON demo</h4>
                    <pre>
      <code>{JSON.stringify(jso13, null, 2)}</code>
    </pre>
                </div>

                      </div>
                    </div>
                  </div>
                )}





{activeTab === "tab22" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>2.16 Modify sub-customer password</h3>
                        </div>

                        <div>
                 
                    <h4>Purpose</h4>
                    <p>Modify sub-customer password. </p>
                    <h4>Request type</h4>
                    <p>POST</p>
                    <h4>Parameters</h4>
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>access_token</td>
                            <td>string</td>
                            <td>true</td>
                            <td>The access token</td>
                        </tr>
                        <tr>
                            <td>account</td>
                            <td>string</td>
                            <td>true</td>
                            <td>Account</td>
                        </tr>
                        <tr>
                            <td>password</td>
                            <td>string</td>
                            <td>true</td>
                            <td>the algorithm is:md5(password),md5 use 32 lower-case characters.</td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>URI</h4>
                    <p>/api/user/password/set</p>
                    <h4>Example</h4>
                    <p>If  the new password is Abc@34590, then md5(Abc@34590) will calculate out the parameter password=9d591136280b3ec563e3062dbb6f915b</p>
                    <p>https://varjogps.com/api/user/password/set?access_token={`ACCESS_TOKEN`}&amp;account=monitorcenter&amp;password=9d591136280b3ec563e3062dbb6f915b</p>
                    <h4>Response JSON demo</h4>
                    <pre>
      <code>{JSON.stringify(jso9, null, 2)}</code>
    </pre>
                </div>
                      </div>
                    </div>
                  </div>
                )}



{activeTab === "tab23" && (
                  <div className="tab-pane fade show active api-info">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>
3. Error code
</h3>
                        </div>

                        
                        <div>
              
                <table className="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Remark</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>10000</td>
                        <td>System error</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10001</td>
                        <td>Unknown request</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10002</td>
                        <td>Login timeout</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10003</td>
                        <td>Not login yet</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10004</td>
                        <td>Parameter error</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10005</td>
                        <td>Missing required parameter</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10006</td>
                        <td>The parameter is out of range</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10007</td>
                        <td>Permission denied</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10009</td>
                        <td>Request is too frequency</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10010</td>
                        <td>Access token doesn&apos;t exist</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10011</td>
                        <td>Access token is invalid</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10012</td>
                        <td>Access token is out of date</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10013</td>
                        <td>IMEI is not authorized</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10014</td>
                        <td>Request time error</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>10016</td>
                        <td>Account is blocked</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>20001</td>
                        <td>Account or password error</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>20005</td>
                        <td>Target doesn&apos;t exist.</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>20017</td>
                        <td>Device is offline.</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>20018</td>
                        <td>Send command fail.</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>20023</td>
                        <td>No Data.</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>20046</td>
                        <td>Target is expired.</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>20048</td>
                        <td>Unsupported command.</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>20089</td>
                        <td>Device is blocked.</td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
              </div>


                      </div>
                    </div>
                  </div>
                )}


              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default openApi
