import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects"

import { CHANGE_PASSWORD } from "./actionTypes"
import { userChangePasswordSuccess, userChangePasswordError } from "./actions"
import { logoutUser } from "../login/actions"

import { putChangePwd } from "../../../helpers/fakebackend_helper"

import { showToast } from "../../../utils/Common"

function* changePass({ payload: { user, history } }) {
  try {
    const response = yield call(putChangePwd, {
      ...user,
    })
    if (response) {
      yield put(userChangePasswordSuccess())
      showToast("Reset Password Successfully!!", true)
      yield delay(700)
      yield put(logoutUser(history))
    } else {
      yield put(userChangePasswordError())
      showToast("Something Want Wrong! Please Try Again.", false)
    }
  } catch (error) {
    yield put(userChangePasswordError())
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}

export function* watchUserPasswordChnage() {
  yield takeEvery(CHANGE_PASSWORD, changePass)
}

function* changePasswordSaga() {
  yield all([fork(watchUserPasswordChnage)])
}

export default changePasswordSaga
