import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import { Popover } from "antd"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import Tabs from "../../components/Dashboard/Tabs"
import Message from "../../components/Dashboard/Message"
import Supplier from "../../components/Dashboard/Supplier"
import DeviceTabs from "../../components/Dashboard/DeviceTabs"
import DeviceQuantityChart from "../../components/Business/DeviceQuantityChart"
import DeviceChart from "../../components/Business/DeviceChart"
import ModelChart from "../../components/Business/ModelChart"
import BillChart from "../../components/Business/BillChart"
import TopCities from "./TopCities"
import TreeRight from "../../pages/Business/TreeRight"



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const Dashboard = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])



  const Msg = (closeToast) => (
    <div className="toastalert">
      <h3>Lorem ipsum dolor</h3>
      <p>This function is a valid React component because it accepts a single</p>
    </div>
  )

  const notify = () => toast(<Msg />, {
    className: "trackalert trac-warning",
    position: "top-right",
    autoClose: false,
    // hideProgressBar: false,
    closeOnClick: true,
    // newestOnTop,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard</title>
        </MetaTags>
        <Container fluid>
          <Row className="mt-3">
            
            <Col xl="12">
              <Card className="mb-3">
                <CardBody className="d-flex align-items-center justify-content-between p-2">
                  <div className="page-title-box ps-3">
                    <h3 className="mb-0 font-size-16 d-flex align-items-center">
                      <i className="bx bx-user-plus me-2 font-size-24"></i>{" "}
                      Dashboard
                    </h3>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xl="4">
              <Card className="mini-stats-wid bg-dashboard mb-0">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="fw-medium text-white">Account</h5>
                      <h2 className="my-4 fs-1 text-center text-white">
                        <span className="me-2 text-decoration-none">users</span>
                        <b className="fs-1 fw-normal">
                          <span className="fs-1 text-decoration-underline">
                            12
                          </span>
                        </b>
                      </h2>
                    </div>
                  </div>
                  <div className="d-flex text-white">
                    <span className="me-5 text-center opacity-50">
                      {" "}
                      <i className="bx bx-user-plus font-size-24"></i>{" "}
                      <b className="fw-normal d-block">New</b>
                    </span>

                    <span className="me-5 text-center mt-1 opacity-50">
                      <i className=" bx bxs-pencil font-size-20 "></i>{" "}
                      <b className="fw-normal d-block">Edit</b>
                    </span>

                    <span className="text-center opacity-50">
                      <i className="bx bx-key me-2 font-size-24"></i>{" "}
                      <b className="fw-normal d-block">Password</b>
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="mini-stats-wid bg-dashboard2 mb-0">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="fw-medium text-white">Device quantity</h5>
                      <h2 className="my-4 fs-1 text-center text-white">
                        <span className="me-2 text-decoration-none">
                          Total{" "}
                        </span>
                        <b className="fs-1 fw-normal">
                          <span className="fs-1 text-decoration-underline">
                            45
                          </span>
                        </b>
                      </h2>
                    </div>
                  </div>
                  <div className="d-flex text-white">
                    <span className="me-5 text-center opacity-50">
                      {" "}
                      10
                      <b className="fw-normal d-block">Imported</b>
                    </span>

                    <span className="me-5 text-center mt-1 opacity-50">
                      2 / 2{" "}
                      <b className="fw-normal d-block">
                        Activated / <br />
                        Unactivated
                      </b>
                    </span>

                    <span className="me-5 text-center opacity-50">
                      0 <b className="fw-normal d-block">Recharged</b>
                    </span>

                    <span className="text-center opacity-50">
                      1 <b className="fw-normal d-block">Expired</b>
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="mini-stats-wid bg-dashboard3 mb-0">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="fw-medium text-white">Card balance</h5>
                    </div>
                  </div>
                  <div className="d-flex text-white mb-3 mt-3">
                    <span className="me-5 text-start  fs-3">
                      {" "}
                      <span className="fs-3 text-decoration-underline">10</span>
                      <b className="fw-normal d-block opacity-50"> New card </b>
                    </span>

                    <span className="me-5 text-start fs-3">
                      <span className="fs-3 text-decoration-underline">20</span>{" "}
                      <b className="fw-normal d-block opacity-50">
                        New card to lifelong
                      </b>
                    </span>
                  </div>

                  <div className="d-flex text-white">
                    <span className="me-5 text-start fs-3">
                      <span className="fs-3 text-decoration-underline">12</span>{" "}
                      <b className="fw-normal d-block opacity-50">
                        Annual card{" "}
                      </b>
                    </span>

                    <span className="text-start fs-3">
                      <span className="fs-3 text-decoration-underline">5</span>{" "}
                      <b className="fw-normal d-block opacity-50">
                        Lifelong card{" "}
                      </b>
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xl="6">
              <Tabs />
            </Col>
            <Col xl="3">
              <Message />
            </Col>

            <Col xl="3">
              <Supplier />
            </Col>
          </Row>

          {/* <Row className="mb-4">
            <Col xl="9">
              <DeviceTabs />
            </Col>
            <Col xl="3">
              <Supplier />
            </Col>
          </Row> */}

          {/* <Row className="mb-4">
            <Col xl="9">
              <Card className="mb-0">
                <CardBody className="p-0">
                  <CardTitle className="mb-3 p-4 homecardbody">Bill</CardTitle>
                  <BillChart />
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card className="mb-0">
                <CardBody className="p-0 pb-3">
                  <CardTitle className="mb-5 p-4 homecardbody">
                    Device quantity
                  </CardTitle>
                  <DeviceQuantityChart />
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          {/* <Row className="mb-4">
            <Col xl="9">
              <Card className="mb-0">
                <CardBody className="p-0">
                  <CardTitle className="mb-5 p-4 homecardbody">
                    Device analysis
                  </CardTitle>
                  <DeviceChart />
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card className="mb-0">
                <CardBody className="p-0 pb-3">
                  <CardTitle className="mb-5 p-4 homecardbody">Model</CardTitle>
                  <ModelChart />
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <Row>
            <Col lg="12"></Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
