/* eslint-disable */

import React, { Component, useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Spinner,
} from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import GeocodingAPI from "./GeocodingAPI"
import MapsJavaScriptAPI from "./MapsJavaScriptAPI"

const GoogleMapsApikey = () => {
  const [activeTab, setActiveTab] = useState("1")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="crypto-buy-sell-nav w-100">
        <Nav tabs className="nav-tabs-custom mb-3" role="tablist">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1")
              }}
            >
              Geocoding API
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggleTab("2")
              }}
            >
              Maps Javascript API
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent
          activeTab={activeTab}
          className="crypto-buy-sell-nav-content border-0"
        >
          <TabPane tabId="1" id="buy">
            <div className="ps-3 pb-3 pe-3">
              <GeocodingAPI />
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div className="ps-3 pb-3 pe-3">
              <MapsJavaScriptAPI />
            </div>
          </TabPane>
        </TabContent>
      </div>
    </React.Fragment>
  )
}

export default GoogleMapsApikey
