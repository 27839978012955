import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Popover } from "antd"
import PropTypes from "prop-types"
import "./UserDrop.css"
//Import Breadcrumb
import Breadcrumbs from "../Common/Breadcrumb"


const TreeRight = ({ userInfo, popUpHandler }) => {
  const popcontent = (
    <div className="popuserright">
      <ul>
        <li
          onClick={() => {
            popUpHandler("Sale")
          }}
        >
          Sell
        </li>
        <li
          onClick={() => {
            popUpHandler("Add device")
          }}
        >
          Add device
        </li>
        <hr className="my-1" />
        <li
          onClick={() => {
            popUpHandler("Add user")
          }}
        >
          Add user
        </li>
        <li
          onClick={() => {
            popUpHandler("Information")
          }}
        >
          Information
        </li>
        <li
          onClick={() => {
            popUpHandler("Move account")
          }}
        >
          Move account
        </li>
        <li
          onClick={() => {
            popUpHandler("Send message")
          }}
        >
          Send message
        </li>
        <li
          onClick={() => {
            popUpHandler("Delete User")
          }}
        >
          Delete User
        </li>
        <hr className="my-1" />
        <li
          onClick={() => {
            popUpHandler("Reset password")
          }}
        >
          Reset password
        </li>
        <li
          onClick={() => {
            popUpHandler("Modify password")
          }}
        >
          Modify password
        </li>
        <hr className="my-1" />
        <li
          onClick={() => {
            popUpHandler("Refresh")
          }}
        >
          Refresh
        </li>
      </ul>
    </div>
  )

  return (
    <React.Fragment>
      <Popover content={popcontent} placement="right">
        <span type="primary" className="dotteduser">
          {" "}
          <i className="bx bx-dots-vertical-rounded"></i>
        </span>
      </Popover>
    </React.Fragment>
  )
}
TreeRight.propTypes = {
  userInfo: PropTypes.object,
  popUpHandler: PropTypes.func
}
export default TreeRight
