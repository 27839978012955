export const ADD_MESSAGE = "ADD_MESSAGE"
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS"
export const ADD_MESSAGE_ERROR = "ADD_MESSAGE_ERROR"

export const GET_SENT_MESSAGE = "GET_SENT_MESSAGE"
export const GET_SENT_MESSAGE_SUCCESS = "GET_SENT_MESSAGE_SUCCESS"
export const GET_SENT_MESSAGE_ERROR = "GET_SENT_MESSAGE_ERROR"

export const GET_RECEIVED_MESSAGE = "GET_RECEIVED_MESSAGE"
export const GET_RECEIVED_MESSAGE_SUCCESS = "GET_RECEIVED_MESSAGE_SUCCESS"
export const GET_RECEIVED_MESSAGE_ERROR = "GET_RECEIVED_MESSAGE_ERROR"

export const DELETE_MESSAGE = "DELETE_MESSAGE"
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS"
export const DELETE_MESSAGE_ERROR = "DELETE_MESSAGE_ERROR"

export const PUT_MESSAGE = "PUT_MESSAGE"
export const PUT_MESSAGE_SUCCESS = "PUT_MESSAGE_SUCCESS"
export const PUT_MESSAGE_ERROR = "PUT_MESSAGE_ERROR"
