import { TreeSelect } from "antd"
import React, { useState } from "react"
import TreeRight from "./TreeRight"
import PropTypes from "prop-types"
import axios from "axios"
import { PopoverBody, DropdownItem, Button, Modal } from "reactstrap"
import usicons1 from "../../assets/images/user.svg"
import usicons2 from "../../assets/images/user2.svg"
import usicons3 from "../../assets/images/user3.svg"
import usicons4 from "../../assets/images/user4.svg"
//Popup operations

import Sale from "../../components/Business/Sale"
import DeviceInfo from "../../components/Business/DeviceInfo1"
import AddUsersMain from "../../pages/Shared/AddUsersMain"
import AddDevice from "../../pages/Shared/addDevice"
import Profile from "../../components/Profile/Profile"
import Information from "../../components/Info/Information"
import MoveDevice from "../../pages/Shared/MoveAccount"
import SendMessage from "../../pages/Shared/MoveAccount"

const TreeMenuSelect = props => {
  const { handleCallBack, selectedValue, TreeHeight } = props
  const [value, setValue] = useState()
  const userDetails = JSON.parse(localStorage.getItem("authUser"))
  const [treeLine, setTreeLine] = useState(true)
  const [showLeafIcon, setShowLeafIcon] = useState(false)

  //popup operations

  const [commonData, setCommonData] = useState(false)
  const [commonPopUpOpen, setCommonPopUpOpen] = useState(false)
  const [selectedNode, setSelectedNode] = useState({})


  //functions for model popup
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const popUpHandler = popUpId => {
    setCommonPopUpOpen(!commonPopUpOpen)
    setCommonData(popUpId)
    removeBodyCss()
  }
  const handlePopUpClose = (status) => {
    setCommonPopUpOpen(status)
    handleCallBack("refresh", selectedNode)

  }

  const [treeData, setTreeData] = useState([
    {
      id: userDetails.memberId,
      pId: 0,
      value: userDetails.memberId,
      title: (
        <>
          <div>{userDetails.member.name}</div>
          <div className="ripos">
            <TreeRight userInfo={{}} popUpHandler={popUpHandler} />
          </div>
        </>
      ),
      icon: <img src={usicons1} />,
    },
  ])

  const onChange = (value, label, extra) => { }

  const onSelect = (value, node, extra) => {
    handleCallBack("baseValue", node)
    setSelectedNode(node)
  }

  const genTreeNode = async parentId => {
    const random = Math.random().toString(36).substring(2, 6)
    const axconfig = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/Member/MenuTree/SubUser/${parentId}`,
      headers: {
        accept: "/",
        Authorization: `Bearer ${userDetails.accessToken}`,
      },
    }

    const response = await axios(axconfig)

    response.data.rows.forEach(userDetails => {
      let leafstatus
      delete userDetails.key
      if (userDetails.childMemberCount === "0") {
        leafstatus = true
      } else {
        leafstatus = false
      }

      if (userDetails.role === "end_user") {
        userDetails.icon = <img src={usicons1} />
      } else if (userDetails.role === "distributor") {
        userDetails.icon = <img src={usicons1} />
      } else if (userDetails.role === "virtual") {
        userDetails.icon = <img src={usicons2} />
      }
      userDetails.id = userDetails.id
      userDetails.pId = parentId
      userDetails.value = `${userDetails.id}`
      userDetails.title = (
        <>
          <div>{userDetails.name}</div>
          <div className="ripos">
            <TreeRight userInfo={{}} popUpHandler={popUpHandler} />
          </div>
        </>
      )
      userDetails.isLeaf = leafstatus
    })

    return response.data.rows
  }

  const onLoadData = ({ id }) =>
    new Promise(resolve => {
      setTimeout(async () => {
        setTreeData(treeData.concat(await genTreeNode(id)))
        resolve(undefined)
      }, 300)
    })


  return (
    <>
      <TreeSelect
        style={{ width: "100%" }}
        treeDataSimpleMode
        placeholder="Search"
        dropdownStyle={{
          maxHeight: 100,
          height: TreeHeight - 160,
          overflow: "auto",
          position: "relative",
        }}
        onSelect={onSelect}
        onChange={onChange}
        loadData={onLoadData}
        treeData={treeData}
        // status="warning"
        allowClear
        defaultOpen={true}
        bordered={false}
        treeIcon={true}
        defaultValue="Select"
        getPopupContainer={trigger => trigger.parentNode}
        open={true}
        virtual={false}
        showSearch={true}
        showIcon={true}
        treeLine={
          treeLine && {
            showLeafIcon,
          }
        }
        treeNodeFilterProp="name"
        // showLeafIcon={false}
        treeDefaultExpandAll={true}
        popupClassName="gloabtree"
        filterTreeNode={(search, item) => {
          return item.children.toLowerCase().indexOf(search.toLowerCase()) >= 0
        }}
      />

      <Modal
        size="lg"
        isOpen={commonPopUpOpen}
        toggle={() => {
          popUpHandler("none")
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{commonData}</h5>
          <button
            type="button"
            onClick={() => {
              setCommonPopUpOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        {commonData === "Sale" && (
          <>
            <div className="modal-body">
              <Sale
                userInfo={selectedNode}
                updatePopupClose={handlePopUpClose}
              />
            </div>
          </>
        )}
        {commonData === "Add device" && (
          <>
            <div className="modal-body">
              <AddDevice
                userInfo={selectedNode}
                updatePopupClose={handlePopUpClose}
              />
            </div>
          </>
        )}
        {commonData === "Add user" && (
          <>
            <div className="modal-body">
              <AddUsersMain
                userInfo={selectedNode}
                updatePopupClose={handlePopUpClose}
              />
            </div>
          </>
        )}

        {commonData === "Information" && (
          <div className="modal-body">
            <Information
              userDetail={selectedNode}
              updatePopupClose={handlePopUpClose}
            />
          </div>
        )}
        {commonData === "Move account" && (
          <MoveDevice
            userInfo={selectedNode}
            updatePopupClose={handlePopUpClose}
          />
        )}
        {commonData === "Send message" && (
          <SendMessage
            userInfo={selectedNode}
            updatePopupClose={handlePopUpClose}
          />
        )}
        {commonData === "Delete User" && <Sale userInfo={selectedNode} />}
        {commonData === "Reset password" && <Sale userInfo={selectedNode} />}
        {commonData === "Modify password" && <Sale userInfo={selectedNode} />}
        {commonData === "Refresh" && <Sale userInfo={selectedNode} />}
      </Modal>
    </>
  )
}

TreeMenuSelect.propTypes = {
  handleCallBack: PropTypes.func,
  selectedValue: PropTypes.string,
  TreeHeight: PropTypes.number,
}

export default TreeMenuSelect
