import {
  GET_TREE_MENU_DATAILS,
  GET_TREE_MENU_DATAILS_FAIL,
  GET_TREE_MENU_DATAILS_SUCCESS,
  GET_TREE_MENU_INITIAL,
  GET_TREE_MENU_INITIAL_FAIL,
  GET_TREE_MENU_INITIAL_SUCCESS,
  GET_SUB_USERS,
  GET_SUB_USERS_FAIL,
  GET_SUB_USERS_SUCCESS,
  DELETE_USERS,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_ERROR,
} from "./actionTypes"

export const getTreeMunu = parentId => ({
  type: GET_TREE_MENU_DATAILS,
  parentId,
})

export const getTreeMunuSuccess = users => ({
  type: GET_TREE_MENU_DATAILS_SUCCESS,
  payload: users,
})
export const getTreeMunuFail = error => ({
  type: GET_TREE_MENU_DATAILS_FAIL,
  payload: error,
})

export const getTreeMunuInitialValue = initialValue => ({
  type: GET_TREE_MENU_INITIAL,
  payload: initialValue,
})
export const getTreeMunuInitialValueFail = error => ({
  type: GET_TREE_MENU_INITIAL_FAIL,
  payload: error,
})
export const getTreeMunuInitialValueSuccess = initialValue => ({
  type: GET_TREE_MENU_INITIAL_SUCCESS,
  payload: initialValue,
})

export const getSubUsers = payload => ({
  type: GET_SUB_USERS,
  payload,
})

export const getSubUsersSuccess = users => ({
  type: GET_SUB_USERS_SUCCESS,
  payload: users,
})
export const getSubUsersFail = error => ({
  type: GET_SUB_USERS_FAIL,
  payload: error,
})

export const deleteUser = user => {
  return {
    type: DELETE_USERS,
    payload: user,
  }
}

export const deleteUserSuccess = () => {
  return {
    type: DELETE_USERS_SUCCESS,
  }
}

export const deleteUserError = () => {
  return {
    type: DELETE_USERS_ERROR,
  }
}
