/* eslint-disable */

import React, { Component, useState, useEffect } from "react"
// import React, { Component }  from 'react';
import MetaTags from "react-meta-tags"

import { connect } from "react-redux"

import { bindActionToPromise } from "../../utils/Common"

import TreeSelect from "../../components/user/commonPopUpTree"

import * as yup from "yup"
import { addDevicedefault } from "../../store/actions"
import { getCardCountDefault } from "../../store/actions"

import Select from "react-select"

import {
  Input,
  Container,
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  FormFeedback,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import LoadingButton from "components/Common/Button"



const AddDevice = ({ userInfo, updatePopupClose, addDevicedefaultAction }) => {
  const INITIAL_VALUE = {
    memberId: userInfo?.id,
    model: "GT800",
    remark: "",
    imei: [],
    isLifelong: false,
    deviceType: "1",
  }
  const dispatch = useDispatch()
  const { loading } = useSelector(state => {
    return {
      loading: state.AddDevice.loading,
    }
  })

  const [deviceData, setDeviceData] = useState(INITIAL_VALUE)
  const [error, setError] = useState({})

  const optionsModel = [
    { value: "GT800", label: "GT800" },
    { value: "GT300", label: "GT300" },
    { value: "GT06N", label: "GT06N" },
    { value: "VT08S", label: "VT08S" },
    { value: "A18", label: "A18" },
    { value: "V5", label: "V5" },
    { value: "FMB 125", label: "FMB 125" },
    { value: "FMB920", label: "FMB920" },
    { value: "Wetrack 2", label: "Wetrack 2" },
  ]

  const onChangeInput = (key, value) => {
    if (key === "imei") {
      const updateArray = value ? value.split("\n") : []
      setDeviceData(previousUser => ({ ...previousUser, imei: updateArray }))
    } else {
      setDeviceData(previousUser => ({ ...previousUser, [key]: value }))
    }
  }
  const handleSetData = childData => {
    onChangeInput("memberId", childData)
  }

  const { getCardsCountDataOBject } = useSelector(state => {
    return {
      getCardsCountDataOBject: state.GetCardsDataReducer.cardsCount,
    }
  })

  const deviceFormScheme = yup.object().shape({
    memberId: yup.string().required("Target user is required"),
    imei: yup
      .array()
      .min(1, "IMEI/ID is required")
      .required("IMEI/ID is required"),
    model: yup.string().required("Model is required"),
  })

  useEffect(() => {
    dispatch(getCardCountDefault())
  }, [])

  const submitForm = async () => {
    const errObj = {}
    await deviceFormScheme
      .validate(deviceData, { abortEarly: false })
      .catch(err => {
        err.inner.forEach(x => {
          if (x.path !== undefined) {
            errObj[x.path] = x.errors?.join("")
          }
        })
      })
    setError(errObj)

    if (errObj && Object.keys(errObj).length === 0) {
      const payload = {
        memberId: deviceData?.memberId,
        model: deviceData?.model,
        remark: deviceData?.remark,
        imei: deviceData?.imei,
        isLifelong: deviceData?.deviceType === "2" ? true : false,
        deviceType: deviceData?.deviceType,
      }
      addDevicedefaultAction({ payload }).then(() => {
        setDeviceData(INITIAL_VALUE)
        updatePopupClose(false)
      })
    }
  }
  return (
    <React.Fragment>
      <>
        <Row>
          <div className="col-md-12">
            <Card>
              <CardBody className="d-flex align-items-center justify-content-between px-3 py-4">
                <div className="crypto-buy-sell-nav w-100">
                  <div className="row">
                    <div className="col-md-12">
                      <Alert color="warning" role="alert" className="d-flex">
                        <i className="bx bx-info-circle me-2 fs-5"></i> Please
                        DOUBLE-CHECK the validity of IMEI, or it will WASTE your
                        new card.
                      </Alert>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="bg-card p-3 mb-4">
                            <div className="mb-4">
                              <h3 className="fs-5 mb-3">Card balance</h3>
                            </div>
                            <div className="carddetails d-flex align-items-center justify-content-center">
                              {getCardsCountDataOBject &&
                                getCardsCountDataOBject.length > 0 && (
                                  <>
                                    <h3 className="me-5 ">
                                      <b className="text-danger fs-5">
                                        {getCardsCountDataOBject[0]
                                          ?.newCardCount || 0}
                                      </b>
                                      <br /> New card
                                    </h3>
                                    <h3 className="me-5 ">
                                      <b className="text-danger fs-5">
                                        {getCardsCountDataOBject[0]
                                          ?.annualCardsCount || 0}
                                      </b>
                                      <br /> Annual card
                                    </h3>
                                    <h3>
                                      <b className="text-danger fs-5">
                                        {getCardsCountDataOBject[0]
                                          ?.lifeLongCardsCount || 0}
                                      </b>
                                      <br /> Lifelong card
                                    </h3>
                                  </>
                                )}
                            </div>
                          </div>
                          <Row>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-email-Input">
                                  Target user <b>*</b>
                                </Label>
                                <TreeSelect
                                  parentCallback={handleSetData}
                                  selectedValue={userInfo}
                                />
                                {error?.memberId ? (
                                  <FormFeedback
                                    type="invalid"
                                    style={{ display: "block" }}
                                  >
                                    {error?.memberId}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-email-Input">
                                  Model<b>*</b>
                                </Label>
                                <Select
                                  classNamePrefix="select2-selection"
                                  placeholder="Please Select"
                                  title="model"
                                  name="model"
                                  // menuPlacement="top"
                                  isClearable={false}
                                  options={optionsModel}
                                  isSearchable={false}
                                  onChange={selectedOption =>
                                    onChangeInput(
                                      "model",
                                      selectedOption?.value
                                    )
                                  }
                                  value={optionsModel.filter(option => {
                                    if (option.value == deviceData.model)
                                      return option
                                  })}
                                />
                                {error?.model ? (
                                  <FormFeedback
                                    type="invalid"
                                    style={{ display: "block" }}
                                  >
                                    {error?.model}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl={12} sm={12}>
                              <Label htmlFor="formrow-email-Input">
                                Type<b>*</b>
                              </Label>
                              <div className="mt-0 d-flex">
                                <div className="form-check mb-3 me-3">
                                  <Input
                                    type="radio"
                                    value="1"
                                    id="type1"
                                    name="deviceType"
                                    className="form-check-input"
                                    onClick={e =>
                                      onChangeInput(
                                        "deviceType",
                                        e.target.value
                                      )
                                    }
                                    checked={deviceData.deviceType === "1"}
                                  />
                                  <Label
                                    className="form-check-label font-size-13 w-99"
                                    htmlFor="type1"
                                  >
                                    New Card
                                  </Label>
                                </div>
                                <div className="form-check mb-3 me-3">
                                  <Input
                                    type="radio"
                                    value="2"
                                    id="type2"
                                    name="deviceType"
                                    className="form-check-input"
                                    onClick={e =>
                                      onChangeInput(
                                        "deviceType",
                                        e.target.value
                                      )
                                    }
                                    checked={deviceData.deviceType === "2"}
                                  />
                                  <Label
                                    className="form-check-label font-size-13 w-99"
                                    htmlFor="type2"
                                  >
                                    Lifelong Card
                                  </Label>
                                </div>
                                <div className="form-check mb-3 me-3">
                                  <Input
                                    type="radio"
                                    value="3"
                                    id="type3"
                                    name="deviceType"
                                    className="form-check-input"
                                    onClick={e =>
                                      onChangeInput(
                                        "deviceType",
                                        e.target.value
                                      )
                                    }
                                    checked={deviceData.deviceType === "3"}
                                  />
                                  <Label
                                    className="form-check-label font-size-13 w-99"
                                    htmlFor="type3"
                                  >
                                    Yearly Card
                                  </Label>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12} className="mb-3">
                              <div className="mb-3">
                                <Label htmlFor="formrow-email-Input">
                                  IMEI/ID <b>*</b>
                                </Label>
                                <Input
                                  type="textarea"
                                  placeholder="Enter Imei"
                                  value={
                                    deviceData.imei
                                      ? deviceData.imei.join("\n")
                                      : ""
                                  }
                                  onChange={e =>
                                    onChangeInput("imei", e.target.value)
                                  }
                                  rows={5}
                                />
                                {error?.imei ? (
                                  <FormFeedback
                                    type="invalid"
                                    style={{ display: "block" }}
                                  >
                                    {error?.imei}
                                  </FormFeedback>
                                ) : null}
                                <span className="form-text">
                                  One batch for 500 IMEI at most.
                                </span>
                              </div>
                            </Col>

                            <Col md={12}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-email-Input">
                                  Remark
                                </Label>
                                <Input
                                  type="textarea"
                                  placeholder="Enter Remark"
                                  value={deviceData.remark}
                                  onChange={e =>
                                    onChangeInput("remark", e.target.value)
                                  }
                                  rows={5}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="pb-3">
                            <LoadingButton
                              color="primary"
                              className="btn btn-primary btn-right"
                              loading={loading}
                              disabled={loading}
                              block={false}
                              onClick={() => submitForm()}
                            >
                              {" "}
                              Submit
                            </LoadingButton>
                            <Button
                              type="reset"
                              color="secondary"
                              className="btnwid"
                              outline
                              onClick={() => setDeviceData(INITIAL_VALUE)}
                            >
                              Clear
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  addDevicedefaultAction: bindActionToPromise(dispatch, addDevicedefault),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDevice)
