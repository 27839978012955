/* eslint-disable */

import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"
import CheckPopup from "./CheckPopup"

function ModifyPlatformExpire() {
  const [popoverright, setpopoverright] = useState(false);

  return (
    <div>
      <div className="modal-body">
      
        <Row className="mb-0">
        <div className="col-md-6">
        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="engineoff"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="engineoff"
                          >
                            Engine OFF
                          </label>
                        </div>
          </div>
          <div className="col-md-6">
        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="engineon"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="engineon"
                          >
                            Engine On
                          </label>
                        </div>
          </div>
        </Row>

        <Row className="mb-0">
        <div className="col-md-6">
        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="dopen"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="dopen"
                          >
                           Door Open
                          </label>
                        </div>
          </div>
          <div className="col-md-6">
        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="dclose"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="dclose"
                          >
                            Door Close
                          </label>
                        </div>
          </div>
        </Row>

        <Row className="mb-0">
        <div className="col-md-6">
        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="oilleakalert"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="oilleakalert"
                          >
                           Oil leak alert
                          </label>
                        </div>
          </div>
          <div className="col-md-6">
        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="refuelingalert"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="refuelingalert"
                          >
                            Refueling alert
                          </label>
                        </div>
          </div>
        </Row>


        <Row className="mb-3">
        <div className="col-md-12 d-flex align-items-center">
        <div className="col-auto form-check mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="speedingalert"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="speedingalert"
                          >
                            Speeding alert
                          </label>
                        </div>
                        <div className="col-auto input-group w-150x ms-3">
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="20"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                KPH
                              </span>
                            </div>
                            </div>
          </div>
        </Row>

        <Row className="mb-0">
        <div className="col-md-6">
        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="oilleakalert"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="oilleakalert"
                          >
                            Offline alert
                          </label>
                        </div>
          </div>
        </Row>

        <Row className="mb-3">
        <div className="col-md-12 d-flex align-items-center">
        <div className="col-auto form-check mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="engineidel"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="engineidel"
                          >
                            Engine IDLE
                          </label>
                        </div>
                        <div className="col-auto input-group w-150x ms-3">
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="20"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                min
                              </span>
                            </div>
                            </div>
          </div>
        </Row>

        <Row className="mb-3">
        <div className="col-md-12 d-flex align-items-center">
        <div className="col-auto form-check mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="parkingalert"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="parkingalert"
                          >
                             Parking alert
                          </label>
                        </div>
                        <div className="col-auto input-group w-150x ms-3">
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="0"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                                min
                              </span>
                            </div>
                            </div>
          </div>
        </Row>

        <Row className="mb-3">
        <div className="col-md-12 d-flex align-items-center">
        <div className="col-auto form-check mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="ltempl"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="ltempl"
                          >
                              Low temperature alert
                          </label>
                        </div>
                        <div className="col-auto input-group w-150x ms-3">
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="0"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                               °C
                              </span>
                            </div>
                            </div>
          </div>
        </Row>

        <Row className="mb-3">
        <div className="col-md-12 d-flex align-items-center">
        <div className="col-auto form-check mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="htempl"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="htempl"
                          >
                               High temperature alert
                          </label>
                        </div>
                        <div className="col-auto input-group w-150x ms-3">
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="0"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                               °C
                              </span>
                            </div>
                            </div>
          </div>
        </Row>

        <Row className="mb-3">
        <div className="col-md-12 d-flex align-items-center">
        <div className="col-auto form-check mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="htempl"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="htempl"
                          >
                                Restricted driving alert
                          </label>
                        </div>
                        <div className="col-auto input-group w-150x ms-3">
                        <input
                        className="form-control height32"
                        type="number"
                        defaultValue="0"
                      />
                      <div className="input-group-prepend">
                              <span
                                className="input-group-text height32"
                                id="validationTooltipUsernamePrepend"
                              >
                               °C
                              </span>
                            </div>
                            </div>
          </div>
        </Row>


     
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary ">
          Save
        </button>
        
      </div>
    </div>
  )
}

export default ModifyPlatformExpire
