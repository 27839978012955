/* USERS */
export const CHECK_IME_INITIAL = "CHECK_IME_INITIAL"
export const CHECK_IME_FAIL = "CHECK_IME_FAIL"
export const CHECK_IME_SUCCESS = "CHECK_IME_SUCCESS"

//Sale

export const POST_SALE_INITIAL = "POST_SALE_INITIAL"
export const POST_SALE_FAIL = "POST_SALE_FAIL"
export const POST_SALE_SUCCESS = "POST_SALE_SUCCESS"

//Move Account 
export const POST_MOVE_ACCOUNT = "POST_MOVE_ACCOUNT"
export const POST_MOVE_ACCOUNT_FAIL = "POST_MOVE_ACCOUNT_FAIL"
export const POST_MOVE_ACCOUNT_SUCCESS = "POST_MOVE_ACCOUNT_SUCCESS"
