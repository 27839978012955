import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

import LoadingButton from "../../components/Common/Button"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser } from "../../store/actions"

const Login = props => {
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "admin" || "",
      password: "qwertyuiop" || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history))
    },
  })

  const { error, loading } = useSelector(state => {
    return {
      error: state.Login.error,
      loading: state.Login.loading,
    }
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Varjo GPS Tracking Device</title>
      </MetaTags>

      <div className="account-pages">
        <Container fluid>
          <Row className="justify-content-end">
            <Col md={8} lg={3} xl={3} className="mrlogin">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft loginbg">
                  <Row>
                    <Col xs={12}>
                      <div className="text-primary p-4 headinglogin">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Varjo GPS Tracking Device.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 mt-4 mb-3">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">User Name</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter User Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <LoadingButton
                          color="primary"
                          className="btn btn-primary submitbtn "
                          loading={loading}
                          disabled={loading}
                          block={false}
                          type="submit"
                        >
                          Log In
                        </LoadingButton>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer-copyrights">
        <div className="container">
        <div className="row align-items-center">
        <div className="col-md-6">
        <span className="text-white">Copyright © 2024 Varjo GPS. All rights reserved.</span>
          </div>
          <div className="col-md-6 text-end">
       

        <Link className="open-api-cla" to="/openapi">Open API</Link>
          </div>
          </div>
        </div>
       
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
