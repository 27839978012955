import React from "react"
import { Button, Spinner } from "reactstrap"
import classnames from "classnames"
import PropTypes from "prop-types"

const LoadingButton = ({
  children = "save",
  loading = false,
  block = false,
  ...rest
}) => {
  return (
    <Button {...rest} block={block}>
      {loading && (
        <>
          <Spinner
            className={classnames({
              "position-relative": true,
              "button-style": !block,
              visible: loading,
              invisible: !loading,
            })}
            size="sm"
            // type="grow"
          />{" "}
        </>
      )}
      <span
        className={classnames({
          "span-style": !block,
        })}
      >
        {children}
      </span>
    </Button>
  )
}

LoadingButton.propTypes = {
  children: PropTypes.string,
  loading: PropTypes.bool,
  block: PropTypes.bool,
}

export default LoadingButton
