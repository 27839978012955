import {
  ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_ERROR,
  GET_SENT_MESSAGE,
  GET_SENT_MESSAGE_SUCCESS,
  GET_SENT_MESSAGE_ERROR,
  GET_RECEIVED_MESSAGE,
  GET_RECEIVED_MESSAGE_SUCCESS,
  GET_RECEIVED_MESSAGE_ERROR,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_ERROR,
  PUT_MESSAGE,
  PUT_MESSAGE_SUCCESS,
  PUT_MESSAGE_ERROR,
} from "./actionTypes"

export const addMessage = payload => {
  return {
    type: ADD_MESSAGE,
    payload: payload,
  }
}

export const addMessageSuccess = () => {
  return {
    type: ADD_MESSAGE_SUCCESS,
  }
}

export const addMessageError = () => {
  return {
    type: ADD_MESSAGE_ERROR,
  }
}

export const getSentMessages = payload => ({
  type: GET_SENT_MESSAGE,
  payload,
})

export const getSentMessagesSuccess = messages => ({
  type: GET_SENT_MESSAGE_SUCCESS,
  payload: messages,
})

export const getSentMessagesFail = error => ({
  type: GET_SENT_MESSAGE_ERROR,
  payload: error,
})

export const getReceivedMessages = payload => ({
  type: GET_RECEIVED_MESSAGE,
  payload,
})

export const getReceivedMessagesSuccess = messages => ({
  type: GET_RECEIVED_MESSAGE_SUCCESS,
  payload: messages,
})

export const getReceivedMessagesFail = error => ({
  type: GET_RECEIVED_MESSAGE_ERROR,
  payload: error,
})

export const deleteMessage = payload => ({
  type: DELETE_MESSAGE,
  payload,
})

export const deleteMessageSuccess = messages => ({
  type: DELETE_MESSAGE_SUCCESS,
  payload: messages,
})

export const deleteMessageFail = error => ({
  type: DELETE_MESSAGE_ERROR,
  payload: error,
})

export const putMessage = payload => ({
  type: PUT_MESSAGE,
  payload,
})

export const putMessageSuccess = messages => ({
  type: PUT_MESSAGE_SUCCESS,
  payload: messages,
})

export const putMessageFail = error => ({
  type: PUT_MESSAGE_ERROR,
  payload: error,
})
