import L, { MarkerCluster } from "leaflet"
let iconComponents = {}
iconComponents["avatar"] =
  require("../assets/images/users/avatar-1.jpg").default
iconComponents["playback"] =
  require("../assets/images/icons/playback.svg").default
iconComponents["track"] = require("../assets/images/icons/track.svg").default
iconComponents["geoFence"] =
  require("../assets/images/icons/geo-fence.svg").default
iconComponents["street"] = require("../assets/images/icons/street.svg").default
iconComponents["details"] =
  require("../assets/images/icons/details.svg").default

const iconColorList = ["red", "green", "orange", "blue", "grey"]
const iconVehicleList = ["car"]
//Blue icons
const pointValue = new L.Point(35, 42)

for (let i = 0; i < iconVehicleList.length; i++) {
  for (let j = 0; j < iconColorList.length; j++) {
    iconComponents[`${iconVehicleList[i] + iconColorList[j]}bottom`] =
      new L.Icon({
        iconUrl:
          require(`../assets/images/icons/vehicle/${iconVehicleList[i]}/${iconColorList[j]}/bottom.png`)
            .default,
        iconSize: pointValue,
      })

    iconComponents[`${iconVehicleList[i] + iconColorList[j]}top`] = new L.Icon({
      iconUrl:
        require(`../assets/images/icons/vehicle/${iconVehicleList[i]}/${iconColorList[j]}/top.png`)
          .default,
      iconSize: pointValue,
    })
    iconComponents[`${iconVehicleList[i] + iconColorList[j]}right`] =
      new L.Icon({
        iconUrl:
          require(`../assets/images/icons/vehicle/${iconVehicleList[i]}/${iconColorList[j]}/right.png`)
            .default,
        iconSize: pointValue,
      })
    iconComponents[`${iconVehicleList[i] + iconColorList[j]}left`] = new L.Icon(
      {
        iconUrl:
          require(`../assets/images/icons/vehicle/${iconVehicleList[i]}/${iconColorList[j]}/left.png`)
            .default,
        iconSize: pointValue,
      }
    )
  }
}

iconComponents["createClusterCustomIcon"] = function (cluster: MarkerCluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: L.point(33, 33, true),
  })
}

export default iconComponents
