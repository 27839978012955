/* eslint-disable */

import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import PlayBackRight from "../../components/Monitor/PlayBack/PlayBackRight"
import { useParams, NavLink } from "react-router-dom"
import Navigation from "../../components/Monitor/NavigationMenu"
import PlayBackMap from "../../components/Monitor/PlayBack/playBackMap"
//Import Breadcrumb
import "./MonitorStyle.css"
import MonitorBottomBar from "../../components/Monitor/MonitorBottom"
import PlayBackTop from "../../components/Monitor/PlayBack/PlayBackTop"
import PlayBackTabs from "../../components/Monitor/PlayBack/PlayBackTabs"
import { useSelector, useDispatch } from "react-redux"
import { showToast } from "../../utils/Common"
import {
  getPlayBackDetails,
  getPlayBackHistoryDetails,
} from "../../store/actions"
let cursor = 0
const PlayBack = () => {
  const { memberId, deviceId } = useParams()
  const filterVal = { moveValue: 1, parkingValue: 2, speedVal: 2000 }
  const dispatch = useDispatch()
  const [fromDateParent, setFromDateParent] = useState()
  const [toDateParent, setToDateParent] = useState()
  const [deviceIdParent, setDeviceIdParent] = useState()
  const [playButtonStatus, setPlayButtonStatus] = useState(false)
  const [filterData, setFilterData] = useState(filterVal)

  //Optitions Value
  const [alert, setAlert] = useState(false)
  const [startEndMarker, setStartEndMarker] = useState(false)
  const [parkingMarker, setParkingMarker] = useState(false)
  const [infoWindow, setInfoWindow] = useState(false)
  const [showPoints, setShowPoints] = useState(false)
  const [stopAndPlay, setStopAndPlay] = useState(false)
  const [accessedFetching, setAccessedFetching] = useState(false)

  //Map component states
  const [locationData, setLocationData] = useState([])
  const [currentTrack, setCurrentTrack] = useState({})
  const [endPlayBackPopUp, setEndPlayBackPopUp] = useState(false)
  const [playPauseStop, setPlayPauseStop] = useState(true)
  const [showToastPop, setShowToastPop] = useState(false)
  const [endLogicForButton, setEndLogicForButton] = useState(false)
  const intervalRef = useRef(null)
  //TODO TOP Component
  //Getting initial list of device for select box in top component based on member Id
  useEffect(() => {
    dispatch(getPlayBackDetails(memberId))
  }, [])

  //Move All the logic here to pass multiple component

  //Data Fetching and manipulation
  console.log(cursor, "========cursor=================")
  //Get Playback history Data Based on Search
  const getAddData = async data => {
    setEndLogicForButton(false)
    setFilterData(data)
    if (data.isValue) {
      setFromDateParent(data.fromDate)
      setToDateParent(data.toDate)
      setDeviceIdParent(data.deviceId)
      setShowToastPop(true)
      dispatch(
        getPlayBackHistoryDetails({
          fromDate: data.fromDate,
          toDate: data.toDate,
          deviceId: data.deviceId,
        })
      )
      setFilterData({
        ...filterVal,
        [key]: value,
      })
    }
  }

  //Filter Logic

  //Update the filter Value to pass other component
  const updateFilterValue = (key, value) => {
    console.log(key, value, "updateFilterValue")
    setFilterData({
      ...filterVal,
      [key]: value,
    })
  }

  //deviceDetails for show in chile components

  //ALl Device information Based on Current User
  const { deviceDetails, deviceLoading, deviceError } = useSelector(state => {
    return {
      deviceDetails: state.PlayBackDetails.deviceDetails,
      deviceLoading: state.MonitorDetails.isLoading,
      deviceError: state.MonitorDetails.error,
    }
  })

  //Button CHange Logic and pause /Play/Stop Logic
  const getPlayBackEnd = data => {
    setPlayButtonStatus(data)
  }

  const changeButtonHandler = data => {
    setStopAndPlay(data)
  }
  const stopAndPlayHandler = data => {
    setPlayPauseStop(data)
  }

  const resetCurser = data => {
    cursor = data
  }

  const changeAccessFetchingHandler = data => {
    setAccessedFetching(data)
  }

  //accessedFetching

  //Manage Right side Options logic below

  const handleOptions = optionName => {
    console.log("updating")
    if (optionName === "alert") setAlert(!alert)
    if (optionName === "startEndMarker") setStartEndMarker(!startEndMarker)
    if (optionName === "parkingMarker") setParkingMarker(!parkingMarker)
    if (optionName === "infoWindow") setInfoWindow(!infoWindow)
    if (optionName === "showPoints") setShowPoints(!showPoints)
  }

  //TODO :  Work on Map component
  const { playBackHistory, playBackHistoryLoading, playBackHistoryError } =
    useSelector(state => {
      return {
        playBackHistory: state.playBackHistoryDetails.playBackHistoryData,
        playBackHistoryLoading: state.playBackHistoryDetails.isLoading,
        playBackHistoryError: state.playBackHistoryDetails.error,
      }
    })

  useEffect(() => {
    const colors = []
    const intervalIds = []
    const allHistoryData = playBackHistory.map(locData => {
      return [parseFloat(locData.lat), parseFloat(locData.lng)]
    })
    //  const map = mapRef.current
    //  if (!map) return

    //map.target.flyTo([playBackHistory[0].lat, playBackHistory[0].lng], 17)

    setLocationData(prev => [...prev, ...allHistoryData])
    setCurrentTrack(playBackHistory[cursor])

    const intervalId = setInterval(() => {
      console.log(
        playPauseStop,
        cursor,
        playBackHistory.length,
        "===========playPauseStop"
      )

      if (playPauseStop) {
        if (cursor === playBackHistory.length - 1) {
          console.log("inside of the loop end ")
          cursor = playBackHistory.length
          showPopup()
          setEndLogicForButton(true)
          setCurrentTrack(playBackHistory[cursor])
          //changeButtonStatus()
          intervalIds.forEach(clearInterval)
          // clearInterval(intervalRef.current)
          return
        }
        cursor += 1

        setCurrentTrack(playBackHistory[cursor])
      }
    }, filterData.speedVal)

    intervalIds.push(intervalId)

    return () => {
      intervalIds.forEach(clearInterval)
      //clearInterval(intervalRef.current)
    }
  }, [playBackHistory, filterData, playPauseStop, endPlayBackPopUp])

  useEffect(() => {
    if (
      playBackHistory.length === 0 &&
      showToastPop &&
      !playBackHistoryLoading &&
      filterData != undefined
    ) {
      showToast("Data Not available", false)
      setShowToastPop(false)
    }
  }, [playBackHistory, showToastPop])

  useEffect(() => {
    if (playBackHistory.length === 0) return
    if (!endLogicForButton) {
      setStopAndPlay(true)
    } else {
      setStopAndPlay(false)
      setPlayPauseStop(true)
    }
  }, [playBackHistory, endLogicForButton])




  //Show endPopUp callback Function
  function showPopup() {
    setEndPlayBackPopUp(!endPlayBackPopUp)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  //todo : check why stop and play state is not working
  // todo : work on button status if there is no data

  return (
    <React.Fragment>
      <PlayBackTop
        playTopData={{
          deviceDetails, //For device Details popup
          playButtonStatus, // Managing the button and logic status
          getAddData, //Get Playback form value from top component and give to redux to fetch playback history
          updateFilterValue, //This is using for setup Playback filter change on time (speed etc..)
          PreviousData: { fromDateParent, toDateParent, deviceIdParent }, //this is used for Check if current search date same with previous date and if its same block the action and change button to stop
          changeButtonStatus: getPlayBackEnd, //Change the button status ---Check this logic
          defaultDevice: deviceId, //default device Id to preselect the seletbox value
          changeButtonHandler, //Stop and play Handler
          changeAccessFetchingHandler, //dont know for this
          accessedFetching,
          stopAndPlay,
          playPauseStop,
          stopAndPlayHandler,
          playBackHistory,
          resetCurser, //dont know for this
        }}
      />
      <PlayBackRight handleRightOption={handleOptions} />

      <PlayBackMap
        mapData={{
          playBackHistory,
          currentTrack,
          locationData,
          playBackHistoryLoading,
          filterData,
          endPopupHandler: showPopup,
          playPauseStop,
          stopAndPlayHandler,
          endPlayBackPopUp,
        }}
      />

      <PlayBackTabs
        playBarData={{
          playBackHistory,
          cursor,
          playBackHistoryLoading,
          filterData,
          stopAndPlay,
          playPauseStop,
          stopAndPlayHandler,
          resetCurser,
        }}
      />

      <div className="mapbottombar w-100">
        <MonitorBottomBar />
      </div>
    </React.Fragment>
  )
}

export default PlayBack
