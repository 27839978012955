import {
  ADD_DEVICE_GROUP,
  ADD_DEVICE_GROUP_SUCCESS,
  ADD_DEVICE_GROUP_ERROR,
  GET_DEVICE_GROUP,
  GET_DEVICE_GROUP_SUCCESS,
  GET_DEVICE_GROUP_ERROR,
  PUT_DEVICE_GROUP,
  PUT_DEVICE_GROUP_SUCCESS,
  PUT_DEVICE_GROUP_ERROR,
} from "./actionTypes"

const initialState = {
  addLoading: false,
  listLoading: false,
  putLoading: false,
  deviceGroups: [],
}

const deviceGroup = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEVICE_GROUP:
      state = {
        ...state,
        addLoading: true,
      }
      break
    case ADD_DEVICE_GROUP_SUCCESS:
      state = {
        ...state,
        addLoading: false,
      }
      break
    case ADD_DEVICE_GROUP_ERROR:
      state = {
        ...state,
        addLoading: false,
      }
      break
    case GET_DEVICE_GROUP:
      state = {
        ...state,
        listLoading: true,
      }
      break
    case GET_DEVICE_GROUP_SUCCESS:
      state = {
        ...state,
        deviceGroups: action.payload,
        listLoading: false,
      }
      break
    case GET_DEVICE_GROUP_ERROR:
      state = {
        ...state,
        listLoading: false,
      }
      break
    case PUT_DEVICE_GROUP:
      state = {
        ...state,
        putLoading: true,
      }
      break
    case PUT_DEVICE_GROUP_SUCCESS:
      state = {
        ...state,
        putLoading: false,
      }
      break
    case PUT_DEVICE_GROUP_ERROR:
      state = {
        ...state,
        putLoading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default deviceGroup
