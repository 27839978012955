/* eslint-disable */
import React from "react"
import { Row, Col } from "reactstrap"
import "./ReportStyle.css"
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

const ReportParkGrid = props => {
  const { paginationOptions, setPaginationOptions, dataList, count } = props

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
    },
    {
      dataField: "title",
      text: "Device Name",
      sort: true,
    },
    {
      dataField: "price",
      text: "Mileage(KM)",
      sort: true,
    },
    {
      dataField: "rating.rate",
      text: "Speeding(Times)",
      sort: true,
    },
    {
      dataField: "rating.count",
      text: "Parking(Times)",
      sort: true,
    },
  ]

  // Table Data
  const productData = [
    {
      id: 1,
      DeviceName: "GT06N-57085",
      Mileage: "15",
      Speeding: "20",
      parking: "12",
    },
    {
      id: 2,
      DeviceName: "Airi Satou",
      Mileage: "0",
      Speeding: "0",
      parking: "1",
    },
    {
      id: 3,
      DeviceName: "GT06N-57085",
      Mileage: "15",
      Speeding: "20",
      parking: "12",
    },
    {
      id: 4,
      DeviceName: "Airi Satou",
      Mileage: "0",
      Speeding: "0",
      parking: "1",
    },
    {
      id: 5,
      DeviceName: "GT06N-57085",
      Mileage: "15",
      Speeding: "20",
      parking: "12",
    },
    {
      id: 6,
      DeviceName: "Airi Satou",
      Mileage: "0",
      Speeding: "0",
      parking: "1",
    },
    {
      id: 7,
      DeviceName: "GT06N-57085",
      Mileage: "15",
      Speeding: "20",
      parking: "12",
    },
    {
      id: 8,
      DeviceName: "Airi Satou",
      Mileage: "0",
      Speeding: "0",
      parking: "1",
    },
    {
      id: 9,
      DeviceName: "GT06N-57085",
      Mileage: "15",
      Speeding: "20",
      parking: "12",
    },
    {
      id: 10,
      DeviceName: "Airi Satou",
      Mileage: "0",
      Speeding: "0",
      parking: "1",
    },
    {
      id: 11,
      DeviceName: "GT06N-57085",
      Mileage: "15",
      Speeding: "20",
      parking: "12",
    },
    {
      id: 12,
      DeviceName: "Airi Satou",
      Mileage: "0",
      Speeding: "0",
      parking: "1",
    },
    {
      id: 13,
      DeviceName: "GT06N-57085",
      Mileage: "15",
      Speeding: "20",
      parking: "12",
    },
    {
      id: 14,
      DeviceName: "Airi Satou",
      Mileage: "0",
      Speeding: "0",
      parking: "1",
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const onSizePerPageChange = sizePerPage => {
    setPaginationOptions({
      ...paginationOptions,
      sizePerPage: sizePerPage,
      page: 1,
    })
  }

  const onPageChange = (page, sizePerPage) => {
    setPaginationOptions({
      ...paginationOptions,
      page: page,
    })
  }

  const pageOptions = {
    sizePerPage: paginationOptions?.sizePerPage || 5,
    totalSize: count,
    custom: true,
    page: paginationOptions?.page || 1,
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={columns}
            data={dataList}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider keyField="id" columns={columns} data={dataList}>
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={
                              "table align-middle table-nowrap table-bordered"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone
                            {...paginationProps}
                            sizePerPageList={[5, 10, 15, 20]}
                            onSizePerPageChange={onSizePerPageChange}
                          />
                          <PaginationListStandalone
                            {...paginationProps}
                            onPageChange={onPageChange}
                          />
                        </div>
                        <div className="text-md-right ms-auto">
                          <div className="grid_down_item text-end pb-3">
                            <button className="btn btn-success btn-sm me-3 pt-1 pb-1 cursor-pointer">
                              Excel
                            </button>
                            <button className="btn btn-danger-2 text-white btn-sm me-3 pt-1 pb-1 cursor-pointer">
                              PDF
                            </button>
                            <button className="btn btn-info btn-sm pt-1 pb-1 cursor-pointer">
                              Print
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

ReportParkGrid.propTypes = {
  setPaginationOptions: PropTypes.func,
  paginationOptions: PropTypes.obj,
  dataList: PropTypes.Array,
  count: PropTypes.number,
}

export default ReportParkGrid
