import React from "react"

const LinkGeoFenceInfo = () => {
  return (
    <div>
      <div className="row gx-0">
        <div className="col-lg-4 border-end">
          <header className="p-2">
            <i className="bx bx-caret-down"></i>
            Geo-fence list
          </header>
          <div className="gf_col2 p-2">
            <div className="clos_input position-relative">
              <input className="form-control poi_head_input" type="text" />
              <span className="txt_clr d-flex align-items-center justify-content-center">
                X
              </span>
            </div>
          </div>
          <div className="gf_col3">
            <ul className="list_disc">
              <li className="active_gf">
                <div className="d-flex align-items-center">
                  <div className="flx_0 me-2">
                    <i className="bx bx-polygon"></i>
                  </div>
                  <div className="flx_0">test</div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="flx_0 me-2">
                    <i className="bx bx-polygon"></i>
                  </div>
                  <div className="flx_0">test</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-8">
          <header className="p-2">
            <i className="bx bx-caret-down"></i>
            Device List
          </header>
          <div className="gf_col2 p-2">
            <div className="clos_input position-relative">
              <input className="form-control poi_head_input" type="text" />
              <span className="txt_clr d-flex align-items-center justify-content-center">
                X
              </span>
            </div>
          </div>
          <div className="gf_table">
            <table className="table mb-0 table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th width="80">
                    <input type="checkbox" className="form-check-input me-1" id="" />
                    In
                  </th>
                  <th width="80">
                    <input type="checkbox" className="form-check-input me-1" id="" />
                    Out
                  </th>
                  <th width="150">
                    
                    <input
                      type="checkbox"
                      className="form-check-input me-1"
                      id=""
                    />
                    Speeding alert
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                      DEMO
                      </td>
                      <td><input type="checkbox" className="form-check-input" id="" /></td>
                      <td><input type="checkbox" className="form-check-input" id="" /></td>
                      <td><input type="checkbox" className="form-check-input" id="" /></td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="border-top p-3 text-end">
            <button className="btn btn-primary btn-sm me-2">Save</button>
            <button className="btn btn-outline-primary btn-sm">Cancel</button>
      </div>
    </div>
  )
}

export default LinkGeoFenceInfo
