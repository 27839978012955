/* eslint-disable */
import {
  GET_CARDS_DATA,
  GET_CARDS_DATA_FAIL,
  GET_CARDS_DATA_SUCCESS,
  ADD_CARD_DEFAULT,
  ADD_CARD_DEFAULT_FAIL,
  ADD_CARD_DEFAULT_SUCCESS,
  ADD_TRANSFER_CARD_DEFAULT,
  ADD_TRANSFER_CARD_FAIL,
  ADD_TRANSFER_CARD_SUCCESS,
  GET_DISTRIBUTOR_DATA_DEFAULT,
  GET_DISTRIBUTOR_DATA_FAIL,
  GET_DISTRIBUTOR_DATA_FAIL_SUCCESS,
  GET_CARD_COUNT_DATA_DEFAULT,
  GET_CARD_COUNT_DATA_FAIL,
  GET_CARD_COUNT_DATA_SUCCESS,
  GET_CARD_BALANCE_DATA_DEFAULT,
  GET_CARD_BALANCE_DATA_SUCCESS,
  GET_CARD_BALANCE_DATA_FAIL,
} from "./actionTypes"

export const getAllDistribitutor = () => ({
  type: GET_DISTRIBUTOR_DATA_DEFAULT,
})

export const getAllDistribitutorSuccess = cards => ({
  type: GET_DISTRIBUTOR_DATA_FAIL_SUCCESS,
  payload: cards,
})
export const getAllDistribitutorFail = error => ({
  type: GET_DISTRIBUTOR_DATA_FAIL,
  payload: error,
})

export const getCardMenu = () => ({
  type: GET_CARDS_DATA,
})

export const getCardMenuSuccess = cards => ({
  type: GET_CARDS_DATA_SUCCESS,
  payload: cards,
})
export const getCardMenuFail = error => ({
  type: GET_CARDS_DATA_FAIL,
  payload: error,
})

export const getCardBalanceAction = id => ({
  type: GET_CARD_BALANCE_DATA_DEFAULT,
  id,
})

export const getCardBalanceActionSuccess = cards => ({
  type: GET_CARD_BALANCE_DATA_SUCCESS,
  payload: cards,
})
export const getCardBalanceActionFail = error => ({
  type: GET_CARD_BALANCE_DATA_FAIL,
  payload: error,
})

export const getCardCountDefault = () => ({
  type: GET_CARD_COUNT_DATA_DEFAULT,
})

export const getCardCountASuccess = cards => ({
  type: GET_CARD_COUNT_DATA_SUCCESS,
  payload: cards,
})
export const getCardCountFail = error => ({
  type: GET_CARD_COUNT_DATA_FAIL,
  payload: error,
})

export const addCardDefault = payload => ({
  type: ADD_CARD_DEFAULT,
  payload,
})

export const addCardSuccess = message => ({
  type: ADD_CARD_DEFAULT_SUCCESS,
  payload: message,
})
export const addCardFail = error => ({
  type: ADD_CARD_DEFAULT_FAIL,
  payload: error,
})

export const addTransferCardDefault = payload => ({
  type: ADD_TRANSFER_CARD_DEFAULT,
  payload,
})

export const addTransferCardSuccess = message => ({
  type: ADD_TRANSFER_CARD_SUCCESS,
  payload: message,
})
export const addTransferCardFail = error => ({
  type: ADD_TRANSFER_CARD_FAIL,
  payload: error,
})
