/* eslint-disable */

import React, { useEffect, useState } from "react"
import "./TopBarMessageStyle.css"
import Tooltip from "@mui/material/Tooltip"
import TopMessgTab from "./TopMessgTab"

import PropTypes from "prop-types"

import { connect } from "react-redux"

import LoadingButton from "../../components/Common/Button"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  NavLink,
  InputGroup,
} from "reactstrap"

import TreeMultiSelecte from "../user/treeMenuSelectMultiple"

import { bindActionToPromise } from "../../utils/Common"

import { addMessage } from "../../store/actions"

import { useSelector } from "react-redux"

const SendMessagePopup = props => {
  const { modal_center, tog_center, selectedUserData } = props

  const [selectedUser, setSelectedUser] = useState(
    selectedUserData ? [selectedUserData?.id] : []
  )

  const [message, setMessage] = useState("")
  const [selectedUserErr, setSelectedUserErr] = useState("")
  const [messageErr, setMessageErr] = useState("")

  const { loading } = useSelector(state => {
    return {
      loading: state.Message.loading,
    }
  })

  function handleCallBack(value) {
    setSelectedUser(value)
  }

  useEffect(() => {
    if (selectedUserData?.id) {
      setSelectedUser([selectedUserData?.id])
    }
  }, [selectedUserData])

  const [type, setType] = useState("1")

  const [TopBarMessage, setTopBarMessage] = useState(false)
  function TopBarMessageBtn() {
    setTopBarMessage(!TopBarMessage)
  }

  function submitForm() {
    if (selectedUser.length < 1) setSelectedUserErr("Please Select User")

    if (message === "") setMessageErr("Please Enter Message")

    if (selectedUser.length >= 1 && message !== "") {
      setSelectedUserErr("")
      setMessageErr("")
      props
        .addMessageAction({
          message: message,
          receiverIds: selectedUser,
        })
        .then(() => {
          tog_center()
        })
    }
  }
  return (
    <div>
      <Modal
        isOpen={modal_center}
        size="lg"
        toggle={() => {
          tog_center()
        }}
        
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Send Message</h5>
          <button
            type="button"
            onClick={() => {
              tog_center()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="form-group p-4">
          <div className="mb-3 row align-items-center">
            <label className="col-md-2 text-end pt-2">
              To <small className="text-danger">*</small>
            </label>
            <div className="col-md-10">
              <TreeMultiSelecte
                handleCallBack={handleCallBack}
                selectedValue={selectedUser}
              />

              {selectedUserErr ? (
                <FormFeedback type="invalid" style={{ display: "block" }}>
                  {selectedUserErr}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="mb-3 row">
            <label className="col-md-2 text-end pt-2">
              Content <small className="text-danger">*</small>
            </label>
            <div className="col-md-10">
              <Input
                type="textarea"
                placeholder="Enter message"
                value={message}
                onChange={e => setMessage(e.target.value)}
                maxLength={300}
                rows={5}
              />

              {messageErr ? (
                <FormFeedback type="invalid" style={{ display: "block" }}>
                  {messageErr}
                </FormFeedback>
              ) : null}
              <span className="d-block text-end pt-1">{`${message.length} / 300`}</span>
            </div>
          </div>
          <div className="mb-3 row">
            <div className="col-md-10 offset-md-2">
              <LoadingButton
                color="primary"
                className="btn btn-primary btn-right"
                loading={loading}
                disabled={loading}
                block={false}
                onClick={() => submitForm()}
              >
                Submit
              </LoadingButton>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => tog_center()}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-md-2">
              <span className="pe-2">Message</span>
              <span
                className="pe-2 txt_link"
                onClick={() => {
                  setType("1")
                  TopBarMessageBtn()
                }}
              >
                Received
              </span>
              /
              <span
                onClick={() => {
                  setType("3")
                  TopBarMessageBtn()
                }}
                className="pe-2 ps-2 txt_link"
              >
                Sent
              </span>
            </div>
          </div>
        </div>

        <Modal
          isOpen={TopBarMessage}
          size="lg"
          toggle={() => {
            TopBarMessageBtn()
          }}
          
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Message</h5>
            <button
              type="button"
              onClick={() => {
                setTopBarMessage(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
          <TopMessgTab type={type} />
        </Modal>
      </Modal>
    </div>
  )
}

SendMessagePopup.propTypes = {
  modal_center: PropTypes.bool,
  tog_center: PropTypes.func,
  addMessageAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  addMessageAction: bindActionToPromise(dispatch, addMessage),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendMessagePopup)
