import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "202203",
    Total: 20,
    Expired: 18,
    amt: 4
  },
  {
    name: "202204",
    Total: 25,
    Expired: 16,
    amt: 6
  },
  {
    name: "202205",
    Total: 18,
    Expired: 12,
    amt: 9
  },
  
];

export default function SimpleLineChart() {
  return (
    <div style={{ width: '100%', height: 350 }}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Total"
            stroke="#68b5fc"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="Expired" stroke="#c23531" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
