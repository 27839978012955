/* eslint-disable */

import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import CheckPopup from "../../components/Business/CheckPopup"
import TreeSelect from "../../components/user/commonPopUpTree"
import { moveAccountDefault} from "../../store/actions"
import { connect, useDispatch, useSelector } from "react-redux"
import LoadingButton from "../../components/Common/Button"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { bindActionToPromise } from "utils/Common"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const MoveAccount = ({
  userInfo,
  moveAccountDefaultAction,
  updatePopupClose,
}) => {
  const dispatch = useDispatch()
  const [currentUser, setCurrentUser] = useState(userInfo?.id || "")
  const [targetUser, setTargetUser] = useState("")
  const [errorParentId, setErrorParentId] = useState("")
  const { isLoadingMoveAccount } = useSelector(state => {
    return {
      isLoadingMoveAccount: state.SharedDetails.moveAccountLoading,
    }
  })
  const handleSetData = childData => {
    setTargetUser(childData)
  }

  const handleSubmit = () => {
    if (targetUser === "") {
      setErrorParentId("Please Select Target User")
    }
    if (targetUser !== "") {
      const moveAccount = {
        targetUser,
        currentUser,
      }

        moveAccountDefaultAction(moveAccount)
          .then(() => {
            updatePopupClose(false)
            console.log("success")
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
  console.log(userInfo)
  return (
    <>
      <form>
        <div>
          <div className="modal-body">
            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-4 col-form-label text-start"
              >
                Target user
              </label>
              <div className="col-md-8">
                <span>{userInfo?.name}</span>
              </div>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-4 col-form-label text-start"
              >
                Target User <small className="text-danger">*</small>
              </label>
              <div className="col-md-8">
                <TreeSelect parentCallback={handleSetData} selectedValue={{}} />
                {errorParentId ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {errorParentId}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
          </div>
          <div className="modal-footer">
            <LoadingButton
              color="primary"
              className="btn btn-primary"
              loading={isLoadingMoveAccount}
              disabled={isLoadingMoveAccount}
              block={false}
              onClick={() => handleSubmit()}
            >
              Save changes
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  moveAccountDefaultAction: bindActionToPromise(dispatch, moveAccountDefault),
})

export default connect(mapStateToProps, mapDispatchToProps)(MoveAccount)
