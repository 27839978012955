import {
  GET_TRACK_INITIAL,
  GET_TRACK_DETAILS_FAIL,
  GET_TRACK_DETAILS_SUCCESS,
} from "./actionTypes"

export const getTrackDetails = deviceId => ({
  type: GET_TRACK_INITIAL,
  deviceId,
})

export const getTrackDetailsSuccess = trackDetails => ({
  type: GET_TRACK_DETAILS_SUCCESS,
  payload: trackDetails,
})
export const getTrackDetailsFail = error => ({
  type: GET_TRACK_DETAILS_FAIL,
  payload: error,
})
