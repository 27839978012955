/* eslint-disable */

import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Navigation from "../../components/Monitor/NavigationMenu"
import Maps from "../../components/Monitor/Maps"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MyDevice from "../../components/Monitor/Index"
import MonitorBottomBar from "../../components/Monitor/MonitorBottom"
import Tooltip from "@mui/material/Tooltip"
import Button from "@mui/material/Button"
import { NavLink } from "react-router-dom"
import lefticon1 from "../../assets/images/icons/measure.svg"
import lefticon2 from "../../assets/images/icons/measure-area.svg"
import lefticon3 from "../../assets/images/icons/traffic.svg"
import lefticon4 from "../../assets/images/icons/measure-layer.svg"
import lefticon5 from "../../assets/images/icons/zoomin.svg"
import lefticon6 from "../../assets/images/icons/zoomout.svg"

import lefticon9 from "../../assets/images/icons/fence.svg"


import lefticon12 from "../../assets/images/users/avatar-1.jpg"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"

import lefticon13 from "../../assets/images/icons/playback.svg"
import lefticon14 from "../../assets/images/icons/track.svg"
import lefticon15 from "../../assets/images/icons/command.svg"
import lefticon16 from "../../assets/images/icons/geo-fence.svg"
import lefticon17 from "../../assets/images/icons/share.svg"
import lefticon18 from "../../assets/images/icons/street.svg"
import lefticon19 from "../../assets/images/icons/details.svg"
import lefticon20 from "../../assets/images/icons/io.svg"

import MonitorBottomMap from "./MonitorBottomMap"
import MonitorBottomGeoFence from "./MonitorBottomGeoFence"


import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import "./MonitorBottomStyle.css"
import MonitorBottomRoute from "./MonitorBottomRoute"
import MonitorBottomPoi from "./MonitorBottomPoi"
import MonitorBottomAlert from "./MonitorBottomAlert"
import MonitorBottomTrace from "./MonitorBottomTrace"

const PagesStarter = ({ bottomBarData }) => {
  // popup
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  return (
    <React.Fragment>
      <div className="d-flex w-100 justify-content-between">
        <div className="leftmap align-items-center d-flex">
          <span>
            {" "}
            <Tooltip title="Measure Distance" placement="top" arrow>
              <Button variant="outlined" className="no-button">
                <img src={lefticon1} width={30} />
              </Button>
            </Tooltip>
          </span>
          <span>
            {" "}
            <Tooltip title="Measure Distance" placement="top" arrow>
              <Button variant="outlined" className="no-button">
                <img src={lefticon2} width={30} />
              </Button>
            </Tooltip>
          </span>
          <span>
            {" "}
            <Tooltip title="Measure area" placement="top" arrow>
              <Button variant="outlined" className="no-button">
                <img src={lefticon3} width={30} />
              </Button>
            </Tooltip>
          </span>
          <div>
            <MonitorBottomMap />
          </div>

          <div className="mapzomm">
            <Button variant="outlined" className="no-button2">
              <Tooltip title="Zoom Out" placement="top" arrow>
                <img src={lefticon5} width={25} />
              </Tooltip>
            </Button>
            <Box width={100}>
              <Slider
                defaultValue={10}
                aria-label="Small"
                marks
                min={1}
                max={18}
                valueLabelDisplay="auto"
              />
            </Box>
            <Button variant="outlined" className="no-button2">
              <Tooltip title="Zoom In" placement="top" arrow>
                <img src={lefticon6} width={25} />
              </Tooltip>
            </Button>
          </div>
          <div className="position-relative barsearch">
            <input
              type="text"
              className="form-control"
              placeholder="Search address"
            />
            <span className="bx bx-search-alt" />
          </div>
        </div>

        <div className="d-none">
          <span onClick={handleClick} className="mapclick">
            <i className="bx bxs-map"></i>
          </span>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            className="poperwidth"
          >
            <div className="row d-flex">
              <div className="leftpop col-md-8">
                <span>
                  <h5 className="text-info">HAJI BIKE(GT800)ss</h5>{" "}
                </span>
                <span>
                  <b>Status:</b> Static(3min7s)
                </span>
                <span>
                  <b>Device:</b> External power ON,Disarming
                </span>
                <span>
                  <b>Engine:</b> OFF(2min55s)
                </span>
                <span>
                  <b>IMEI:</b> 351510093076530
                </span>
                <span>
                  <b>Coordinate:</b>{" "}
                  <b className="text-info">13.080638,80.225254</b>
                </span>
              </div>
              <div className="rightpop col-md-4">
                <span
                  onClick={handleClose}
                  className="mb-2 d-block text-end closebtpop"
                >
                  {" "}
                  <i className="mdi mdi-close"></i>{" "}
                </span>
                <img src={lefticon12} width={100} />
              </div>
            </div>

            <div className="row d-flex">
              <div className="deviceinpop">
                <div className="popmapicons">
                  <span>
                    <NavLink
                      to=""
                      onClick={() =>
                        openInNewWindow(place.deviceIMEI, place.DeviceUUID)
                      }
                    >
                      <img alt="" src={lefticon13} width={21} />
                    </NavLink>
                  </span>
                  <span>
                    <NavLink
                      to=""
                      onClick={() =>
                        openInNewWindow(place.deviceIMEI, place.DeviceUUID)
                      }
                    >
                      <img alt="" src={lefticon14} width={21} />
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/apps/monitor/report/EngineOverview">
                      <img alt="" src={lefticon15} width={21} />
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/apps/monitor/report/EngineOverview">
                      <img alt="" src={lefticon16} width={21} />
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/apps/monitor/report/EngineOverview">
                      <img alt="" src={lefticon17} width={21} />
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/apps/monitor/report/EngineOverview">
                      <img alt="" src={lefticon18} width={21} />
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/apps/monitor/report/EngineOverview">
                      <img alt="" src={lefticon19} width={21} />
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/apps/monitor/report/EngineOverview">
                      <img alt="" src={lefticon20} width={30} />
                    </NavLink>
                  </span>
                </div>
              </div>
            </div>
          </Popover>
        </div>

        <div className="rightmap d-flex align-items-center jus-align-center">
          <div>
            <MonitorBottomPoi />
          </div>
          <div>
            <MonitorBottomRoute />
          </div>
          <div>
            <MonitorBottomGeoFence geoFenceData={bottomBarData} />
          </div>
          <div>
            <MonitorBottomAlert />
          </div>
          <div>
            <MonitorBottomTrace />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PagesStarter
