/* eslint-disable */

import React, { useState, useRef, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import CheckPopup from "./CheckPopup"
import TreeSelect from "../../components/user/treeMenuSelectBox"

import { checkImeiDefault } from "../../store/actions"
import { connect, useDispatch, useSelector } from "react-redux"
import LoadingButton from "../../components/Common/Button"
import { bindActionToPromise } from "utils/Common"
import { addToVirtualDevice } from "../../store/actions"

function AddToVirtual(props) {
  const { selectedUser = {}, selectedRows } = props

  const [popoverright, setpopoverright] = useState(false)

  const [imeiInput, setImeiInput] = useState([])
  const [parentID, setParentID] = useState("")
  const [errorParentId, setErrorParentId] = useState("")
  const [errorImei, setErrorImei] = useState("")

  const { checkImeiData, isLoadingCheckImei, isLoadingAddToVirtualDevice } =
    useSelector(state => {
      return {
        checkImeiData: state.AddDevice.imeiResData,
        isLoadingCheckImei: state.AddDevice.isLoadingCheckImei,
        isLoadingAddToVirtualDevice:
          state.AddDevice.isLoadingAddToVirtualDevice,
      }
    })

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0) {
      setImeiInput(selectedRows)
    }
  }, [])

  const updateImei = value => {
    setErrorImei("")
    const updateArray = value ? value.split("\n") : []
    setImeiInput(updateArray)
  }

  const handleSetData = childData => {
    setErrorParentId("")
    setParentID(childData)
  }

  const handleCheck = () => {
    const imeiData = {
      imei: imeiInput,
    }
    props.checkImeiDefaultAction(imeiData).then(() => {
      setpopoverright(true)
    })
  }

  const handleSubmit = () => {
    if (parentID === "") {
      setErrorParentId("Please Select Target User")
    }

    if (imeiInput.length <= 0) setErrorImei("Please Enter IMEI")

    if (parentID !== "" && imeiInput.length > 0) {
      const payloadData = {
        targetMemberId: parentID,
        imei: imeiInput,
      }

      if (payloadData) {
        props.addToVirtualDeviceAction(payloadData).then(() => {
          props.setmodal_center2(false)
        })
      }
    }
  }
  return (
    <div>
      <form>
        <div className="modal-body">
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label text-start"
            >
              Target user <small className="text-danger">*</small>
            </label>
            <div className="col-md-8">
              <TreeSelect
                parentCallback={handleSetData}
                selectedValue={parentID}
              />
              {errorParentId ? (
                <FormFeedback type="invalid" style={{ display: "block" }}>
                  {errorParentId}
                </FormFeedback>
              ) : null}
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label text-start"
            >
              IMEI <small className="text-danger">*</small>
            </label>
            <div className="col-md-8">
              <div className="row gx-2">
                <div className="col">
                  <Input
                    type="textarea"
                    placeholder="Enter Imei"
                    value={imeiInput ? imeiInput.join("\n") : ""}
                    onChange={e => updateImei(e.target.value)}
                    rows={5}
                  />
                  <span className="font-size-12">
                    One batch for 200 IMEI at most.
                  </span>
                </div>
                {errorImei ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {errorImei}
                  </FormFeedback>
                ) : null}
                <div className="col-auto">
                  <LoadingButton
                    id="Popoverright"
                    color="primary"
                    className="btn btn-primary btn-sm"
                    loading={isLoadingCheckImei}
                    disabled={isLoadingCheckImei || imeiInput.length <= 0}
                    block={false}
                    onClick={() => {
                      handleCheck()
                    }}
                  >
                    Check
                  </LoadingButton>
                  {checkImeiData && !isLoadingCheckImei && (
                    <Popover
                      placement="right"
                      isOpen={popoverright}
                      target="Popoverright"
                      toggle={() => {
                        setpopoverright(!popoverright)
                      }}
                      className="width350"
                    >
                      <PopoverBody>
                        <span
                          id="Popoverright"
                          onClick={() => {
                            setpopoverright(!popoverright)
                          }}
                          className="popoverclose"
                        >
                          <i className="mdi mdi-close"></i>
                        </span>
                        <CheckPopup checkImeiData={checkImeiData} />
                      </PopoverBody>
                    </Popover>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </div>
        <div className="modal-footer">
          <LoadingButton
            color="primary"
            className="btn btn-primary"
            loading={isLoadingAddToVirtualDevice}
            disabled={isLoadingAddToVirtualDevice}
            block={false}
            onClick={() => handleSubmit()}
          >
            Save changes
          </LoadingButton>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  checkImeiDefaultAction: bindActionToPromise(dispatch, checkImeiDefault),
  addToVirtualDeviceAction: bindActionToPromise(dispatch, addToVirtualDevice),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddToVirtual)
