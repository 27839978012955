/* eslint-disable */

import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePwd from "../pages/Authentication/ChangePassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import AddUsers from "../pages/AddUsers/Index"
import AddDevices from "../pages/AddDevices/Index"
import Business from "../pages/Business/Index"
import Monitor from "../pages/Monitor/Index"
//import TrackData from "../pages/Monitor/track/Index"
import Ui from "../pages/Ui/Modals"
import TransferCard from "../pages/Card/Index"
import ExchangeLifeCard from "../pages/Card/ExchangeLifeCard"
import CardBalance from "../pages/Card/CardBalance"
import CardHistory from "../pages/Card/CardHistory"
import NonAuthLayout from "../components/NonAuthLayout"
import CreateCard from "../pages/Card/CreateCard"

import PlayBack from "../pages/Monitor/PlayBack"
import Track from "../pages/Monitor/Track"

import { getLoggedInUser } from "../helpers/fakebackend_helper"
import MovingOverview from "pages/Report/MovingOverview"
import MileageReport from "pages/Report/MileageReport"
import SpeedingDetails from "pages/Report/SpeedingDetails"
import ParkingDetails from "pages/Report/ParkingDetails"
import TripReport from "pages/Report/TripReport"
import SpeedingTripReport from "pages/Report/SpeedingTripReport"
import DrivingBehaviorOverview from "pages/Report/DrivingBehaviorOverview"
import EngineOverview from "pages/Report/EngineOverview"
import EngineReport from "pages/Report/EngineReport"
import EngineIdleReport from "pages/Report/EngineIdleReport"
import AlertOverview from "pages/Report/AlertOverview"
import AlertReport from "pages/Report/AlertReport"
import AlertDetails from "pages/Report/AlertDetails"
import GeoFenceAlert from "pages/Report/GeoFenceAlert"
import RightUser from "../components/Business/RightUser"
import openApi from "pages/OpenApi/openApi"

const commonRoutesForAllUsers = [
  {
    path: "/monitor/playback/:memberId/:deviceId",
    component: PlayBack,
    NonAuthLayout,
  },
  { path: "/monitor/track/:deviceId", component: Track, NonAuthLayout },
  { path: "/monitor", component: Monitor, NonAuthLayout },
  { path: "/report/movingoverview", component: MovingOverview, NonAuthLayout },
  { path: "/report/mileagereport", component: MileageReport, NonAuthLayout },
  {
    path: "/report/speedingdetails",
    component: SpeedingDetails,
    NonAuthLayout,
  },
  { path: "/report/parkingdetails", component: ParkingDetails, NonAuthLayout },
  { path: "/report/tripreport", component: TripReport, NonAuthLayout },
  {
    path: "/report/speedtripreport",
    component: SpeedingTripReport,
    NonAuthLayout,
  },
  {
    path: "/report/drivingbehavior",
    component: DrivingBehaviorOverview,
    NonAuthLayout,
  },
  { path: "/report/engineoverview", component: EngineOverview, NonAuthLayout },
  { path: "/report/enginereport", component: EngineReport, NonAuthLayout },
  {
    path: "/report/engineidlereport",
    component: EngineIdleReport,
    NonAuthLayout,
  },
  { path: "/report/alertoverview", component: AlertOverview, NonAuthLayout },
  { path: "/report/alertreport", component: AlertReport, NonAuthLayout },
  { path: "/report/alertdetails", component: AlertDetails, NonAuthLayout },
  { path: "/report/geofencealert", component: GeoFenceAlert, NonAuthLayout },
]

const virtualRoutes = [...commonRoutesForAllUsers]

const EndUserRoutes = [...commonRoutesForAllUsers]

const commonBetweenAdminDistributor = [
  { path: "/dashboard", component: Dashboard },
  // eslint-disable-next-line react/display-name

  { path: "/addusers", component: AddUsers },
  { path: "/adddevices", component: AddDevices },
  { path: "/business", component: Business },
  { path: "/card/transfercard", component: TransferCard },
  { path: "/card/exchangecard", component: ExchangeLifeCard },
  { path: "/card/cardbalance", component: CardBalance },
  { path: "/card/cardhistory", component: CardHistory },

  { path: "/ui", component: Ui },
  { path: "/rightuser", component: RightUser },
]

const distributorRoutes = [
  ...commonRoutesForAllUsers,
  ...commonBetweenAdminDistributor,
]

const adminProtectedRoutes = [
  ...commonBetweenAdminDistributor,
  { path: "/card/createcard", component: CreateCard },
]

const getRoutesBasedOnRole = () => {
  const userDetail = getLoggedInUser()
  console.log(userDetail)
  if (userDetail) {
    if (userDetail.roles.includes("admin")) {
      return [
        ...commonRoutesForAllUsers,
        ...adminProtectedRoutes,
        {
          path: "/",
          exact: true,
          component: () => <Redirect to="/dashboard" />,
        },
      ]
    } else if (userDetail.roles.includes("virtual")) {
      return [
        ...virtualRoutes,
        { path: "/", exact: true, component: () => <Redirect to="/monitor" /> },
      ]
    } else if (userDetail.roles.includes("distributor")) {
      return [
        ...distributorRoutes,
        { path: "/", exact: true, component: () => <Redirect to="/monitor" /> },
      ]
    } else if (userDetail.roles.includes("end_user")) {
      return [
        ...EndUserRoutes,
        { path: "/", exact: true, component: () => <Redirect to="/monitor" /> },
      ]
    } else {
      return []
    }
  } else {
    return []
  }
}

const authProtectedRoutes = getRoutesBasedOnRole()

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/openapi", component: openApi },
]

export { publicRoutes, authProtectedRoutes }
