import React, { useState, memo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getMonitorDetails } from "../../store/actions"

const Counter = () => {
  const [seconds, setSeconds] = useState(10)
  const { selectedUser } = useSelector(state => {
    return {
      selectedUser: state.MonitorDetails.deviceDetails.selectedData,
    }
  })

  const [selectedUserData, setSelectedUserData] = useState(selectedUser)

  const dispatch = useDispatch()
 
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => {
        if (seconds === 1) {
          return setSeconds(10)
        }
        return seconds - 1
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!selectedUser) return 
    setSelectedUserData(selectedUser)
    if (seconds === 1) {
     
      if (selectedUser) {

     
        dispatch(getMonitorDetails(selectedUser))
      }
    }
  }, [selectedUser, seconds])

  function refreshComponent() {
    if (selectedUserData) {
      dispatch(getMonitorDetails(selectedUserData))
    }
    return setSeconds(10)
  }

  
  return (
    <>
      <div className="refreshmap">
        Refresh after<span>{seconds}</span>seconds{" "}
        <span
          onClick={() => {
            refreshComponent()
          }}
        >
          Refresh
        </span>
      </div>
    </>
  )
}

export default memo(Counter)
