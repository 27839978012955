import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ChangeSaga from "./auth/changepwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
// import dashboardSaga from "./dashboard/saga"
import addUser from "./addUser/saga"
import addDevice from "./addDevice/saga"
import devicesSaga from "./device/saga"
import menuTreeSaga from "./treeMenu/saga"
import getAllCardSaga from "./getAllCards/saga"
import messageSaga from "./message/saga"
import preferencesSaga from "./preferences/saga"
import monitorSaga from "./monitor/saga"
import deviceGroupSaga from "./deviceGroup/saga"
import deviceSelectedPopupSaga from "./monitor/deviceList/saga"
import trackDetailsSaga from "./monitor/track/saga"
import reportsSaga from "./reports/saga"
import playBackSaga from "./monitor/playback/saga"
import playBackHistorySaga from "./monitor/playBackHistory/saga"
import sharedDataSaga from "./sharedPopUp/saga"
import geoFenceSaga from "./geoFence/saga"
export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ChangeSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(addUser),
    fork(addDevice),
    fork(devicesSaga),
    fork(menuTreeSaga),
    fork(getAllCardSaga),
    fork(messageSaga),
    fork(monitorSaga),
    fork(preferencesSaga),
    fork(deviceGroupSaga),
    fork(deviceSelectedPopupSaga),
    fork(trackDetailsSaga),
    fork(reportsSaga),
    fork(playBackSaga),
    fork(playBackHistorySaga),
     fork(sharedDataSaga),
     fork(geoFenceSaga)
  ])
}
  