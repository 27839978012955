/* eslint-disable */

import React, { Component, useState } from "react"
// import React, { Component }  from 'react';
// import { Formik, Field, Form, ErrorMessage } from "formik"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Card,
  Label,
  Button,
  Col,
  CardBody,
  Alert,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"

import { useSelector, useDispatch } from "react-redux"

import LoadingButton from "../../components/Common/Button"

import * as Yup from "yup"
import { useFormik } from "formik"

import { memberUserChangePassword } from "../../store/actions"

import { getLoggedInUserID } from "../../helpers/fakebackend_helper"

const ChangePassword = props => {
  const dispatch = useDispatch()
  const { type, tog_center3 = undefined } = props

  const { loading } = useSelector(state => {
    return { loading: state.AddUser.loading }
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newPassword: "",
      newPasswordConfirm: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Please Enter Your New Password")
        .min(6, "New Password should be at least 6 character"),
      newPasswordConfirm: Yup.string()
        .required("Please Enter Your Confirm New Password")
        .min(6, "Confirm New Password should be at least 6 character")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: values => {
      const payload = {
        password: values.newPassword,
        memberId: getLoggedInUserID(),
      }
      dispatch(memberUserChangePassword(payload, props.history))
    },
  })

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {type === "change" ? (
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row className="mb-3">
                <label
                  htmlFor="example-text-input"
                  className="col-md-5 col-form-label text-start"
                >
                  New password <small className="text-danger">*</small>
                </label>
                <div className="col-md-7">
                  <Input
                    name="newPassword"
                    className="form-control"
                    placeholder="Enter New Password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.newPassword || ""}
                    invalid={
                      validation.touched.newPassword &&
                      validation.errors.newPassword
                        ? true
                        : false
                    }
                  />
                  {validation.touched.newPassword &&
                  validation.errors.newPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.newPassword}
                    </FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row className="mb-3">
                <label
                  htmlFor="example-text-input"
                  className="col-md-5 col-form-label text-start"
                >
                  Confirm password <small className="text-danger">*</small>
                </label>
                <div className="col-md-7">
                  <Input
                    name="newPasswordConfirm"
                    className="form-control"
                    placeholder="Enter Confirm New Password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.newPasswordConfirm || ""}
                    invalid={
                      validation.touched.newPasswordConfirm &&
                      validation.errors.newPasswordConfirm
                        ? true
                        : false
                    }
                  />
                  {validation.touched.newPasswordConfirm &&
                  validation.errors.newPasswordConfirm ? (
                    <FormFeedback type="invalid">
                      {validation.errors.newPasswordConfirm}
                    </FormFeedback>
                  ) : null}
                </div>
              </Row>

              <Row className="mb-3">
                <label
                  htmlFor="example-text-input"
                  className="col-md-5 col-form-label text-start"
                ></label>
                <div className="col-md-7">
                  <LoadingButton
                    color="primary"
                    className="btn btn-primary submitbtn "
                    loading={loading}
                    disabled={loading}
                    block={false}
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </div>
              </Row>
            </Form>
          ) : (
            <>
              <Row className="mb-3">Reset password into '123456' ?</Row>
              <Row className="mb-3">
                <div className="d-flex justify-content-end">
                  <LoadingButton
                    color="secondary"
                    className="btn btn-primary submitbtn btn-left"
                    loading={false}
                    disabled={false}
                    block={false}
                    onClick={() => tog_center3 && tog_center3()}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    color="primary"
                    className="btn btn-primary submitbtn "
                    loading={loading}
                    disabled={loading}
                    block={false}
                    onClick={() =>
                      dispatch(
                        memberUserChangePassword(
                          {
                            password: "123456",
                            memberId: getLoggedInUserID(),
                          },
                          props.history
                        )
                      )
                    }
                  >
                    Ok
                  </LoadingButton>
                </div>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

ChangePassword.propTypes = {
  // selectedUser: PropTypes.object,
  history: PropTypes.func,
  type: PropTypes.string,
  tog_center3: PropTypes.func,
}

export default ChangePassword
