/* eslint-disable */

import React, { useState } from "react"
import { InputGroup } from "reactstrap"
import "./ReportStyle.css"
import EngineOverviewGrid from "./EngineOverviewGrid"
import moment from "moment"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import ReturnNormalFlatPicker from "./DatePickr/ReturnNormalFlatPicker"
import ReturnFlatPickerWithTime from "./DatePickr/ReturnFlatPickerWithTime"

const EngineOverviewMain = props => {
  const { selectedUser } = props
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isDaily, setIsDaily] = useState(true)

  return (
    <div className="panel_main">
      <div className="head_main cursor-pointer">Engine overview </div>
      <div className="p-3">
        <div className="pd_1">
          <div className="d-flex flex-wrap align-items-start">
            <div className="d-inline-flex  align-items-center pe-4 pt-2 pb-1">
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="option1"
                  checked={isDaily}
                  onClick={() => setIsDaily(true)}
                />
                <label className="form-check-label " htmlFor="exampleRadios1">
                  Daily Details
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="option2"
                  checked={!isDaily}
                  onClick={() => setIsDaily(false)}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  Period Details
                </label>
              </div>
            </div>

            <div className="d-inline-flex flex-column pe-4 pb-1">
              <div>
                <div className="d-inline-flex  align-items-center pe-2 w-50c">
                  <label className="pe-2 flx_0 pt-1">Date</label>
                  <div className="flx_1">
                    <InputGroup>
                      {isDaily ? (
                        <ReturnNormalFlatPicker
                          dateValue={startDate}
                          updateDate={setStartDate}
                        />
                      ) : (
                        <ReturnFlatPickerWithTime
                          dateValue={startDate}
                          updateDate={setStartDate}
                        />
                      )}
                    </InputGroup>
                  </div>
                </div>
                <label className="pe-2 flx_0 pt-1">To</label>
                <div className="d-inline-flex align-items-center">
                  <div className="flx_1">
                    <InputGroup>
                      {isDaily ? (
                        <ReturnNormalFlatPicker
                          dateValue={endDate}
                          updateDate={setEndDate}
                        />
                      ) : (
                        <ReturnFlatPickerWithTime
                          dateValue={endDate}
                          updateDate={setEndDate}
                        />
                      )}
                    </InputGroup>
                  </div>
                  <button className="btn btn-sm btn-primary ms-2">
                    Check{" "}
                  </button>
                </div>
              </div>

              <div className="d-flex w-100 pt-1 ps-3 pb-3">
                <span className="ps-4 form-text">
                  Maximum time range is 30 days. Please schedule for more
                  reports.
                </span>
                <span className="txt_link ps-2 form-text">Schedule now</span>
              </div>
            </div>

            <div className=""></div>
          </div>
        </div>
        <div className="pd_2 text-end pb-2 mb-4 datseparate">
          <div>
            <span
              className="txt_link"
              onClick={() => {
                setStartDate(moment().subtract(1, "days").toDate())
                setEndDate(moment().subtract(1, "days").toDate())
              }}
            >
              Yesterday
            </span>
            <span
              className="txt_link"
              onClick={() => {
                setStartDate(moment().startOf("week").toDate())
                setEndDate(moment().toDate())
              }}
            >
              This week
            </span>
            <span
              className="txt_link"
              onClick={() => {
                setStartDate(
                  moment().subtract(1, "weeks").startOf("week").toDate()
                )
                setEndDate(moment().subtract(1, "weeks").endOf("week").toDate())
              }}
            >
              Last week
            </span>
            <span
              className="txt_link"
              onClick={() => {
                setStartDate(moment().startOf("month").toDate())
                setEndDate(moment().toDate())
              }}
            >
              This month
            </span>
            <span
              className="txt_link border-end-0"
              onClick={() => {
                setStartDate(
                  moment().subtract(1, "month").startOf("month").toDate()
                )
                setEndDate(
                  moment().subtract(1, "month").endOf("month").toDate()
                )
              }}
            >
              Last month
            </span>
          </div>
        </div>

        <div className="row devicecol">
          <div className="col-lg-3">
            <div className="body_box">
              <span className="title">Device quantity: </span>
              <span className="txt_bold d-block">2</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="body_box">
              <span className="title">Total Engine ON: </span>
              <div>
                <span className="txt_bold">10hr7min6s</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="body_box">
              <span className="title">Total Engine OFF: </span>
              <div>
                <span className="txt_bold">1d11hr22min</span>
              </div>
            </div>
          </div>
        </div>
        <div className="report_park_grid pt-4">
          <EngineOverviewGrid />
        </div>
      </div>
    </div>
  )
}

EngineOverviewMain.propTypes = {
  selectedUser: PropTypes.obj,
}

export default EngineOverviewMain
