/* eslint-disable */

import React, { Component, useState } from "react"
// import React, { Component }  from 'react';
import MetaTags from "react-meta-tags"
import { Container, Row, Card,Label,
    Button,Nav,
    Table,
    NavItem,
    NavLink, TabContent,
    TabPane,
    CardBody,
    CardText,
    Modal,
    CardTitle,
    Col } from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import SearchDeviceInfo from "./SearchDeviceInfo"








const Membership = props => {


  const [ViewDevice, setViewDevice] = useState(false);
  const viewDeviceInfo = () => {
    setViewDevice(!ViewDevice);
  }
  const BackDeviceInfo = () => {
    setViewDevice(false);
  }

  const [activeTab, setactiveTab] = useState("1");
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };


  const [modal_center2, setmodal_center2] = useState(false)
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  return (
    <React.Fragment>
<span className="d-block mb-2">Total <b className="text-danger">0</b> records matched</span>
    <Table className="table mb-0">
                      <thead>
                        <tr>
                        <th>#</th>
                          <th>Device Name</th>
                          <th>IMEI</th>
                          <th>SIM Card</th>
                          <th>Model</th>
                          <th>Membership</th>
                          <th>Operation</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          props.data && props.data.length > 0 && props.data.map((data, index) => {
                            return(
                              <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{data?.name}</td>
                                      <td><span className="text-danger">{data?.imei}</span></td>
                                      <td>{data?.simCard}</td>
                                      <td>-</td>
                                      <td>admin</td>
                                      <td>
                                      <span className="text-info">
                                        <span 
                                          onClick={viewDeviceInfo}
                                          className="txt_colr_1">Details</span> | <Link
                                          to="/monitor" target={"_blank"}
                                          className="txt_colr_1"
                                        >
                                        <span>Monitor</span>
                                      </Link>
                                      </span></td>
                                   </tr> 
                              </>
                            )
                          })
                        }
                      </tbody>
                    </Table>


                    {
        ViewDevice ?
        <div className="ViewDeviceInfo">
 


        <Row className="mb-3">
 <Col md="6">
                            
                   <span className="txt_colr_1 me-2"  onClick={BackDeviceInfo}><i className="dripicons-return me-1"></i> Back</span>| <span>GT06N-57085 ( 892951027457085 )</span></Col>
 <Col md="6" className="text-end"> <Link
                to="/monitor" target={"_blank"}
                className="btn btn-outline-secondary btn-sm me-2"
              >
                <span>Monitor</span>
              </Link> <Link
                to="/monitor" target={"_blank"}
                className="btn btn-outline-secondary btn-sm"
              >
                <span>Track</span>
              </Link></Col>
 </Row>



        <SearchDeviceInfo />
        </div>
        :null 
      }
                   

                   



      

  
    </React.Fragment>
  )
}

export default Membership
