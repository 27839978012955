/* eslint-disable */
import usicons1 from "../../../src/assets/images/user.svg"
import React from "react"
import {
  GET_MONITOR_INITIAL,
  GET_MONITOR_DETAILS_FAIL,
  GET_MONITOR_DETAILS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  deviceDetails:{},
  error: {},
  isLoading: false,
}

const MonitorDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MONITOR_INITIAL:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MONITOR_DETAILS_FAIL:
      return {
        ...state,
        deviceDetails: action.payload,
        isLoading: false,
      }

    case GET_MONITOR_DETAILS_SUCCESS:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    default:
      return state
  }
}

export default MonitorDetails
