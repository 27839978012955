import {
  SET_SELECTED_DEVICE_INITIAL,
  SET_SELECTED_DEVICE_FAIL,
  SET_SELECTED_DEVICE_SUCCESS,
} from "./actionTypes"

export const setSelectedDevicePopup = selectedData => ({
  type: SET_SELECTED_DEVICE_INITIAL,
  selectedData,
})

export const setSelectedDevicePopupSuccess = selectedDevicePopupDetails => ({
  type: SET_SELECTED_DEVICE_SUCCESS,
  payload: selectedDevicePopupDetails,
})
export const setSelectedDevicePopupFail = error => ({
  type: SET_SELECTED_DEVICE_FAIL,
  payload: error,
})
