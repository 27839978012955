export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const UPDATE_LOGIN_USER = "UPDATE_LOGIN_USERS"
export const UPDATE_LOGIN_USER_SUCCESS = "UPDATE_LOGIN_USERS_SUCCESS"
export const UPDATE_LOGIN_USER_ERROR = "UPDATE_LOGIN_USERS_ERROR"

export const UPLOAD_IMAGE = "UPLOAD_IMAGE"
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS"
export const UPLOAD_IMAGE_ERROR = "UPLOAD_IMAGE_ERROR"

export const LOGIN_USER_DETAIL = "LOGIN_USER_DETAIL"
export const LOGIN_USER_DETAIL_SUCCESS = "LOGIN_USER_DETAIL_SUCCESS"
export const LOGIN_USER_DETAIL_ERROR = "LOGIN_USER_DETAIL_ERROR"
