/* USERS */
export const GET_TREE_MENU_DATAILS = "GET_TREE_MENU_DATAILS"
export const GET_TREE_MENU_DATAILS_FAIL = "GET_TREE_MENU_DATAILS_FAIL"
export const GET_TREE_MENU_DATAILS_SUCCESS = "GET_TREE_MENU_DATAILS_SUCCESS"

export const GET_TREE_MENU_INITIAL = "GET_TREE_MENU_INITIAL"
export const GET_TREE_MENU_INITIAL_FAIL = "GET_TREE_MENU_INITIAL_FAIL"
export const GET_TREE_MENU_INITIAL_SUCCESS = "GET_TREE_MENU_INITIAL_SUCCESS"

export const GET_SUB_USERS = "GET_SUB_USERS"
export const GET_SUB_USERS_FAIL = "GET_SUB_USERS_FAIL"
export const GET_SUB_USERS_SUCCESS = "GET_SUB_USERS_SUCCESS"

export const DELETE_USERS = "DELETE_USERS"
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS"
export const DELETE_USERS_ERROR = "DELETE_USERS_ERROR"
