/* eslint-disable */

import React, { useState } from "react"
import { MultiSelect } from "react-multi-select-component";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Modal,
  Label,
  Input,
  NavLink,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./ReportStyle.css"
import { withTranslation } from "react-i18next"
import DrivingBehaviorOverviewMainGrid from "./DrivingBehaviorOverviewMainGrid"

const MileageOverviewDetails = () => {
  const [selectedMulti, setselectedMulti] = useState(null);
  const optionGroup = [
    {
      options: [
        { label: "Maruthi Van", value: "GT06N 252" },
        { label: "Scopria", value: "GT12N 252" },
      ]
    },
   
  ];
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  const [selected, setSelected] = useState([]);
  const options = [
   
  ];
  

  return (
    
      <div className="panel_main">
        <div className="head_main cursor-pointer">Driving behavior overview </div>
        <div className="p-3">
          <div className="pd_1">
            <div className="d-flex flex-wrap align-items-start">
              <div className="d-inline-flex flex-column pe-4 pb-1">
                <div>
                  <div className="d-inline-flex  align-items-center pe-2 w-50c">
                    <label className="pe-2 flx_0 pt-1">Date</label>
                    <div className="flx_1">
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block w-100"
                          placeholder=""
                          options={{
                            altInput: false,
                            enableTime: false,
                            dateFormat: "Y-m-d",
                            time_24hr: true,
                            defaultDate: ["2016-10-20"],
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <label className="pe-2 flx_0 pt-1">To</label>
                  <div className="d-inline-flex align-items-center">
                   
                    <div className="flx_1">
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block w-100"
                          placeholder=""
                          options={{
                            altInput: false,
                            enableTime: false,
                            dateFormat: "Y-m-d",
                            time_24hr: true,
                            defaultDate: ["2016-10-20"],
                          }}
                        />
                      </InputGroup>
                    </div>


                   
                    <button className="btn btn-sm btn-primary  ms-3">Check </button>
                
                  </div>
                </div>

                <div className="d-flex w-100 pt-1 ps-3 pb-3">
                  <span className="ps-4 form-text">
                  Maximum time range is 30 days. Please schedule for more reports
                  </span>
                  <span className="txt_link form-text ps-2">Schedule now</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pd_2 text-end pb-2 mb-4 datseparate">
            <div>
              <span className="txt_link">Yesterday</span>
              <span className="txt_link">This week</span>
              <span className="txt_link">Last week</span>
              <span className="txt_link">This month</span>
              <span className="txt_link border-end-0">Last month</span>
            </div>
          </div>

          <div className="row devicecol mb-4">
            <div className="col-lg-2">
              <div className="body_box">
                <span className="title">Total Fatigue Driving:  </span>
                <div>
                  <span className="txt_bold">15</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="body_box">
                <span className="title">Total Harsh driving acceleration:  </span>
                <div>
                  <span className="txt_bold">15 </span>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="body_box">
                <span className="title">Total Harsh driving braking:  </span>
                <div>
                  <span className="txt_bold">15</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="body_box">
                <span className="title">Total Sharp Turn:   </span>
                <div>
                  <span className="txt_bold">10</span>
                </div>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="body_box">
                <span className="title">Total Sharp Change Lane:    </span>
                <div>
                  <span className="txt_bold">15</span>
                </div>
              </div>
            </div>


          </div>


          

          <div className="report_park_grid">
            <DrivingBehaviorOverviewMainGrid />
          </div>
        </div>
      </div>
    
  )
}

export default MileageOverviewDetails
