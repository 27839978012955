import React from "react"
import Flatpickr from "react-flatpickr"
import PropTypes from "prop-types"

const ReturnNormalFlatPicker = ({ dateValue, updateDate }) => {
  return (
    <Flatpickr
      className="form-control d-block w-100"
      placeholder=""
      options={{
        altInput: false,
        enableTime: false,
        dateFormat: "Y-m-d",
        time_24hr: false,
      }}
      onChange={date => updateDate(date)}
      value={dateValue}
    />
  )
}
ReturnNormalFlatPicker.propTypes = {
  dateValue: PropTypes.date,
  updateDate: PropTypes.func,
}

export default ReturnNormalFlatPicker
