import {
  GET_DEVICES_BY_MEMBERID,
  GET_DEVICES_BY_MEMBERID_FAIL,
  GET_DEVICES_BY_MEMBERID_SUCCESS,
  GET_DEVICES_INFO_BY_MEMBERID,
  GET_DEVICES_INFO_BY_MEMBERID_FAIL,
  GET_DEVICES_INFO_BY_MEMBERID_SUCCESS,
  GET_DEVICE_BY_DEVICEID,
  GET_DEVICE_BY_DEVICEID_SUCCESS,
  GET_DEVICE_BY_DEVICEID_FAIL,
  PUT_DEVICE_BY_DEVICEID,
  PUT_DEVICE_BY_DEVICEID_SUCCESS,
  PUT_DEVICE_BY_DEVICEID_FAIL,
  PUT_DEVICE_INSSTALLATION_INFO,
  PUT_DEVICE_INSSTALLATION_INFO_SUCCESS,
  PUT_DEVICE_INSSTALLATION_INFO_FAIL,
  PUT_DEVICE_FUEL,
  PUT_DEVICE_FUEL_SUCCESS,
  PUT_DEVICE_FUEL_FAIL,
  PUT_DEVICE_ALARM,
  PUT_DEVICE_ALARM_SUCCESS,
  PUT_DEVICE_ALARM_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  deviceByMember: {},
  error: {},
  isLoading: false,
  deviceInfoByMember: {},
  deviceDetail: {},
  isLoadingDetail: false,
  isUpdateDeviceLoading: false,
}

const getDevicesByMember = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICES_BY_MEMBERID:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DEVICES_BY_MEMBERID_SUCCESS:
      return {
        ...state,
        deviceByMember: action.payload,
        isLoading: false,
      }

    case GET_DEVICES_BY_MEMBERID_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case GET_DEVICES_INFO_BY_MEMBERID:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DEVICES_INFO_BY_MEMBERID_SUCCESS:
      return {
        ...state,
        deviceInfoByMember: action.payload,
        isLoading: false,
      }

    case GET_DEVICES_INFO_BY_MEMBERID_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case GET_DEVICE_BY_DEVICEID:
      return {
        ...state,
        isLoadingDetail: true,
      }
    case GET_DEVICE_BY_DEVICEID_SUCCESS:
      return {
        ...state,
        deviceDetail: action.payload,
        isLoadingDetail: false,
      }

    case GET_DEVICE_BY_DEVICEID_FAIL:
      return {
        ...state,
        isLoadingDetail: false,
      }

    case PUT_DEVICE_BY_DEVICEID:
      return {
        ...state,
        isUpdateDeviceLoading: true,
      }

    case PUT_DEVICE_BY_DEVICEID_SUCCESS:
      return {
        ...state,
        isUpdateDeviceLoading: false,
      }

    case PUT_DEVICE_BY_DEVICEID_FAIL:
      return {
        ...state,
        isUpdateDeviceLoading: false,
      }

    case PUT_DEVICE_INSSTALLATION_INFO:
      return {
        ...state,
        isUpdateDeviceLoading: true,
      }

    case PUT_DEVICE_INSSTALLATION_INFO_SUCCESS:
      return {
        ...state,
        isUpdateDeviceLoading: false,
      }

    case PUT_DEVICE_INSSTALLATION_INFO_FAIL:
      return {
        ...state,
        isUpdateDeviceLoading: false,
      }

    case PUT_DEVICE_FUEL:
      return {
        ...state,
        isUpdateDeviceLoading: true,
      }

    case PUT_DEVICE_FUEL_SUCCESS:
      return {
        ...state,
        isUpdateDeviceLoading: false,
      }

    case PUT_DEVICE_FUEL_FAIL:
      return {
        ...state,
        isUpdateDeviceLoading: false,
      }
    case PUT_DEVICE_ALARM:
      return {
        ...state,
        isUpdateDeviceLoading: true,
      }

    case PUT_DEVICE_ALARM_SUCCESS:
      return {
        ...state,
        isUpdateDeviceLoading: false,
      }

    case PUT_DEVICE_ALARM_FAIL:
      return {
        ...state,
        isUpdateDeviceLoading: false,
      }

    default:
      return state
  }
}

export default getDevicesByMember
