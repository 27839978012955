import React, { useState } from "react"
import Navigation from "../../components/Monitor/NavigationMenu"

import SpeedTripReportMain from "../../components/Report/SpeedTripReportMain"
import ReportAside from "../../components/Report/ReportAside"
import { useSelector } from "react-redux"

const ReportIndex = () => {
  const { profileData } = useSelector(state => state.Login)
  const [selectedUser, setSelectedUser] = useState(profileData?.member)

  return (
    <React.Fragment>
      <Navigation />
      <div className="nan_auto_layout">
        <ReportAside
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
        <div className="right_report h-100">
          <SpeedTripReportMain selectedUser={selectedUser} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReportIndex
