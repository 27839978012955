/* eslint-disable */
import React, { Component, useState, useEffect } from "react"

import PropTypes from "prop-types"

import { connect } from "react-redux"

import { bindActionToPromise } from "../../utils/Common"

import ReactTooltip from "react-tooltip"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Input, Label, Button, FormFeedback } from "reactstrap"
import { useHistory, Link } from "react-router-dom"

import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"

import usicons1 from "../../assets/images/user.svg"
import usicons2 from "../../assets/images/user2.svg"
import usicons3 from "../../assets/images/user3.svg"
import usicons4 from "../../assets/images/user4.svg"

import Select from "react-select"

import { addUserdefault } from "../../store/actions"

const userFormScheme = yup.object({
  parentID: yup.string().required("Superior is required"),
  username: yup
    .string()
    .required("Username is required")
    .matches(/^(\S+$)/, "Spaces are not allowed"),
  name: yup.string().required("LoginAccount is required"),
  password: yup.string().required("Password is required"),
  cPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf(
      [yup.ref("password"), null],
      "Confirm password and password must match"
    ),
  timezone: yup.string().required("Time zone is required"),
})


import TreeSelect from "../../components/user/commonPopUpTree"

import { TimeZoneOptionList } from "utils/Common"
import LoadingButton from "components/Common/Button"



const AddUsersMain = ({ userInfo, updatePopupClose, addUserdefaultAction }) => {
  const INITIAL_VALUE = {
    parentID: userInfo?.id,
    username: "",
    name: "",
    password: "",
    cPassword: "",
    telephone: "",
    mobileNumber: "",
    contactNumber: "",
    email: "",
    address: "",
    webpage: "",
    timezone: "+05:30",
    role: "end_user",
  }
  const dispatch = useDispatch()

  const { loading } = useSelector(state => {
    return {
      loading: state.AddUser.isLoading,
    }
  })

  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [error, setError] = useState({})
  const [userData, setUserData] = useState(INITIAL_VALUE)
  const [monitorOnly, setMonitorOnly] = useState(false)

  const generatePassword = () => {
    const randomPassword = Math.random().toString(36).slice(2)

    setUserData(previousUser => ({
      ...previousUser,
      password: randomPassword,
      cPassword: randomPassword,
    }))

    navigator.clipboard.writeText(randomPassword)
  }

  const onChangeInput = (key, value) => {
    if (
      key === "contactNumber" ||
      key === "mobileNumber" ||
      key === "telephone"
    ) {
      const re = /^[0-9\+b]+$/
      if (!(value === "" || re.test(value))) {
        return
      }
    }

    if (key === "role" && value === "end_user") {
      setMonitorOnly(false)
    }

    setUserData(previousUser => ({ ...previousUser, [key]: value }))
  }

  const handleSetData = childData => {
    onChangeInput("parentID", childData)
  }

  const optionsTimeZone = TimeZoneOptionList()

  const submitForm = async () => {
    const errObj = {}
    await userFormScheme
      .validate(userData, { abortEarly: false })
      .catch(err => {
        err.inner.forEach(x => {
          if (x.path !== undefined) {
            errObj[x.path] = x.errors?.join("")
          }
        })
      })
    setError(errObj)

    if (errObj && Object.keys(errObj).length === 0) {
      const payload = {
        parentId: userData?.parentID,
        username: userData?.username,
        name: userData?.name,
        password: userData?.password,
        cPassword: userData?.cPassword,
        telephone: userData?.telephone ? userData?.telephone : undefined,
        mobileNumber: userData?.mobileNumber
          ? userData?.mobileNumber
          : undefined,
        contactNumber: userData?.contactNumber
          ? userData?.contactNumber
          : undefined,
        email: userData?.email || undefined,
        address: userData?.address || undefined,
        webpage: userData?.webpage || undefined,
        timezone: userData?.timezone,
        role: userData?.role,
        monitorOnly: userData?.role === "end_user" ? monitorOnly : undefined,
      }
      addUserdefaultAction({ payload }).then(() => {
      updatePopupClose(false)
        setUserData(INITIAL_VALUE)
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <div className="mb-3">
            <Label htmlFor="formrow-login-account">
              Superior <b>*</b>
            </Label>
            <TreeSelect
              parentCallback={handleSetData}
              selectedValue={userInfo}
            />
            {error?.parentID ? (
              <FormFeedback type="invalid" style={{ display: "block" }}>
                {error?.parentID}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-login-account">
              Login account <b>*</b>
            </Label>
            <Input
              name="name"
              type="text"
              className="form-control"
              onChange={e => onChangeInput("name", e.target.value)}
              value={userData.name}
            />
            {error?.name ? (
              <FormFeedback type="invalid" style={{ display: "block" }}>
                {error?.name}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-username">User name</Label>

            <Input
              name="username"
              type="text"
              className="form-control"
              onChange={e => onChangeInput("username", e.target.value)}
              value={userData.username}
            />
            {error?.username ? (
              <FormFeedback type="invalid" style={{ display: "block" }}>
                {error?.username}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-1">
            <Label htmlFor="formrow-email-Input">
              Password <b>*</b>
            </Label>
            <div className="d-flex align-items-center">
              <Input
                name="password"
                type={showPassword ? "text" : "password"}
                className="form-control"
                onChange={e => onChangeInput("password", e.target.value)}
                value={userData.password}
              />
              <Button
                className="btnpasswaord"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </Button>
            </div>
            {error?.password ? (
              <FormFeedback type="invalid" style={{ display: "block" }}>
                {error?.password}
              </FormFeedback>
            ) : null}
          </div>
          <div className="">
            <a
              className="generate-password mb-2 d-inline-block"
              onClick={generatePassword}
            >
              <i className="mdi mdi-key-outline"></i> Generate password
            </a>
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-cpassword-Input">
              Confirm Password <b>*</b>
            </Label>
            <div className="d-flex align-items-center">
              <Input
                name="cPassword"
                type={showPassword2 ? "text" : "password"}
                className="form-control"
                onChange={e => onChangeInput("cPassword", e.target.value)}
                value={userData.cPassword}
              />
              <Button
                className="btnpasswaord"
                onClick={() => setShowPassword2(!showPassword2)}
              >
                {showPassword2 ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </Button>
            </div>
            {error?.cPassword ? (
              <FormFeedback type="invalid" style={{ display: "block" }}>
                {error?.cPassword}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>

      <Row className="mb-3 align-items-center">
        <label
          htmlFor="example-text-input"
          className="col-md-2 col-form-label text-end"
        >
          Role <small className="text-danger"> *</small>
        </label>
        <div className="col-md-10 d-flex pt-2">
          <div className="form-check pe-2">
            <Input
              type="radio"
              value="distributor"
              id="role1"
              name="role"
              className="form-check-input"
              onClick={e => onChangeInput("role", e.target.value)}
              checked={userData.role === "distributor"}
            />
            <Label
              className="form-check-label font-size-13 w-99"
              htmlFor="role1"
            >
              <img src={usicons1} className="me-1" width={20} /> Distributor
            </Label>
          </div>
          <div className="form-check pe-2">
            <Input
              type="radio"
              value="virtual"
              id="role2"
              name="role"
              className="form-check-input"
              onClick={e => onChangeInput("role", e.target.value)}
              checked={userData.role === "virtual"}
            />
            <Label
              className="form-check-label font-size-13 w-99"
              htmlFor="role2"
            >
              <img src={usicons2} className="me-1" width={20} />
              Virtual account
            </Label>
          </div>
          <div className="form-check pe-2">
            <Input
              type="radio"
              value="end_user"
              id="role3"
              name="role"
              className="form-check-input"
              onClick={e => onChangeInput("role", e.target.value)}
              checked={userData.role === "end_user"}
            />
            <Label
              className="form-check-label font-size-13 w-99"
              htmlFor="role3"
            >
              <img src={usicons3} className="me-1" width={20} />
              End User
            </Label>
          </div>
          {userData.role === "end_user" ? (
            <>
              <div className="form-check pe-2">
                <Input
                  type="checkbox"
                  className="form-check-Input"
                  id="horizontal-customCheck-auto2"
                  onClick={e => setMonitorOnly(e.target.checked ? true : false)}
                  checked={monitorOnly}
                />
                <label className="form-check-label">
                  <img src={usicons4} className="me-1" width={20} />
                  Monitor Only
                </label>
                <span
                  className="ms-1 text-danger"
                  data-tip="User can not modify any device or account information; Only Monitoring,Tracking,Playback and Statistic reports are supported."
                >
                  <i className="bx bxs-info-circle"></i>
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-contact-Input">Contact</Label>

            <Input
              name="contactNumber"
              type="text"
              className="form-control"
              onChange={e => onChangeInput("contactNumber", e.target.value)}
              value={userData.contactNumber}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-cpassword-Input">Mobile</Label>

            <Input
              name="mobileNumber"
              type="text"
              className="form-control"
              onChange={e => onChangeInput("mobileNumber", e.target.value)}
              value={userData.mobileNumber}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-tephone-Input">Telephone</Label>

            <Input
              name="telephone"
              type="text"
              className="form-control"
              onChange={e => onChangeInput("telephone", e.target.value)}
              value={userData.telephone}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-email-Input">Email</Label>
            <span
              className="ms-1 text-danger"
              data-tip="You can retrieve your password through this email address, please be sure to fill in the valid one."
            >
              <i className="bx bxs-info-circle"></i>
            </span>

            <Input
              name="email"
              type="text"
              className="form-control"
              onChange={e => onChangeInput("email", e.target.value)}
              value={userData.email}
            />
          </div>
          <ReactTooltip place="bottom" type="dark" effect="float" />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-address-Input">Address</Label>

            <Input
              name="address"
              type="text"
              className="form-control"
              onChange={e => onChangeInput("address", e.target.value)}
              value={userData.address}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-webpage-Input">WebPage</Label>

            <Input
              name="webpage"
              type="text"
              className="form-control"
              onChange={e => onChangeInput("webpage", e.target.value)}
              value={userData.webpage}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="mb-3">
            <Label htmlFor="formrow-email-Input">
              Time zone <b>*</b>
            </Label>

            <Select
              classNamePrefix="select2-selection"
              placeholder="Please Select"
              title="timezone"
              name="timezone"
              menuPlacement="top"
              isClearable={false}
              options={optionsTimeZone}
              isSearchable={false}
              onChange={selectedOption =>
                onChangeInput("timezone", selectedOption?.value)
              }
              value={optionsTimeZone.filter(option => {
                if (option.value == userData.timezone) return option
              })}
            />
          </div>
        </Col>
      </Row>

      <div className="pb-3">
        <LoadingButton
          color="primary"
          className="btn btn-primary btn-right"
          loading={loading}
          disabled={loading}
          block={false}
          onClick={() => submitForm()}
        >
          {" "}
          Submit
        </LoadingButton>
        <Button
          color="secondary"
          className="btnwid"
          outline
          onClick={() => setUserData(INITIAL_VALUE)}
        >
          Clear
        </Button>
      </div>
    </React.Fragment>
  )
}

AddUsersMain.propTypes = {
  history: PropTypes.object,
  selectedUser: PropTypes.object,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  addUserdefaultAction: bindActionToPromise(dispatch, addUserdefault),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUsersMain)
