/* eslint-disable */
import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Modal,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import Tooltip from "@mui/material/Tooltip"
import lefticon11 from "../../assets/images/icons/download.svg"

//i18n
import { withTranslation } from "react-i18next"
const MonitorBottomTrace = props => {
  const [menu, setMenu] = useState(false)
  const [col6, setcol6] = useState(true)
  const t_col6 = () => {
    setcol6(!col6)
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="div"
      >
        <DropdownToggle className="no-button ms-4 me-4" tag="button">
          <Tooltip title="Trace Download" placement="top" arrow>
            <img src={lefticon11} width={30} />
          </Tooltip>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu mb_trace_center p-0">
          <div className="d-flex align-items-center bot_pop_head">
            <i className="bx bxs-map pe-2 flx_0 font-size-17"></i>
            <span className="flx_1"> Trace Download</span>
            <div className="flx_0 ps-2 pt-2">
              <span
                onClick={() => setMenu(!menu)}
                className="bx bx-message-alt-minus font-size-17 cursor-pointer"
              ></span>
            </div>
          </div>
          <SimpleBar style={{ height: "470px" }}>
            <div className="p-3">
              <div className="bg_cus_2 p-2 mb-2 d-flex">
                <span className="pe-2 flx_0">
                  <i className="helpIcon  bx bxs-info-circle fs-5"></i>
                </span>
                <p className="flx_1 mb-0">
                  Trace download can not be over 7 days.
                </p>
              </div>

              <div className="form_trace">
                <div className="pb-2">
                  <label>Device</label>
                  <select name="" id="" className="form-control">
                    <option value="">Select</option>
                  </select>
                </div>
                <div className="pb-2">
                  <label>Start Time</label>
                  <input type="date" className="form-control" />
                </div>
                <div className="pb-2">
                  <label>End Time</label>
                  <input type="date" className="form-control" />
                </div>
              </div>

              <div className="download_row bg_cus_1 p-3">
                <div className="d-flex align-items-center justify-content-center mb-2">
                  <button className="btn btn-sm btn-info text-start me-2 d-flex">
                    <i className="bx bx-download fs-5 me-2"></i> Download Kml
                  </button>
                  <Tooltip
                    title="Trace download format is Google KML,you can open it by Google Earth."
                    placement="top"
                    arrow
                  >
                    <i className="helpIcon  bx bxs-info-circle fs-5"></i>
                  </Tooltip>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-2">
                  <button className="btn btn-sm btn-success text-start me-2 d-flex">
                    <i className="bx bx-file-blank fs-5 me-2"></i> Download Excel
                  </button>
                  <Tooltip
                    title="Open by Microsoft Excel."
                    placement="top"
                    arrow
                  >
                    <i className="helpIcon  bx bxs-info-circle fs-5"></i>
                  </Tooltip>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <button className="btn btn-sm btn-primary text-start me-2 d-flex">
                    <i className="bx bx-file fs-5 me-2"></i> Download Txt
                  </button>
                  <Tooltip
                    title="Trace file format: TXT. TXT file can be reviewed in Player or in Playback page."
                    placement="top"
                    arrow
                  >
                    <i className="helpIcon  bx bxs-info-circle fs-5"></i>
                  </Tooltip>
                </div>
              </div>
            </div>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
export default withTranslation()(MonitorBottomTrace)
