/* eslint-disable */

import React, { useState } from "react"

import PropTypes from "prop-types"

import { Row } from "reactstrap"

import Select from "react-select"

import { connect } from "react-redux"

import LoadingButton from "../../components/Common/Button"

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

import { useSelector } from "react-redux"

import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"

import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"

function Calibration(props) {
  const { fuelInformationInputs, updateFuelCalibration, onSubmitFuelDetail } =
    props

  const { isUpdateDeviceLoading } = useSelector(state => {
    return {
      isUpdateDeviceLoading: state.Device.isUpdateDeviceLoading,
    }
  })

  const [isChartVisisble, setIsChartVisible] = useState(false)
  const calibrationInputs = [
    { value: "Analogue input 1", label: "Analogue input 1" },
    { value: "Analogue input 2", label: "Analogue input 2" },
  ]
  const columns = [
    {
      dataField: "sensorValue",
      text: "Sensor Value",
      style: { width: "50%" },
      editor: {
        type: "number",
      },
    },
    {
      dataField: "value_l",
      text: "Value L",
      style: { width: "50%" },
      editor: {
        type: "number",
      },
    },
    {
      dataField: "delete",
      text: "Delete",
      classes: "txt_link",
      style: { width: "10%" },
      editable: false,
      formatter: (cellContent, message) => (
        <>
          <div className="text-center" onClick={() => {}}>
            {" "}
            <i className="bx bx-trash-alt fs-5"></i>{" "}
          </div>
        </>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          const newvalues = [...fuelInformationInputs?.calibration?.values]
          newvalues[rowIndex] = {
            ...row,
            sensorValue: "",
            value_l: "",
          }
          updateFuelCalibration("values", newvalues)
        },
      },
    },
  ]

  function afterSaveCell(oldValue, newValue, row, column) {
    const newvalues = [...fuelInformationInputs?.calibration?.values]
    var foundIndex = newvalues.findIndex(ele => ele.id == row.id)
    newvalues[foundIndex] = {
      id: row.id,
      sensorValue: Number(row.sensorValue),
      value_l: Number(row.value_l),
    }
    updateFuelCalibration("values", newvalues)
  }

  const addRow = () => {
    const newvalues = [...fuelInformationInputs?.calibration?.values]
    newvalues.push({ id: newvalues.length + 1, sensorValue: "", value_l: "" })
    updateFuelCalibration("values", newvalues)
  }

  const mapDataCalibrationValues = fuelInformationInputs?.calibration?.values
    .filter(ele => ele.value_l !== "" && ele.sensorValue !== "")
    .map(ele => ({
      sensorValue: ele.sensorValue,
      value_l: ele.value_l,
    }))

  return (
    <React.Fragment>
      <Row className="mb-1">
        <div className="col-md-5">
          <label className="col-md-12 col-form-label text-start">Input</label>
          <Select
            classNamePrefix="select2-selection"
            placeholder="Please Select"
            title="Type"
            name="input"
            options={calibrationInputs}
            isSearchable={false}
            isClearable={false}
            onChange={selectedOption =>
              updateFuelCalibration("input", selectedOption?.value || "")
            }
            value={calibrationInputs.filter(option => {
              if (option.value == fuelInformationInputs?.calibration?.input)
                return option
            })}
          />
        </div>
        <div className="col-md-7 mt-2">
          <div className="calibration-table-scroll">
            <BootstrapTable
              keyField="id"
              data={fuelInformationInputs?.calibration?.values || []}
              columns={columns}
              cellEdit={cellEditFactory({
                mode: "click",
                blurToSave: true,
                afterSaveCell,
              })}
            />
            {
              <div
                style={{
                  height: `${isChartVisisble ? `265px` : "0px"}`,
                  width: "94.5%",
                  backgroundColor: "#3d4b64",
                  position: "absolute",
                  bottom: "65px",
                  visibility: `${isChartVisisble ? `visible` : "hidden"}`,
                  transition:
                    "height 0.3s ease-in-out,visibility 0.3s ease-in-out",
                }}
              >
                <div
                  style={{
                    height: "20px",
                    borderBottom: "none",
                    visibility: `${isChartVisisble ? `visible` : "hidden"}`,
                  }}
                  className="modal-header"
                >
                  <button
                    type="button"
                    onClick={() => {
                      setIsChartVisible(!isChartVisisble)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                </div>
                {isChartVisisble && (
                  <ResponsiveContainer width="100%" height={200}>
                    <LineChart
                      width={500}
                      height={200}
                      data={mapDataCalibrationValues}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="sensorValue" stroke="#fff" />
                      <YAxis dataKey="value_l" stroke="#fff" />
                      <Tooltip />
                      <Line
                        connectNulls
                        type="monotone"
                        dataKey="value_l"
                        stroke="#8884d8"
                        fill="#8884d8"
                        name="value"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              </div>
            }
          </div>
          <div className="d-flex mt-4">
            <button
              className="btn flx_0 pe-1 d-flex justify-content-center align-items-center"
              style={{
                width: "50px",
                height: "40px",
                backgroundColor: "#E98607",
                borderColor: "#E98607",
              }}
              onClick={() => addRow()}
            >
              <i
                className="bx bx-plus"
                style={{
                  fontSize: "1.5rem",
                  color: "white",
                  paddingRight: "8px",
                }}
              ></i>
            </button>
            <button
              className="btn btn-primaray flx_0 pe-1 d-flex justify-content-center align-items-center"
              style={{
                width: "50px",
                height: "40px",
                marginLeft: "10px",
                backgroundColor: "white",
                border: "2px #E98607 solid",
              }}
              onClick={() => {
                setIsChartVisible(!isChartVisisble)
              }}
            >
              <i
                class="bx bx-line-chart"
                style={{
                  fontSize: "1.5rem",
                  color: "#E98607",
                  paddingRight: "8px",
                }}
              ></i>
            </button>
          </div>
        </div>
      </Row>

      <Row className="mb-1 mt-3">
        <div className="col-md-12 d-flex justify-content-end">
          <LoadingButton
            color="primary"
            className="btn btn-primary btn-right"
            loading={isUpdateDeviceLoading}
            disabled={isUpdateDeviceLoading}
            block={false}
            onClick={() => onSubmitFuelDetail()}
          >
            Submit
          </LoadingButton>
        </div>
      </Row>
    </React.Fragment>
  )
}

Calibration.propTypes = {
  fuelInformationInputs: PropTypes.obj,
  updateFuelInformationInputs: PropTypes.func,
  onSubmitFuelDetail: PropTypes.func,
}

export default Calibration
