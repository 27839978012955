import {
  ADD_DEVICE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_ERROR,
  SEARCH_DEVICE_DEFAULT,
  SEARCH_DEVICE_SUCCESS,
  SEARCH_DEVICE_ERROR,
  CHECK_IMEI_DEFAULT,
  CHECK_IMEI_SUCCESS,
  CHECK_IMEI_ERROR,
  BULKSALE_DEFAULT,
  BULKSALE_SUCCESS,
  BULKSALE_ERROR,
  RECHARGE_DEFAULT,
  RECHARGE_SUCCESS,
  RECHARGE_ERROR,
  MODIFY_EXPIRY_DATE,
  MODIFY_EXPIRY_DATE_SUCCESS,
  MODIFY_EXPIRY_DATE_ERROR,
  PLATEFORM_MODIFY_EXPIRY_DATE,
  PLATEFORM_MODIFY_EXPIRY_DATE_SUCCESS,
  PLATEFORM_MODIFY_EXPIRY_DATE_ERROR,
  ADD_TO_VIRTUAL_DEVICE,
  ADD_TO_VIRTUAL_DEVICE_SUCCESS,
  ADD_TO_VIRTUAL_DEVICE_ERROR,
} from "./actionTypes"

export const addDevicedefault = payload => {
  return {
    type: ADD_DEVICE,
    payload,
  }
}

export const addDeviceSuccess = message => {
  return {
    type: ADD_DEVICE_SUCCESS,
    payload: message,
  }
}

export const addDeviceError = message => {
  return {
    type: ADD_DEVICE_ERROR,
    payload: message,
  }
}

export const searchDeviceDefault = searchQuery => ({
  type: SEARCH_DEVICE_DEFAULT,
  searchQuery,
})

export const searchDeviceSuccess = deviceData => ({
  type: SEARCH_DEVICE_SUCCESS,
  payload: deviceData.rows,
})

export const searchDeviceFail = error => ({
  type: SEARCH_DEVICE_ERROR,
  payload: error,
})

export const checkImeiDefault = payload => ({
  type: CHECK_IMEI_DEFAULT,
  payload,
})

export const checkImeiSuccess = imeiData => ({
  type: CHECK_IMEI_SUCCESS,
  payload: imeiData,
})

export const checkImeiFail = error => ({
  type: CHECK_IMEI_ERROR,
  payload: error,
})

export const bulkSaleDefault = payload => {
  return {
    type: BULKSALE_DEFAULT,
    payload,
  }
}

export const bulkSaleSuccess = payload => {
  return {
    type: BULKSALE_SUCCESS,
    payload,
  }
}

export const bulkSaleFail = payload => {
  return {
    type: BULKSALE_ERROR,
    payload,
  }
}

export const rechargeDefault = payload => {
  return {
    type: RECHARGE_DEFAULT,
    payload,
  }
}

export const rechargeSuccess = payload => {
  return {
    type: RECHARGE_SUCCESS,
    payload,
  }
}

export const rechargeFail = payload => {
  return {
    type: RECHARGE_ERROR,
    payload,
  }
}

export const modifyExpiryDate = payload => ({
  type: MODIFY_EXPIRY_DATE,
  payload,
})

export const modifyExpiryDateSuccess = data => ({
  type: MODIFY_EXPIRY_DATE_SUCCESS,
  payload: data,
})

export const modifyExpiryDateFail = error => ({
  type: MODIFY_EXPIRY_DATE_ERROR,
  payload: error,
})

export const plateformModifyExpiryDate = payload => ({
  type: PLATEFORM_MODIFY_EXPIRY_DATE,
  payload,
})

export const plateformModifyExpiryDateSuccess = data => ({
  type: PLATEFORM_MODIFY_EXPIRY_DATE_SUCCESS,
  payload: data,
})

export const plateformModifyExpiryDateFail = error => ({
  type: PLATEFORM_MODIFY_EXPIRY_DATE_ERROR,
  payload: error,
})

export const addToVirtualDevice = payload => ({
  type: ADD_TO_VIRTUAL_DEVICE,
  payload,
})

export const addToVirtualDeviceSuccess = data => ({
  type: ADD_TO_VIRTUAL_DEVICE_SUCCESS,
  payload: data,
})

export const addToVirtualDeviceFail = error => ({
  type: ADD_TO_VIRTUAL_DEVICE_ERROR,
  payload: error,
})
