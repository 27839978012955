import React, { useState } from "react"
import { useSelector } from "react-redux"
import Navigation from "../../components/Monitor/NavigationMenu"

import EngineIdleReportMain from "../../components/Report/EngineIdleReportMain"
import ReportAside from "../../components/Report/ReportAside"

const ReportIndex = () => {
  const { profileData } = useSelector(state => state.Login)
  const [selectedUser, setSelectedUser] = useState(profileData?.member)

  return (
    <React.Fragment>
      <Navigation />
      <div className="nan_auto_layout">
        <ReportAside
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
        <div className="right_report h-100">
          <EngineIdleReportMain selectedUser={selectedUser} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReportIndex
