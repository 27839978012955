/* eslint-disable */

import React, { useState, useEffect } from "react"
import { MultiSelect } from "react-multi-select-component"
import { InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"
import "./ReportStyle.css"
import SpeedDetailsGrid from "./SpeedDetailsGrid"
import moment from "moment"
import { getDevicesByMemberId } from "store/actions"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

const SpeedingDetails = props => {
  const { selectedUser } = props
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [selected, setSelected] = useState([])

  const { deviceByMember } = useSelector(state => {
    return {
      deviceByMember: state.Device.deviceByMember,
    }
  })

  useEffect(() => {
    dispatch(
      getDevicesByMemberId({
        parentId: selectedUser?.id,
      })
    )
  }, [selectedUser])

  const options = deviceByMember?.rows
    ? deviceByMember?.rows.map(ele => ({
        label: ele.name || ele.model,
        value: ele.id,
      }))
    : []

  return (
    <div className="panel_main">
      <div className="head_main cursor-pointer">Speeding details </div>
      <div className="p-3">
        <div className="pd_1">
          <div className="d-flex flex-wrap align-items-start">
            <label className="pe-2 flx_0 pt-1">Device</label>
            <div className="mb-3 w-50c me-2">
              <MultiSelect
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
              />
            </div>

            <div className="d-inline-flex flex-column pe-4 pb-1">
              <div>
                <div className="d-inline-flex  align-items-center pe-2 w-50c">
                  <label className="pe-2 flx_0 pt-1">Time</label>
                  <div className="flx_1">
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block w-100"
                        placeholder=""
                        options={{
                          altInput: false,
                          enableTime: true,
                          dateFormat: "Y-m-d H:i",
                          time_24hr: true,
                          maxDate: new Date(),
                        }}
                        onChange={date => setStartDate(date)}
                        value={startDate}
                      />
                    </InputGroup>
                  </div>
                </div>
                <label className="pe-2 flx_0 pt-1">To</label>
                <div className="d-inline-flex align-items-center">
                  <div className="flx_1">
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block w-100"
                        placeholder=""
                        options={{
                          altInput: false,
                          enableTime: true,
                          dateFormat: "Y-m-d H:i",
                          time_24hr: true,
                          maxDate: new Date(),
                          minDate: new Date(startDate),
                        }}
                        onChange={date => setEndDate(date)}
                        value={endDate}
                      />
                    </InputGroup>
                  </div>
                  <div className="flx_1 ms-2 w-50c">
                    <button className="btn btn-sm btn-primary ">Check </button>
                  </div>
                </div>
              </div>

              <div className="d-flex w-100 pt-1 ps-3 pb-3">
                <span className="ps-4 form-text">
                  Maximum time range is 3 days. Please schedule for more reports
                </span>
                <span className="txt_link form-text ps-2">Schedule now</span>
              </div>
            </div>

            <div className=""></div>
          </div>
        </div>
        <div className="pd_2 text-end pb-2 mb-4 datseparate">
          <div>
            <span
              className="txt_link"
              onClick={() => {
                setStartDate(moment().toDate())
                setEndDate(moment().toDate())
              }}
            >
              Today
            </span>
            <span
              className="txt_link border-end-0"
              onClick={() => {
                setStartDate(moment().subtract(1, "days").toDate())
                setEndDate(moment().subtract(1, "days").toDate())
              }}
            >
              Yesterday{" "}
            </span>
          </div>
        </div>

        <div className="report_park_grid">
          <SpeedDetailsGrid />
        </div>
      </div>
    </div>
  )
}

SpeedingDetails.propTypes = {
  selectedUser: PropTypes.obj,
}

export default SpeedingDetails
