/* eslint-disable */

import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap"
import CheckPopup from "./CheckPopup"

function SettingsPop() {
  const [popoverright, setpopoverright] = useState(false);


  return (
    <div>
      <div className="modal-body">
      

        <Row className="mb-3">
          <div className="col-md-12">
          <div className="bg-light p-3 d-flex align-items-center simpleri">
                      <i className="bx bx-info-circle"></i>A simple
                      You can modify device by uploading Excel file. Please follow the format of example to prepare the file. File Example
                    </div>
          </div>
        </Row>

        <Row className="mb-3">
          <div className="col-md-12">
         <p>Following fields are available for import:</p>
         <div className="popifio">
         <ul className="table-cell-list">
           <li><b>IMEI</b> - Required</li> <li><b>SIM</b> - Optional, SIM can not be more than 21 characters.</li> <li><b>Device name</b> - Optional, Device name can not be more than 30 characters.</li> <li><b>Vehicle plate number</b> - Optional, Vehicle plate number can not be more than 20 characters.</li> 
         <li><b>Speeding value(kph)</b> - Optional</li> <li><b>User due</b> - Optional</li></ul>
          </div>
          </div>
        </Row>
        
        <div className="row">
                      <Col sm={12}>
                        <div>
                          <Input className="form-control" type="file" id="formFile" />
                        </div>
                      </Col>
                    </div>

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary ">
          Confirm
        </button>
        
      </div>
    </div>
  )
}

export default SettingsPop
