import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "./actionTypes"

export const userChangePassword = (user, history) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { user, history },
  }
}

export const userChangePasswordSuccess = () => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  }
}

export const userChangePasswordError = () => {
  return {
    type: CHANGE_PASSWORD_ERROR,
  }
}
