import {
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_ERROR,
  ADD_USERS,
  ADD_USERS_SUCCESS,
  ADD_USERS_ERROR,
  UPDATE_USERS,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_ERROR,
  CHANGE_PASSWORD_MEMBER,
  CHANGE_PASSWORD_MEMBER_SUCCESS,
  CHANGE_PASSWORD_MEMBER_ERROR,
  SEARCH_USER_DEFAULT,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_ERROR,
} from "./actionTypes"

export const getUserDetail = memberId => {
  return {
    type: GET_USER_DETAIL,
    memberId,
  }
}

export const getUserDetailSucces = user => {
  return {
    type: GET_USER_DETAIL_SUCCESS,
    payload: user,
  }
}

export const getUserDetailError = error => {
  return {
    type: GET_USER_DETAIL_ERROR,
    payload: error,
  }
}

export const addUserdefault = payload => {
  return {
    type: ADD_USERS,
    payload,
  }
}

export const addUserSuccess = message => {
  return {
    type: ADD_USERS_SUCCESS,
    payload: message,
  }
}

export const addUserError = message => {
  return {
    type: ADD_USERS_ERROR,
    payload: message,
  }
}

export const updateUserdefault = (
  userData,
  id,
  history,
  isFileUpload,
  files
) => {
  return {
    type: UPDATE_USERS,
    payload: { userData, id: id, history, isFileUpload, files },
  }
}

export const updateUserSuccess = message => {
  return {
    type: UPDATE_USERS_SUCCESS,
    payload: message,
  }
}

export const updateUserError = message => {
  return {
    type: UPDATE_USERS_ERROR,
    payload: message,
  }
}

export const memberUserChangePassword = (user, history) => {
  return {
    type: CHANGE_PASSWORD_MEMBER,
    payload: { user, history },
  }
}

export const memberUserChangePasswordSuccess = () => {
  return {
    type: CHANGE_PASSWORD_MEMBER_SUCCESS,
  }
}

export const memberUserChangePasswordError = () => {
  return {
    type: CHANGE_PASSWORD_MEMBER_ERROR,
  }
}

export const searchUserDefault = searchQuery => ({
  type: SEARCH_USER_DEFAULT,
  searchQuery,
})

export const searchUserSuccess = userSearchData => ({
  type: SEARCH_USER_SUCCESS,
  payload: userSearchData.rows,
})

export const searchUserFail = error => ({
  type: SEARCH_USER_ERROR,
  payload: error,
})
