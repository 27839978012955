/* eslint-disable */
import React from "react"
import {
  CHECK_IME_INITIAL,
  CHECK_IME_FAIL,
  CHECK_IME_SUCCESS,
  POST_SALE_INITIAL,
  POST_SALE_FAIL,
  POST_SALE_SUCCESS,
  POST_MOVE_ACCOUNT,
  POST_MOVE_ACCOUNT_FAIL,
  POST_MOVE_ACCOUNT_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  imeNumberDetails: {},
  saleResponse: {},
  moveAccountResponse:{},
  addSalesLoading: false,
  checkIMIELoading: false,
  putLoading: false,
  moveAccountLoading:false
}

const SharedDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHECK_IME_INITIAL:
      return {
        ...state,
        checkIMIELoading: true,
      }
    case CHECK_IME_SUCCESS:
      return {
        ...state,
        imeNumberDetails: action.payload,
        checkIMIELoading: false,
      }

    case CHECK_IME_FAIL:
      return {
        ...state,
        error: action.payload,
        checkIMIELoading: false,
      }
    //sale operation
    case POST_SALE_INITIAL:
      return {
        ...state,
        addSalesLoading: true,
      }
    case POST_SALE_SUCCESS:
      return {
        ...state,
        saleResponse: action.payload,
        addSalesLoading: false,
      }

    case POST_SALE_FAIL:
      return {
        ...state,
        addSalesLoading: false,
      }
    //Move account operation
    case POST_MOVE_ACCOUNT:
      return {
        ...state,
        moveAccountLoading: true,
      }
    case POST_MOVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        moveAccountResponse: action.payload,
        moveAccountLoading: false,
      }

    case POST_MOVE_ACCOUNT_FAIL:
      return {
        ...state,
        moveAccountLoading: false,
      }

    default:
      return state
  }
}

export default SharedDetails
