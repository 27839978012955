/* eslint-disable */
import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects"

import {
  ADD_DEVICE_GROUP,
  GET_DEVICE_GROUP,
  PUT_DEVICE_GROUP,
} from "./actionTypes"
import {
  addDeviceGroupSuccess,
  addDeviceGroupError,
  getDeviceGroupSuccess,
  getDeviceGroupFail,
  putDeviceGroupSuccess,
  putDeviceGroupFail,
} from "./actions"

import {
  getDeviceGroups,
  addDeviceGroups,
  putDeviceGroups,
} from "../../helpers/fakebackend_helper"

import { showToast } from "../../utils/Common"

function* addDeviceGroupSaga({ payload }) {
  try {
    const {
      payload: data,
      meta: { resolve, reject },
    } = payload

    const response = yield call(addDeviceGroups, {
      ...data,
    })
    if (response) {
      yield put(addDeviceGroupSuccess())
      yield call(resolve, response)
      showToast("Group Added!", true)
    } else {
      yield put(addDeviceGroupError())
      yield call(reject, response)
      showToast("Something Want Wrong! Please Try Again.", false)
    }
  } catch (error) {
    yield put(addDeviceGroupError())
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
    yield call(reject, error?.response?.data?.message)
  }
}

function* fetchDeviceGroupSaga({ payload }) {
  try {
    const response = yield call(getDeviceGroups, payload?.payload.memberId)
 
    yield put(getDeviceGroupSuccess(response))
  } catch (error) {
    yield put(getDeviceGroupFail(error))
  }
}

function* putDeviceGroupSaga({ payload }) {
  const {
    payload: data,
    meta: { resolve, reject },
  } = payload
  try {
    const response = yield call(putDeviceGroups, data)
    yield put(putDeviceGroupSuccess(response))
    yield call(resolve, response)
  } catch (error) {
    yield put(putDeviceGroupFail())
    yield call(reject, error)
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}

export function* watchMessage() {
  yield takeEvery(ADD_DEVICE_GROUP, addDeviceGroupSaga)
  yield takeEvery(GET_DEVICE_GROUP, fetchDeviceGroupSaga)
  yield takeEvery(PUT_DEVICE_GROUP, putDeviceGroupSaga)
}

function* deviceGroupSaga() {
  yield all([fork(watchMessage)])
}

export default deviceGroupSaga
