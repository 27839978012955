/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Tooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Label,
  Button,
  Form,
  Input,
  NavLink,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  InputGroup,
  Spinner,
  CardText,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Link } from "react-router-dom"
import classnames from "classnames"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import SelectSearch from "react-select-search"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import BulkSale from "../../components/Business/BulkSale"
import Recharge from "../../components/Business/Recharge"
import MoveDevice from "../../components/Business/MoveDevice"
import ModifyExpiryDate from "../../components/Business/ModifyExpiryDate"
import ModifyPlatformExpire from "../../components/Business/ModifyPlatformExpire"
import AlertSettings from "../../components/Business/AlertSettings"

import SettingsPop from "../../components/Business/SettingsPop"
import SettingsUpload from "../../components/Business/SettingsUpload"
import Sale from "../../components/Business/Sale"

import AddToVirtual from "../../components/Business/AddToVirtual"

import DeviceDetails from "../../components/Business/DeviceDetails"
import SearchPop from "../Search/Search"

import { getDevicesByMemberId } from "../../store/actions"

import { useSelector, useDispatch } from "react-redux"

const DatatableTables = props => {
  const { selectedUser } = props

  const dispatch = useDispatch()

  const [selectedDevice, setSelectedDevice] = useState({})

  const [dataList, setDataList] = useState([])
  const [count, setCount] = useState(0)

  const [selectedRows, setSelectedRows] = useState([])

  const [selectedRowForAddToVirtual, setSelectedRowForAddToVirtual] =
    useState("")

  const { deviceByMember, isLoading } = useSelector(state => {
    return {
      deviceByMember: state.Device.deviceByMember,
      isLoading: state.Device.isLoading,
    }
  })

  const [paginationOptions, setPaginationOptions] = useState({
    sizePerPage: 5,
    totalSize: 5,
    custom: true,
    page: 1,
  })

  const getData = () => {
    dispatch(
      getDevicesByMemberId({
        parentId: selectedUser?.id,
        data: {
          offset:
            paginationOptions?.sizePerPage * (paginationOptions?.page - 1),
          limit: paginationOptions?.sizePerPage,
        },
      })
    )
  }

  useEffect(() => {
    getData()
  }, [paginationOptions, selectedUser])

  useEffect(() => {
    if (deviceByMember) {
      setDataList(deviceByMember.rows)
      setCount(deviceByMember.count)
    }
  }, [deviceByMember])

  const onSizePerPageChange = sizePerPage => {
    setPaginationOptions({
      ...paginationOptions,
      sizePerPage: sizePerPage,
      page: 1,
    })
  }

  const onPageChange = (page, sizePerPage) => {
    setPaginationOptions({
      ...paginationOptions,
      page: page,
    })
  }

  const [modal_center, setmodal_center] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  const [modal_center2, setmodal_center2] = useState(false)
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }
  const [modal_center3, setmodal_center3] = useState(false)
  function tog_center3() {
    setmodal_center3(!modal_center3)
    removeBodyCss()
  }

  const [modal_center4, setmodal_center4] = useState(false)
  function tog_center4() {
    setmodal_center4(!modal_center4)
    removeBodyCss()
  }

  const [modal_center5, setmodal_center5] = useState(false)
  function tog_center5() {
    setmodal_center5(!modal_center5)
    removeBodyCss()
  }

  const [modal_center6, setmodal_center6] = useState(false)
  function tog_center6() {
    setmodal_center6(!modal_center6)
    removeBodyCss()
  }

  const [modal_center7, setmodal_center7] = useState(false)
  function tog_center7() {
    setmodal_center7(!modal_center7)
    removeBodyCss()
  }

  const [modal_center8, setmodal_center8] = useState(false)
  function tog_center8() {
    setmodal_center8(!modal_center8)
    removeBodyCss()
  }

  const [modal_center9, setmodal_center9] = useState(false)
  function tog_center9() {
    setmodal_center9(!modal_center9)
    removeBodyCss()
  }

  const [modal_standard2, setmodal_standard2] = useState(false)
  function tog_standard2(device = {}) {
    setSelectedDevice(device)
    setmodal_standard2(!modal_standard2)
    removeBodyCss()
  }

  const [modal_center_virtual, setmodal_center_virtual] = useState(false)
  function tog_center_virtual(id) {
    if (!modal_center_virtual) {
      setSelectedRowForAddToVirtual(id)
    } else {
      setSelectedRowForAddToVirtual("")
    }
    setmodal_center_virtual(!modal_center_virtual)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }
  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }

  const [activeTab, setactiveTab] = useState("1")
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }


  // data
  const columns = [
    {
      dataField: "imei",
      text: "IMEI",
      sort: false,
      style: { width: "20%", whiteSpace: "normal", wordBreak: "break-all" },
      formatter: (cellContent, user) => (
        <>
          <div className="d-flex linkbtn">
            <span
              type="span"
              onClick={() => {
                tog_large()
              }}
              className="txt_colr_1"
              data-toggle="modal"
              data-target=".bs-example-modal-sm"
            >
              {cellContent}
            </span>
          </div>
        </>
      ),
    },
    {
      dataField: "deviceInfo.lat",
      text: "Latitude",
      sort: false,
      style: { width: "22%", whiteSpace: "normal", wordBreak: "break-all" },
    },
    {
      dataField: "deviceInfo.lng",
      text: "longitude",
      sort: false,
      style: { width: "22%", whiteSpace: "normal", wordBreak: "break-all" },
    },
    {
      dataField: "model",
      text: "Model",
      sort: false,
      style: { width: "11%", whiteSpace: "normal", wordBreak: "break-all" },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      style: { width: "25%", whiteSpace: "normal", wordBreak: "break-all" },
      formatter: (cellContent, device) => (
        <>
          <div className="d-flex">
            <span
              color="secondary"
              className="me-1 txt_colr_1"
              outline
              onClick={() => {
                tog_center9()
              }}
            >
              Sale
            </span>{" "}
            |
            <span
              color="secondary"
              className="px-2 txt_colr_1"
              outline
              onClick={() => {
                tog_standard2(device)
              }}
            >
              Details
            </span>{" "}
            |
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="card-drop txt_colr_1"
                tag="span"
              >
                More <i className=" fas fa-sort-down"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#">Move</DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => tog_center_virtual(device?.imei)}
                >
                  Add to virtual account
                </DropdownItem>
                <DropdownItem href="#">Recharge</DropdownItem>
                <DropdownItem href="#">Playback</DropdownItem>
                <DropdownItem href="#">Track</DropdownItem>
                <DropdownItem href="#">I/O Settings</DropdownItem>
                <DropdownItem href="#">Create account</DropdownItem>

                <DropdownItem href="#">Reset password</DropdownItem>

                <DropdownItem href="#">Modify password</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: paginationOptions?.sizePerPage || 5,
    totalSize: count,
    custom: true,
    page: paginationOptions?.page || 1,
  }

  const selectRow = {
    mode: "checkbox",
    // clickToSelect: true,
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        const idsArray = rows.map(ele => ele.id)
        setSelectedRows(idsArray)
      } else {
        setSelectedRows([])
      }
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      const oldSelectedRows = [...selectedRows]
      if (!isSelect) {
        setSelectedRows(oldSelectedRows.filter(ele => ele !== row.id))
      } else {
        setSelectedRows([...oldSelectedRows, row.id])
      }
    },
  }

  const { SearchBar } = Search

  const imeiaArray = dataList
    ? dataList
        ?.filter(ele => selectedRows.includes(ele.id))
        ?.map(ele => ele.imei)
    : []

  if (isLoading) {
    return (
      <Spinner
        className={classnames({
          "position-relative": true,
          "button-style": false,
          visible: true,
          "left-50": true,
        })}
        size="lg"
        // type="grow"
      />
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
        
          {dataList && (
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={columns}
              data={dataList}
              remote
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={dataList}
                  remote
                  // search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-3 justify-content-between">
                        <Col
                          md="4"
                          className="d-inline-flex align-items-center btnsmall"
                        >
                          <Button
                            color="secondary"
                            className="me-3"
                            outline
                            onClick={() => {
                              tog_center()
                            }}
                          >
                            Bulksale
                          </Button>

                          <Button
                            color="secondary"
                            className="me-3"
                            outline
                            onClick={() => {
                              tog_center2()
                            }}
                          >
                            Recharge
                          </Button>

                          <UncontrolledDropdown>
                            <DropdownToggle
                              href="#"
                              className="card-drop d-flex align-items-center txt_colr_1"
                              tag="span"
                            >
                              More
                              <i className="dripicons-chevron-down font-size-10 ms-1" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-start grey">
                              <DropdownItem href="#">
                                <span
                                  outline
                                  onClick={() => {
                                    tog_center3()
                                  }}
                                >
                                  Move
                                </span>
                              </DropdownItem>
                              <hr className="m-0" />
                              <DropdownItem href="#">
                                <span
                                  outline
                                  onClick={() => {
                                    tog_center4()
                                  }}
                                >
                                  Modify expiry date
                                </span>
                              </DropdownItem>

                              <hr className="m-0" />
                              <DropdownItem href="#">
                                <span
                                  outline
                                  onClick={() => {
                                    tog_center5()
                                  }}
                                >
                                  Modify platform expiration date
                                </span>
                              </DropdownItem>

                              <hr className="m-0" />
                              <DropdownItem href="#">
                                <span
                                  outline
                                  onClick={() => {
                                    tog_center6()
                                  }}
                                >
                                  Alert settings
                                </span>
                              </DropdownItem>

                              <hr className="m-0" />
                              <DropdownItem>
                                <span
                                  outline
                                  onClick={() => {
                                    tog_center7()
                                  }}
                                >
                                  Settings
                                </span>
                              </DropdownItem>

                              <hr className="m-0" />
                              <DropdownItem>
                                <span
                                  outline
                                  onClick={() => {
                                    tog_center8()
                                  }}
                                >
                                  Setting by upload
                                </span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Col>

                        {/* <Col md="4" className="text-end">
                          <div className="search-box me-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar
                                {...toolkitProps.searchProps}
                                className="mb-0"
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col> */}
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          {dataList.length != 0 && (
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                                sizePerPageList={[5, 10, 15, 20]}
                                onSizePerPageChange={onSizePerPageChange}
                              />
                            </div>
                          )}
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone
                              {...paginationProps}
                              onPageChange={onPageChange}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          )}
        </Col>
      </Row>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Bulk Sale</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <BulkSale setmodal_center={setmodal_center} selectedRows={imeiaArray} />
      </Modal>
      <Modal
        isOpen={modal_center2}
        toggle={() => {
          tog_center2()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Recharge</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <Recharge
          setmodal_center2={setmodal_center2}
          selectedRows={imeiaArray}
          selectedUser={selectedUser}
        />
      </Modal>
      <Modal
        isOpen={modal_center3}
        toggle={() => {
          tog_center3()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Move device</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center3(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <MoveDevice />
      </Modal>
      <Modal
        isOpen={modal_center4}
        toggle={() => {
          tog_center4()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Modify expiry date</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center4(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <ModifyExpiryDate
          setToggelModel={setmodal_center4}
          selectedRows={imeiaArray}
        />
      </Modal>
      <Modal
        isOpen={modal_center5}
        toggle={() => {
          tog_center5()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Modify platform expiration date</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center5(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <ModifyPlatformExpire
          setToggelModel={setmodal_center4}
          selectedRows={imeiaArray}
        />
      </Modal>
      <Modal
        isOpen={modal_center6}
        toggle={() => {
          tog_center6()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Alert Settings</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center6(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <AlertSettings />
      </Modal>
      <Modal
        isOpen={modal_center7}
        toggle={() => {
          tog_center7()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Settings</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center7(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <SettingsPop />
      </Modal>
      <Modal
        isOpen={modal_center8}
        toggle={() => {
          tog_center8()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Setting by upload</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center8(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <SettingsUpload />
      </Modal>
      <Modal
        size="lg"
        isOpen={modal_center9}
        toggle={() => {
          tog_center9()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Sale</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center9(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <Sale userInfo={{}} />
      </Modal>
      <Modal
        size="lg"
        isOpen={modal_standard2}
        toggle={() => {
          tog_standard2()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Device</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          {modal_standard2 && (
            <DeviceDetails
              selectedDevice={selectedDevice}
              selectedUser={selectedUser}
              tog_model={tog_standard2}
            />
          )}
        </div>
      </Modal>
      <Modal
        size="lg"
        isOpen={modal_large}
        toggle={() => {
          tog_large()
        }}
      >
        <div className="modal-header border-0">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Search
          </h5>
          <button
            onClick={() => {
              setmodal_large(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body minheightpop pt-0 ps-0 pe-0">
          <SearchPop />
        </div>
      </Modal>
      <Modal
        isOpen={modal_center_virtual}
        toggle={() => {
          tog_center_virtual()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add to virtual account</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center_virtual(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <AddToVirtual
          setmodal_center2={setmodal_center_virtual}
          selectedRows={[selectedRowForAddToVirtual]}
          selectedUser={selectedUser}
        />
      </Modal>
    </React.Fragment>
  )
}

DatatableTables.propTypes = {
  selectedUser: PropTypes.obj,
  deviceList: PropTypes.Array,
}

export default DatatableTables
