/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects"
import { CarryOutOutlined, FormOutlined } from "@ant-design/icons"
import usicons1 from "../../../src/assets/images/user.svg"

// Crypto Redux States
import {
  GET_TREE_MENU_DATAILS,
  GET_TREE_MENU_INITIAL,
  GET_SUB_USERS,
  DELETE_USERS,
} from "./actionTypes"

import {
  getTreeMunuSuccess,
  getTreeMunuFail,
  getTreeMunuInitialValueFail,
  getTreeMunuInitialValueSuccess,
  getSubUsersSuccess,
  getSubUsersFail,
  deleteUserSuccess,
  deleteUserError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTreeMenu,
  getSubUsers,
  deleteUserById,
  getLoggedInUser,
} from "../../helpers/fakebackend_helper"

import { showToast } from "../../utils/Common"

function* fetchDUsersByParentId({ parentId }) {
  try {
    const response = yield call(getTreeMenu, parentId)
    yield put(getTreeMunuSuccess(response))
  } catch (error) {
    yield put(getTreeMunuFail(error))
  }
}
function* fetchDUsersInitialData({ initialData }) {
  try {
    yield put(getTreeMunuInitialValueSuccess(initialData))
  } catch (error) {
    yield put(getTreeMunuInitialValueFail(error))
  }
}

function* fetchSubUser({ payload }) {
  try {
    const response = yield call(getSubUsers, payload)
    yield put(getSubUsersSuccess(response))
  } catch (error) {
    yield put(getSubUsersFail(error))
  }
}

function* deleteUser({ payload }) {
  const { payload: payloadData, meta } = payload
  const { resolve, reject } = meta
  const { memberId, parentId } = payloadData
  try {
    const response = yield call(deleteUserById, memberId)
    yield put(deleteUserSuccess(response))
    yield call(resolve, response)
    showToast("User Deleted!!", true)

    yield put({ type: GET_SUB_USERS, parentId })
  } catch (error) {
    yield put(deleteUserError(error))
    yield call(reject, error)
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}

function* menuTreeSaga() {
  yield takeEvery(GET_TREE_MENU_DATAILS, fetchDUsersByParentId)
  yield takeEvery(GET_TREE_MENU_INITIAL, fetchDUsersInitialData)
  yield takeEvery(GET_SUB_USERS, fetchSubUser)
  yield takeEvery(DELETE_USERS, deleteUser)
}

export default menuTreeSaga
