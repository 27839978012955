/* eslint-disable */
import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects"

import { GET_PREFERENCES, PUT_PREFERENCES } from "./actionTypes"
import {
  getPreferencesSuccess,
  getPreferencesFail,
  putPreferencesSuccess,
  putPreferencesFail,
} from "./actions"

import {
  getPreferences,
  putPreferences,
} from "../../helpers/fakebackend_helper"

import { showToast } from "../../utils/Common"

function* fetchPreferences({ payload }) {
  try {
    const response = yield call(getPreferences, payload)
    yield put(getPreferencesSuccess(response))
  } catch (error) {
    yield put(getPreferencesFail(error))
  }
}

function* putPreferencesSaga({ payload }) {

  const {
    payload: data,
    meta: { resolve, reject },
  } = payload
  try {
    const response = yield call(putPreferences, {
      data: data.data,
      memberId: data.memberId,
    })
    yield put(putPreferencesSuccess(response))
    showToast("Peferences Updated Successfully!!", true)
    yield call(resolve, response)
  } catch (error) {
    yield put(putPreferencesFail())
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
    yield call(reject, error)
  }
}

export function* watchMessage() {
  yield takeEvery(GET_PREFERENCES, fetchPreferences)
  yield takeEvery(PUT_PREFERENCES, putPreferencesSaga)
}

function* preferencesSaga() {
  yield all([fork(watchMessage)])
}

export default preferencesSaga
