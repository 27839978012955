/* eslint-disable */

import React, { Component, useState } from "react"
// import React, { Component }  from 'react';
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Card,
  Label,
  Button,
  Nav,
  Table,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
  CardText,
  CardTitle,
  Col,
} from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"

const CheckPopup = props => {
  const [activeTab, setactiveTab] = useState("1")
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const getCount = () => {
    let total = 0
    if (props.checkImeiData.normal) {
      total += props.checkImeiData.normal.length
    }

    if (props.checkImeiData.error) {
      total += props.checkImeiData.error.length
    }
    return `(${total})`
  }

  return (
    <React.Fragment>
      <Nav tabs>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              toggle("1")
            }}
          >
            All{getCount()}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              toggle("2")
            }}
          >
            Normal
            {props.checkImeiData.normal
              ? `(${props.checkImeiData.normal.length})`
              : `(0)`}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              toggle("3")
            }}
          >
            Repeated
            {props.checkImeiData.repeatedImei
              ? `(${props.checkImeiData.repeatedImei.length})`
              : `(0)`}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "4",
            })}
            onClick={() => {
              toggle("4")
            }}
          >
            Error
            {props.checkImeiData.error
              ? `(${props.checkImeiData.error.length})`
              : `(0)`}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="py-2 text-muted">
        <TabPane tabId="1">
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>IMEI</th>
                <th>Model</th>
                <th>Membership</th>
              </tr>
            </thead>
            <tbody>
              {props.checkImeiData.normal &&
                props.checkImeiData.normal.length >= 0 &&
                props.checkImeiData.normal.map(data => {
                  return (
                    <>
                      <tr>
                        <td>
                          <span className="text-info">
                            {data?.imei ? data?.imei : "-"}
                          </span>
                        </td>
                        <td>{data?.model ? data?.model : "-"}</td>
                        <td>
                          <span className="text-info">
                            {data?.membership ? data?.membership : "-"}
                          </span>
                        </td>
                      </tr>
                    </>
                  )
                })}
              {props.checkImeiData.error &&
                props.checkImeiData.error.length >= 0 &&
                props.checkImeiData.error.map(data => {
                  return (
                    <>
                      <tr className="table-danger">
                        <td>
                          <span className="text-danger">
                            {data?.imei ? data?.imei : "-"}
                          </span>
                        </td>
                        <td>{data?.model ? data?.model : "-"}</td>
                        <td>
                          <span className="text-danger">
                            {data?.membership ? data?.membership : "-"}
                          </span>
                        </td>
                      </tr>
                    </>
                  )
                })}
              {/* <tr className="table-danger">
                          <td><span className="text-danger">25256 </span></td>
                          <td>-</td>
                          <td><span className="text-danger">Device does not exist.</span></td>
                        </tr> */}
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId="2">
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>IMEI</th>
                <th>Model</th>
                <th>Membership</th>
              </tr>
            </thead>
            <tbody>
              {props.checkImeiData.normal ? (
                props.checkImeiData.normal.length >= 0 &&
                props.checkImeiData.normal.map(data => {
                  return (
                    <>
                      <tr>
                        <td>
                          <span className="text-info">
                            {data?.imei ? data?.imei : "-"}
                          </span>
                        </td>
                        <td>{data?.model ? data?.model : "-"}</td>
                        <td>
                          <span className="text-info">
                            {data?.membership ? data?.membership : "-"}
                          </span>
                        </td>
                      </tr>
                    </>
                  )
                })
              ) : (
                <tr>No Data Found</tr>
              )}

              {/* <tr className="table-danger">
                          <td><span className="text-danger">25256 </span></td>
                          <td>-</td>
                          <td><span className="text-danger">Device does not exist.</span></td>
                        </tr> */}
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId="3">
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>IMEI</th>
                <th>Model</th>
                <th>Membership</th>
              </tr>
            </thead>
            <tbody>
              {props.checkImeiData.repeatedImei &&
                props.checkImeiData.repeatedImei.length >= 0 &&
                props.checkImeiData.repeatedImei.map(data => {
                  return (
                    <>
                      <tr className="table-danger">
                        <td>
                          <span className="text-danger">
                            {data?.imei ? data?.imei : "-"}
                          </span>
                        </td>
                        <td>{data?.model ? data?.model : "-"}</td>
                        <td>
                          <span className="text-danger">
                            {data?.membership ? data?.membership : "-"}
                          </span>
                        </td>
                      </tr>
                    </>
                  )
                })}
              {/* <tr className="table-danger">
                          <td><span className="text-danger">25256 </span></td>
                          <td>-</td>
                          <td><span className="text-danger">Device does not exist.</span></td>
                        </tr> */}
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId="4">
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>IMEI</th>
                <th>Model</th>
                <th>Membership</th>
              </tr>
            </thead>
            <tbody>
              {props.checkImeiData.error &&
                props.checkImeiData.error.length >= 0 &&
                props.checkImeiData.error.map(data => {
                  return (
                    <>
                      <tr className="table-danger">
                        <td>
                          <span className="text-danger">
                            {data?.imei ? data?.imei : "-"}
                          </span>
                        </td>
                        <td>{data?.model ? data?.model : "-"}</td>
                        <td>
                          <span className="text-danger">
                            {data?.membership ? data?.membership : "-"}
                          </span>
                        </td>
                      </tr>
                    </>
                  )
                })}
              {/* <tr className="table-danger">
                          <td><span className="text-danger">25256 </span></td>
                          <td>-</td>
                          <td><span className="text-danger">Device does not exist.</span></td>
                        </tr> */}
            </tbody>
          </Table>
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

export default CheckPopup
