/* eslint-disable */

import { call, put, takeEvery } from "redux-saga/effects"
// Login Redux States
import {
  ADD_USERS,
  ADD_DEVICE,
  SEARCH_DEVICE_DEFAULT,
  CHECK_IMEI_DEFAULT,
  BULKSALE_DEFAULT,
  RECHARGE_DEFAULT,
  MODIFY_EXPIRY_DATE,
  PLATEFORM_MODIFY_EXPIRY_DATE,
  ADD_TO_VIRTUAL_DEVICE,
} from "./actionTypes"
import {
  addDeviceSuccess,
  addDeviceError,
  searchDeviceFail,
  searchDeviceSuccess,
  checkImeiSuccess,
  checkImeiFail,
  bulkSaleSuccess,
  bulkSaleFail,
  rechargeSuccess,
  rechargeFail,
  rechargeDefault,
  modifyExpiryDateSuccess,
  modifyExpiryDateFail,
  plateformModifyExpiryDateSuccess,
  plateformModifyExpiryDateFail,
  addToVirtualDeviceSuccess,
  addToVirtualDeviceFail,
} from "./actions"

import {
  postAddUser,
  postAddDevice,
  getSearchDeviceDataByQuery,
  checkImei,
  bulksaleURL,
  rechargeURL,
  modifyExpiryDate,
  plateformExpiryDate,
  addToVirtualDevice,
} from "../../helpers/fakebackend_helper"
import { showToast } from "../../utils/Common"

function* onAddDevice({ payload }) {
  const {
    payload: data,
    meta: { resolve, reject },
  } = payload
  try {
    const response = yield call(postAddDevice, data.payload)
    yield put(addDeviceSuccess(response))
    yield call(resolve, response)
    showToast("Device added succesfully", true)
  } catch (error) {
    yield put(addDeviceError(error.response.data.message))
    yield call(reject, error)
    showToast(`${error.response.data.message}`, false)
  }
}

function* getDataByDeviceSerach({ searchQuery }) {
  try {
    const response = yield call(getSearchDeviceDataByQuery, searchQuery)

    yield put(searchDeviceSuccess(response))
  } catch (error) {
    yield put(searchDeviceFail(error))
  }
}

function* checkImeii({ payload }) {
  const {
    meta: { resolve, reject },
  } = payload

  try {
    const response = yield call(checkImei, payload.payload)

    yield put(checkImeiSuccess(response))
    yield call(resolve, response)
  } catch (error) {
    yield put(checkImeiFail(error))
    yield call(reject, error)
  }
}

function* bulkSale({ payload }) {
  const {
    meta: { resolve, reject },
  } = payload
  try {
    const response = yield call(bulksaleURL, payload.payload)
    yield put(bulkSaleSuccess(response))
    yield call(resolve, response)
    showToast("BulkSale added succesfully", true)
  } catch (error) {
    yield put(bulkSaleFail(error))
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* recharge({ payload }) {
  const {
    meta: { resolve, reject },
  } = payload
  try {
    const response = yield call(rechargeURL, payload.payload)
    yield put(rechargeSuccess(response))
    yield call(resolve, response)
    showToast(`${response.message}`, true)
  } catch (error) {
    yield put(rechargeFail(error))
    yield call(reject, error)
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* modifyExpiryDateSaga({ payload }) {
  const {
    meta: { resolve, reject },
  } = payload

  try {
    const response = yield call(modifyExpiryDate, payload.payload)

    yield put(modifyExpiryDateSuccess(response))
    yield call(resolve, response)
    showToast(`${response.message}`, true)
  } catch (error) {
    yield put(modifyExpiryDateFail(error))
    yield call(reject, error)
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* plateformModifyExpiryDateSaga({ payload }) {
  const {
    meta: { resolve, reject },
  } = payload

  try {
    const response = yield call(plateformExpiryDate, payload.payload)

    yield put(plateformModifyExpiryDateSuccess(response))
    yield call(resolve, response)
    showToast(`${response.message}`, true)
  } catch (error) {
    yield put(plateformModifyExpiryDateFail(error))
    yield call(reject, error)
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* addToVirtualDeviceSaga({ payload }) {
  const {
    meta: { resolve, reject },
  } = payload

  try {
    const response = yield call(addToVirtualDevice, payload.payload)

    yield put(addToVirtualDeviceSuccess(response))
    yield call(resolve, response)
    showToast(`${response.message}`, true)
  } catch (error) {
 
    yield put(addToVirtualDeviceFail(error))
    yield call(reject, error)
    showToast(`${error?.response?.data?.message}`, false)
  }
}

function* addDevice() {
  yield takeEvery(ADD_DEVICE, onAddDevice)
  yield takeEvery(CHECK_IMEI_DEFAULT, checkImeii)
  yield takeEvery(SEARCH_DEVICE_DEFAULT, getDataByDeviceSerach)
  yield takeEvery(BULKSALE_DEFAULT, bulkSale)
  yield takeEvery(RECHARGE_DEFAULT, recharge)
  yield takeEvery(MODIFY_EXPIRY_DATE, modifyExpiryDateSaga)
  yield takeEvery(PLATEFORM_MODIFY_EXPIRY_DATE, plateformModifyExpiryDateSaga)
  yield takeEvery(ADD_TO_VIRTUAL_DEVICE, addToVirtualDeviceSaga)
  // yield takeEvery(MODIFY_EXPIRY_DATE, modifyExpiryDateSaga)
  // yield takeEvery(PLATEFORM_MODIFY_EXPIRY_DATE, plateformModifyExpiryDateSaga)
}

export default addDevice
