import {
  GET_PREFERENCES,
  GET_PREFERENCES_SUCCESS,
  GET_PREFERENCES_ERROR,
  PUT_PREFERENCES,
  PUT_PREFERENCES_SUCCESS,
  PUT_PREFERENCES_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  isLoading: false,
  preferences: {},
  receivedMessage: {},
}

const message = (state = initialState, action) => {
  switch (action.type) {
    case GET_PREFERENCES:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PREFERENCES_SUCCESS:
      state = {
        ...state,
        preferences: action.payload !== null ? action.payload : {},
        loading: false,
      }
      break
    case GET_PREFERENCES_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case PUT_PREFERENCES:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case PUT_PREFERENCES_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      }
      break
    case PUT_PREFERENCES_ERROR:
      state = {
        ...state,
        isLoading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default message
